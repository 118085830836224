// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".commonList_container__vInTt {\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  width: 105%;\n  padding-right: 5%;\n  min-height: 80%;\n  max-height: 100%;\n  overflow-y: auto;\n  height: 100%;\n}\n\n.commonList_inputContainer__2h8nA {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 7.81px;\n  gap: 7.81px;\n  position: relative;\n}\n\n.commonList_inputContainer__2h8nA > span {\n  color: var(--Gris-100, #68688c);\n  font: normal 500 12px/14px Inter;\n}\n\n.commonList_toast__19Xdl {\n  top: 5rem;\n}\n\n.commonList_toast__19Xdl > span {\n  padding-top: 2px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "commonList_container__vInTt",
	"inputContainer": "commonList_inputContainer__2h8nA",
	"toast": "commonList_toast__19Xdl"
};
module.exports = exports;
