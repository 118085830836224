import API from "./API";

export const getEntities = (endpoint, config={}) => {
  return API.get(endpoint, config)
}

export const getEntityNames = async (endpoint, ids, cancelToken) => {
  const urlInvalidCharsRegex = /[^\w\d-._~]/;
  const validIds = ids.filter(id => !urlInvalidCharsRegex.test(id));
  if (validIds.length > 0) {
    return API.get(`${endpoint}/ids?ids=${validIds.join(',')}`, {
      cancelToken: cancelToken,
    });
  } else {
    return Promise.resolve([]);
  }
};