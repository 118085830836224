import React, { useEffect, useRef, useState } from 'react';

const CardSettingItem = ({ k, maxLengthString, i, idx }) => {

  const liRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  useEffect(() => {
    const element = liRef.current;

    if (element) {
      const isOverflowing =
        element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
      
      setIsTextOverflowing(isOverflowing);
    }
  }, [k]);


  return (
    <td key={`td-${i}-${idx}`}>
      <div className='PromoSettingsContainer-list'>
        {k.length > maxLengthString && <span className={`tooltiptext-PromoSettingsContainer ${i === 0 ? 'invertedtooltip' : ''}`}>{k}</span>}
        <div className='PromoSettingsContainer-list-div'>
          <li id="CardSettingItem_li" ref={liRef} className={`CardSettingItem_li ${isTextOverflowing ? 'text-overflow' : ''}`}>
            {k}
          </li>
        </div>
      </div>
    </td>
  );
};

export default CardSettingItem;