import { useTranslation } from 'react-i18next'
import React from 'react'

import arrowSvg from '../../assets/arrow.svg'
import style from './defaultChild.module.css'
import { CommonCheckbox } from '..'

const DefaultChild = ({
  id,
  label,
  checked,
  onClick,
  children,
  handleChange,
  withArrow = true,
  withCheckbox = false,
  withoutBorders = false,
}) => {
  const { t } = useTranslation()

  return children ? (
    <div className={style.container}>
      <li
        className={`${style.itemChild} ${withoutBorders ? style.withoutBorders : null} ${
          withCheckbox ? style.withCheckbox : null
        }`}
        onClick={onClick}>
        {withCheckbox ? (
          <CommonCheckbox value={label.toLocaleLowerCase()} handleChange={handleChange} checked={checked} id={id} />
        ) : (
          <span>{label}</span>
        )}
        {withArrow ? <img src={arrowSvg} /> : null}
      </li>
      {children ? children : null}
    </div>
  ) : (
    <li
      className={`${style.itemChild} ${withoutBorders ? style.withoutBorders : null} ${
        withCheckbox ? style.withCheckbox : null
      }`}
      onClick={onClick}>
      {withCheckbox ? (
        <CommonCheckbox value={label.toLocaleLowerCase()} handleChange={handleChange} checked={checked} id={id} />
      ) : (
        <span>{label}</span>
      )}
      {withArrow ? <img src={arrowSvg} /> : null}
    </li>
  )
}

export default DefaultChild
