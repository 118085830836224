import React, { useState, useEffect } from 'react'

const useSelectLocation = ({ filteredLocations, tree, setTree, origins, searchTree, matchId, raiz,filterKeyword}) => {
  const [visitedSteps, setVisitedSteps] = useState([])
  const [list, setList] = useState([])
  const [open, setIsOpen] = useState(null)

  useEffect(() => {
    if (filteredLocations[0]?.value === 'EVERYWHERE') {
      return setList(filteredLocations[0].children)
    }
    setList(filteredLocations)
  }, [filteredLocations])

  const handleClick = item => {
    if (item.children) {
      setTree([...tree, { value: item.value, label: item.label, children: item.children }])
      setList(item.children)
      setVisitedSteps([...visitedSteps, item])
    }
  }

  const goBack = () => {
    if (visitedSteps.length > 1) {
      const newVisitedSteps = [...visitedSteps]
      newVisitedSteps.pop()
      const lastVisitedStep = newVisitedSteps[newVisitedSteps.length - 1]
      setVisitedSteps(newVisitedSteps)
      setList(lastVisitedStep.children)
      setTree(tree.slice(0, tree.length - 1))
    } else {
      setVisitedSteps([])
      if(filterKeyword==="")
        setList(filteredLocations[0].children)
      else
        setList(filteredLocations)
      setTree([])
    }
  }

  const goStep = item => {
    setList(item.children)
    const index = tree.findIndex(node => node.value === item.value)
    const newTree = tree.slice(0, index + 1)
    setTree(newTree)
    const visitedIndex = visitedSteps.findIndex(step => step.value === item.value)
    const newVisitedSteps = visitedSteps.slice(0, visitedIndex + 1)
    setVisitedSteps(newVisitedSteps)
  }

  const handleChange = (item, clonedObject) => {
    const { value } = item

    // Le asigna el value por si tiene o no, dependiendo de sus hijos
    if (!Array.isArray(item.children)) {
      return clonedObject.includes(value) ? clonedObject.filter(item => item !== value) : [...clonedObject, value]
    }

    if (clonedObject.includes(value)) {
      const updatedOrigins = clonedObject.filter(item => !isDescendant(value, item))
      return updatedOrigins
    } else {
      const updatedOrigins = [...clonedObject, value]
      const parentNode = searchTree(raiz[0], value, matchId)
      if (parentNode && parentNode.children) {
        parentNode.children.forEach(child => {
          updatedOrigins.push(child.value)
          addDescendantsToOrigins(child, updatedOrigins)
        })
      }
      return updatedOrigins
    }
  }

  const validateTree = item => {
    if (!Array.isArray(item.children)) {
      return origins.includes(item.value)
    }

    const childs = item.children.map(child => validateTree(child))

    if (childs.every(valor => valor === false)) {
      return false
    } else if (childs.every(valor => valor === true)) {
      return true
    } else {
      return 2
    }
  }

  const setOriginTree = (item, origins) => {
    // Si es hoja, verifica si el valor está en el arreglo inicial
    if (!Array.isArray(item.children)) {
      return origins.includes(item.value)
    }

    // Mapea sobre los hijos y actualiza el arreglo inicial recursivamente
    const updatedOrigins = item.children.map(child => setOriginTree(child, origins))

    // Si todos los hijos están en el arreglo, agrega el valor del nodo padre
    if (updatedOrigins.every(value => value === true)) {
      origins.push(item.value)
    } else {
      // Si al menos un hijo no está en el arreglo, elimina el valor del nodo padre
      const index = origins.indexOf(item.value)
      if (index !== -1) {
        origins.splice(index, 1)
      }
    }

    // Retorna true si todos los hijos están en el arreglo, false de lo contrario
    return updatedOrigins.every(value => value === true)
  }

  const addDescendantsToOrigins = (node, updatedOrigins) => {
    if (node.children) {
      node.children.forEach(child => {
        updatedOrigins.push(child.value)
        addDescendantsToOrigins(child, updatedOrigins)
      })
    }
  }

  const isDescendant = (parentValue, childValue) => {
    const parent = searchTree(raiz[0], parentValue, matchId)
    if (!parent || !parent.children) {
      return false
    }
    const child = searchTree(parent, childValue, matchId)
    return !!child
  }

  return { list, open, goBack, goStep, handleChange, handleClick, setIsOpen, validateTree, setOriginTree }
}

export default useSelectLocation
