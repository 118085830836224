import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Form, Input, FormGroup, Label } from 'reactstrap';
import style from './ResetPassword.module.css';
import antion from '../ForgottenPassword/assets/antion.svg';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetPassword, existToken } from '../../api/AuthAPI';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import imgEye from './../../assets/eye.svg';
import imgEyeDisable from './../../assets/eye-disable.svg'

const ResetPassword = (props) => {
  const { t } = useTranslation('resetPassword');
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [retrieve, setRetrieve] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cookies, setCookies] = useCookies(['auth_token', 'connect.sid']);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };


  useEffect(() => {
    if (hasParams() && retrieve) {
      const timer = setTimeout(() => {
        history.push('/login');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [hasParams, retrieve, history]);

  useEffect(() => {
    if (hasParams()) {
      setSubmitting(true)
      let params = QueryString.parse(props.location.search)

      existToken(params.token)
        .then(({ data }) => {
          console.log('Recuperè contraseña')
          setSubmitting(false)
          console.log(params.email)
          setEmail(params.email)
        })
        .catch(error => {
          setSubmitting(false)
          setError('El token expiró o ya fue utilizado')
        })
    } else {
      history.push({ pathname: '/login' })
    }
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (password !== confirmPassword) {
      setError('La confirmación de contraseña no coincide');
      setSubmitting(false);
      return;
    }

    let params = QueryString.parse(props.location.search);

    existToken(params.token)
      .then(({ data }) => {
        console.log('Recuperè contraseña')
        setSubmitting(false)
        console.log(params.email)
        setEmail(params.email)
        resetPassword(email, password, params.token)
          .then(({ data }) => {
            console.log('Recuperè contraseña')
            setSubmitting(false)
            setRetrieve(true)
          })
          .catch(error => {
            setSubmitting(false)
            setError('El reseteo de contraseña no pudo realizarse')
          })
      })
      .catch(error => {
        setSubmitting(false)
        setError('El token expiró o ya fue utilizado')
      })
  }

  const isAuthenticated = () => {
    return cookies.auth_token;
  };

  const hasParams = () => {
    return Object.keys(QueryString.parse(props.location.search)).length > 0;
  };

  return isAuthenticated() ? (
    <Redirect to="/" />
  ) : (
    <div className={`${style.page} ${hasParams() ? (retrieve ? style.successfullyPage : '') : ''}`}>
      <Form onSubmit={onSubmit} className={style.form} id="reset-password-form">
        <Card className={style.cardContainer}>
          <CardHeader className={style.header}>
            <div className={style.imgContainer}>
              <img src={antion} alt="antion - ico" />
            </div>
          </CardHeader>
          {hasParams() && !retrieve && (
            <CardBody className={style.body}>
              <h3 className={style.bodyTitle}>{t('title')}</h3>
              <div className={style.bodyContainer}>
                <FormGroup>
                  <Label htmlFor="newPassword">{t('newPassword')}</Label>
                  <div className="relative">
                    <Input
                      id="newPassword"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={t('newPasswordPlaceholder')}
                      required
                      type={isPasswordVisible ? 'text' : 'password'}
                    />
                    <img
                      src={ isPasswordVisible? imgEyeDisable:imgEye} 

                      onClick={handleTogglePasswordVisibility}
                      className="login-eyes"
                      alt="eyes.png"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="confirmNewPassword">{t('confirmPassword')}</Label>
                  <div className="relative">
                    <Input
                      id="confirmNewPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder={t('confirmPasswordPlaceholder')}
                      required
                      type={isConfirmPasswordVisible ? 'text' : 'password'}
                    />
                    <img
                      src={ isConfirmPasswordVisible? imgEyeDisable:imgEye} 
                      onClick={handleToggleConfirmPasswordVisibility}
                      className="login-eyes"
                      alt="eyes.png"
                    />
                  </div>
                </FormGroup>
              </div>
              {error && (
                <div className={style.errorDiv}>
                  <p style={{ color: 'red' }}>{error}</p>
                </div>
              )}
            </CardBody>
          )}
          {!hasParams() && (
            <CardBody className={style.body}>
              <h6>{t('message')}</h6>
            </CardBody>
          )}
          {hasParams() && retrieve && (
            <CardBody className={style.successfully}>
              <h2>Contraseña Actualizada con Exito</h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M41 0C18.3543 0 0 18.3543 0 41C0 63.6457 18.3543 82 41 82C63.6457 82 82 63.6457 82 41C82 18.3543 63.6457 0 41 0ZM36.7314 59.2222C35.5743 59.2222 34.4172 58.7849 33.5289 57.9102L24.1353 48.7946C22.3404 47.027 22.3222 44.1433 24.0943 42.3576C25.8619 40.5581 28.7456 40.549 30.5313 42.3166L36.6996 48.2342L55.9878 28.6772C57.7736 26.9006 60.6527 26.8869 62.4339 28.6544C64.2197 30.4357 64.2288 33.3148 62.4567 35.1006L39.9659 57.8783C39.0684 58.7712 37.8977 59.2222 36.7314 59.2222Z"
                  fill="#13EA90"
                />
              </svg>
            </CardBody>
          )}
          {hasParams() && !retrieve && (
            <CardFooter className={style.footer}>
              <button
                color="primary"
                className="btn-style btn-style-orange"
                type="submit"
                form="reset-password-form"
                disabled={submitting}
              >
                {t('buttonSubmit')}
              </button>
              <a className="login" style={{ textAlign: 'center' }} href="/login">
                {t('login')}
              </a>
            </CardFooter>
          )}
        </Card>
      </Form>
    </div>
  );
};

export default ResetPassword;
