import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory, withRouter } from 'react-router-dom'
import PromoWizard from '../../components/PromoWizard/PromoWizard'
import Steps from 'rc-steps'
import 'rc-steps/assets/index.css'
import { CreatePromoContext } from '../../context/create-promo-context'
import './CreatePromo.css'
import { useTranslation } from 'react-i18next'
import initialFormTemplate from '../../components/PromoWizard/initialForm'
import { createPromo, getPromo, updatePromo } from '../../api/PromotionsAPI'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'

const COPY_PROMO_LABEL = ' - Copia'

const CreatePromo = props => {
  const initialForm = JSON.parse(JSON.stringify(initialFormTemplate))
  const { t } = useTranslation('createPromo')
  const [currentStep, setCurrentStep] = useState(1)
  const [form, setForm] = useState(initialForm)
  const [originalPromo, setOriginalPromo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const history = useHistory()
  const steps = [
    { title: t('stepper.settings') },
    { title: t('stepper.when') },
    { title: t('stepper.where') },
    { title: t('stepper.conditions') },
    { title: t('stepper.actions') },
  ]
  const [isOpen, setIsOpen] = useState(false)
  const [confirmationCallback, setConfirmationCallback] = useState({ onConfirm: () => {}, onCancel: () => {} })

  const ALL_STEPS_ARE_VALID =
    form.header.name &&
    form.header.description &&
    (form.header.always_current || (form.header.date_time_from && form.header.date_time_to)) &&
    form.body.activations.length &&
    form.body.origins.length &&
    form.body.rules.length &&
    form.body.actions.length

  const IS_FORM_VALID = props.location?.state?.copy
    ? ALL_STEPS_ARE_VALID
    : JSON.stringify(form) !== JSON.stringify(originalPromo) && ALL_STEPS_ARE_VALID

  useEffect(() => {
    if (props.location?.state && props.location.state.promoId) {
      getPromo(props.location.state.promoId)
        .then(({ data }) => {
          setForm(!props.location?.state?.copy ? { ...data } : { ...manageFormCopy(data) })
          setOriginalPromo(JSON.parse(JSON.stringify(data)))
          setLoading(false)
        })
        .catch(err => console.log(err))
    } else {
      setLoading(false)
    }
  }, [props.location?.state])

  const saveForm = (key, name, value) => {
    let newForm = { ...form }
    newForm[key][name] = value
    setForm(newForm)
  }

  const addSetting = (setting, settingsKey, editingIndex) => {
    let settings = [...form['body'][settingsKey]]
    if (Number.isFinite(editingIndex)) {
      settings[editingIndex] = setting
    } else {
      settings.push(setting)
    }
    saveForm('body', settingsKey, settings)
  }

  const deleteSetting = (settingsKey, index) => {
    let settings = [...form['body'][settingsKey]]
    if (settingsKey != 'origins') settings.splice(index, 1)
    else settings = []
    saveForm('body', settingsKey, settings)
  }

  const handlePromoOperation = async action => {
    let message

    switch (action) {
      case 'create':
        await createPromo(form, originalPromo)
        message = {
          text: 'Creación realizada con éxito',
          type: 'success',
        }
        break
      case 'update':
        await updatePromo(form, originalPromo)
        message = {
          text: 'Se editó la promoción con éxito',
          type: 'success',
        }
        break
      case 'copy':
        await createPromo(form, originalPromo)
        message = {
          text: 'Se copió la promoción con éxito',
          type: 'success',
        }
        break
    }

    sessionStorage.setItem('promoSettings', JSON.stringify(message))
    history.push('/dashboard')
  }

  const handleCancel = async () => {
    if (IS_FORM_VALID) {
      if (originalPromo) {
        const action = props.location?.state?.copy ? 'copy' : 'update'
        await handlePromoOperation(action, form, originalPromo)
      } else {
        await handlePromoOperation('create', form)
      }
    } else {
      setIsOpen(false)
    }
  }

  const onBackBtnClick = e => {
    e.preventDefault()
    setIsOpen(true)

    const handleConfirm = () => {
      history.push('/dashboard')
    }

    const handleCancelWrapper = async () => {
      await handleCancel()
    }

    setConfirmationCallback({ handleConfirm, handleCancel: handleCancelWrapper })
  }

  const manageFormCopy = form => {
    const { name } = form.header
    if (!name.includes(COPY_PROMO_LABEL)) {
      form.header.name = name + COPY_PROMO_LABEL
    }
    return form
  }

  const manageFreeNavigation = index => {
    const desiredStep = index + 1
    return setCurrentStep(desiredStep)
  }

  return (
    <Row className="create-promo-container">
      <PopUpAlert
        value={t('confirm_go_back')}
        extraText={'Si no guardas, los cambios se perderán.'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={{
          value: 'Salir',
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
        onCancel={{
          value: IS_FORM_VALID ? 'Guardar' : 'Cancelar',
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
      />
      <Col md="3" className="step-sidebar pl-5 pt-4 pb-5">
        <div
          onClick={onBackBtnClick}
          className={`text-decoration-none back-btn ${loading ? 'isLoading' : ''} ${
            loading ? null : originalPromo ? (props.location?.state?.copy ? 'isCopy' : 'isEditing') : ''
          }`}>
          <i className="icon">
            <FontAwesomeIcon icon={faAngleLeft} />
          </i>
          {loading
            ? null
            : originalPromo
            ? props.location?.state?.copy
              ? t('promo_copy')
              : t('promo_edit')
            : t('new_promotion')}
        </div>
        <div className="stepper">
          <Steps current={currentStep - 1} onChange={setCurrentStep} direction="vertical">
            {steps.map((step, index) => (
              <Steps.Step
                key={index}
                title={step.title}
                onStepClick={manageFreeNavigation}
                icons={{ finish: <p>{index + 1}</p> }}
              />
            ))}
          </Steps>
        </div>
      </Col>
      <Col md="9" className="wizard-container pl-4 pr-5 pt-4 pb-4">
        <CreatePromoContext.Provider
          value={{
            form: form,
            saveForm: saveForm,
            originalPromo: originalPromo,
            addSetting: addSetting,
            deleteSetting: deleteSetting,
            copy: props.location?.state?.copy,
            currentStep: currentStep,
            setCurrentStep: setCurrentStep,
            menuIsOpen,
            setMenuIsOpen,
            isValid: Boolean(IS_FORM_VALID),
          }}>
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <PromoWizard />
            </>
          )}
        </CreatePromoContext.Provider>
      </Col>
    </Row>
  )
}

export default React.memo(withRouter(CreatePromo))
