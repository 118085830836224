import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Form, Input } from 'reactstrap'
import PromotionsStatusCard from '../../components/common/PromotionsStatusCard/PromotionsStatusCard'
import imgSearch from './assets/search.svg'
import './ActionBar.css'

const ActionBar = props => {
  const { t } = useTranslation('dashboard')
  const { onFilterChange, onSearchChange, searchQuery, getPromosValues } = props

  return (
    <Card className="action-bar">
      <Form onSubmit={e => e.preventDefault()}>
        <div className={'action-bar-cards'}>
          <PromotionsStatusCard
            value={getPromosValues('active').length}
            label={t('status.active')}
            color="green"
            onFilterChange={() => onFilterChange('active')}
          />
          <PromotionsStatusCard
            value={getPromosValues('paused').length}
            label={t('status.paused')}
            color="yellow"
            onFilterChange={() => onFilterChange('paused')}
          />
          <PromotionsStatusCard
            value={getPromosValues('expired').length}
            label={t('status.expired')}
            color="red"
            onFilterChange={() => onFilterChange('expired')}
          />
        </div>
        <div className={'search'}>
          <img className={'action-bar-search-img'} src={imgSearch} alt={'lupa.svg'} />
          <Input
            placeholder={t('search_placeholder')}
            value={searchQuery}
            onChange={e => onSearchChange(e.target.value)}
          />
          {searchQuery.length !== 0 ? (
            <button type="button" onClick={() => onSearchChange('')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path
                  d="M7.4495 6.00007L11.6997 1.74985C12.1001 1.34972 12.1001 0.700526 11.6997 0.300397C11.2993 -0.100132 10.6507 -0.100132 10.2503 0.300397L6.00005 4.55062L1.74984 0.300397C1.34945 -0.100132 0.70079 -0.100132 0.300396 0.300397C-0.100132 0.700526 -0.100132 1.34972 0.300396 1.74985L4.5506 6.00007L0.300529 10.2503C-0.0999986 10.6504 -0.0999986 11.2996 0.300529 11.6997C0.50066 11.8999 0.762923 12 1.02519 12C1.28745 12 1.54971 11.8999 1.74998 11.6997L6.00005 7.44965L10.2503 11.6997C10.4504 11.8999 10.7128 12 10.9749 12C11.2373 12 11.4994 11.8999 11.6997 11.6997C12.1001 11.2996 12.1001 10.6504 11.6997 10.2503L7.4495 6.00007Z"
                  fill="#68688C"
                />
              </svg>
            </button>
          ) : null}
        </div>
      </Form>
    </Card>
  )
}

export default ActionBar
