import React, { useState } from 'react'
import './PromotionsStatusCard.css'
import { Card } from 'reactstrap'

const PromotionsStatusCard = ({ value, label, color, onFilterChange }) => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <Card
      className={`card - stats promotion-status-card ${
        isChecked ? color : 'default'
      }`}>
      <div className={`status-value PromotionsStatusCard-color-txt-${color}`}>
        {value}
      </div>
      <div className="status-label">
        <label htmlFor={`checkbox-${label}`}>{label}</label>
      </div>
      <div className={`checkbox-wrapper bg-${color}`}>
        <div className="round">
          <input
            type="checkbox"
            id={`checkbox-${label}`}
            onChange={() => {
              onFilterChange()
              setIsChecked(!isChecked)
            }}
          />
          <label htmlFor={`checkbox-${label}`}></label>
        </div>
      </div>
    </Card>
  )
}

export default PromotionsStatusCard
