import React, { useContext, useState } from 'react'
import { FormGroup, Label, Input, Row, Col } from 'reactstrap'
import './WhenForm.css'
import moment from 'moment'
import 'moment/locale/es'
import { useTranslation } from 'react-i18next'
import { es } from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CreatePromoContext } from 'context/create-promo-context'
import RenderDayList from './components/RenderDaysList/RenderDayList'
import clockSvg from '../../../../assets/svg/clock.svg'
import calendarySvg from '../../../../assets/svg/calendary.svg'
import DateHeader from 'components/DateHeader/DateHeader'

const daysList = ['L', 'M', 'M', 'J', 'V', 'S', 'D']

const WhenForm = props => {
  registerLocale('es', es)

  const loadDateType = () => {
    if (props.form.date_setting.days?.length === 7) {
      return 'everyday'
    }
    if (props.form.date_setting.days?.length > 0) {
      return 'days'
    }
    if (
      props.form.date_setting.hasOwnProperty('specific_day') &&
      props.form.date_setting.specific_day !== 0
    ) {
      return 'specificDate'
    }
    if (props.form.date_setting.day_month !== 0) {
      return 'dayofthemonth'
    }
    return 'everyday'
  }

  const { t } = useTranslation('createPromo')
  const [days, setDays] = useState(
    props.form.date_setting.days || [1, 2, 3, 4, 5, 6, 7]
  )
  const [dateType, setDateType] = useState(loadDateType) //hacer funcion sol para ver el tipo al
  const [startTime, setStartTime] = useState(
    props.form.time_range.time_from
      ? moment(props.form.time_range.time_from, 'h:mm a').toDate()
      : new Date()
  )
  const [endTime, setEndTime] = useState(
    props.form.time_range.time_to
      ? moment(props.form.time_range.time_to, 'h:mm a').toDate()
      : new Date()
  )
  const [dayOfTheMonth, setDayOfTheMonth] = useState(
    props.form.date_setting.day_month
  )
  const [specificDate, setSpecificDate] = useState(
    props.form.date_setting.specific_day
      ? new Date(props.form.date_setting.specific_day)
      : null
  )
  const [allDay, setAllDay] = useState(props.form.time_range.all_day)
  const [errors, setErrors] = useState([])
  const context = useContext(CreatePromoContext)

  function validarWhen() {
    const result = context.form.body.activations.filter((element, index) => {
      if (props?.editingIndex === index) return null

      const dateSetting = element.date_setting

      if (dateType === 'days' || dateType === 'everyday') {
        if (!days.every(day => dateSetting?.days?.includes(day))) return null
      } else if (dateType === 'dayofthemonth') {
        if (dateSetting?.day_month !== dayOfTheMonth) return null
      } else {
        const specificDay = specificDate?.getTime()
        if (dateSetting?.specific_day !== specificDay) return null
      }     
      if (allDay !== element.time_range.all_day) return null
      else {
        if (allDay)
          return element
        else {
          if (element.time_range.time_from === moment(startTime).format('HH:mm') && element.time_range.time_to === moment(endTime).format('HH:mm'))
            return element
          else
            return null
        }
      }
    })

    return result
  }

  const onDateTypeChange = value => {
    setDays(value == 'everyday' ? [1, 2, 3, 4, 5, 6, 7] : [])
    setSpecificDate('')
    setDayOfTheMonth(1)
    setDateType(value)
  }

  const filterPassedTime = time => {
    const selectedDate = new Date(time)

    return startTime.getTime() < selectedDate.getTime()
  }

  const minutesOfDay = m => {
    return m.minutes() + m.hours() * 60
  }

  const onSubmit = e => {
    e.preventDefault()
    if (validarWhen().length > 0) {
      setErrors([t('steps.when.form.error_repeated')])
    } else {
      if (
        !allDay &&
        minutesOfDay(moment(startTime)) > minutesOfDay(moment(endTime))
      ) {
        setErrors([t('steps.when.form.range_time_validation')])
        return
      }
      if (dateType === 'days' && !days?.length) {
        setErrors([t('steps.when.form.empty_days_validation')])
        return
      }
      let newWhenSetting = {
        date_setting: {
          day_month: dateType === 'dayofthemonth' ? dayOfTheMonth : 0,
          days: dateType === 'everyday' || dateType === 'days' ? days : [],
          specific_day: specificDate ? specificDate.getTime() : 0,
        },
        time_range: {
          all_day: allDay,
          time_from: moment(startTime).format('HH:mm'),
          time_to: moment(endTime).format('HH:mm'),
        },
      }

      if (props.form?.hasOwnProperty('id') && props.isEditing) {
        newWhenSetting['id'] = props.form.id
      }

      props.addSetting(newWhenSetting)
    }
  }

  return (
    <form onSubmit={onSubmit} id="add-promo-form" className="when-form">
      <h1>{t('steps.when.title')}</h1>
      <FormGroup tag="fieldset">
        {/* Radio button set */}
        <div className="form-check-radio mb-4">
          <Label id={'when-radio-label'} className="form-check-label">
            <Input
              type="radio"
              name="days-radios"
              id="days-radios-1"
              value="everyday"
              checked={dateType === 'everyday'}
              onChange={e => onDateTypeChange(e.target.value)}
              color="warning" // Aquí se aplica el color naranja
            />
            {t('steps.when.form.everyday')}
            <span className="form-check-sign" />
          </Label>
        </div>

        <div className="form-check-radio">
          <Label id={'when-radio-label'} className="form-check-label">
            <Input
              type="radio"
              name="days-radios"
              id="days-radios-2"
              value="days"
              checked={dateType === 'days'}
              onChange={e => onDateTypeChange(e.target.value)}
            />
            {t('steps.when.form.days')}
            <span className="form-check-sign" />
          </Label>
        </div>

        <div className="days-checkboxes mb-3 pl-4">
          <RenderDayList
            daysList={daysList}
            setDays={setDays}
            days={days}
            dateType={dateType}
            isNew={true}
          />
        </div>

        <div className="form-check-radio">
          <Label id={'when-radio-label'} className="form-check-label">
            <Input
              type="radio"
              name="days-radios"
              id="days-radios-3"
              value="specificDate"
              checked={dateType === 'specificDate'}
              onChange={e => onDateTypeChange(e.target.value)}
            />
            {t('steps.when.form.specific_date')}
            <span className="form-check-sign" />
          </Label>
        </div>

        <div className="specific-date-input pl-4 mb-4 w-70 notranslate">
          <div id="generalSettings-date">
            <div className="date-container">
              <div
                className={`date-svg-container ${
                  dateType !== 'specificDate' ? 'date-disabled' : ''
                }`}>
                <img src={calendarySvg} alt="calendary-svg" />
              </div>
              <DatePicker
                style={{maxWidth: "158px"}}
                locale={'es'}
                renderCustomHeader={props => <DateHeader {...props} />}
                placeholderText='dd/mm/aaaa'
                closeOnSelect={true}
                selected={specificDate}
                onChange={v => setSpecificDate(v)}
                className="form-control notranslate"
                disabled={dateType !== 'specificDate'}
                dateFormat={'d/M/yyyy'}
                onKeyDown={(e) => e.preventDefault()}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-check-radio d-inline-block">
          <Label id={'when-radio-label'} className="form-check-label">
            <Input
              type="radio"
              name="days-radios"
              id="days-radios-4"
              value="dayofthemonth"
              checked={dateType === 'dayofthemonth'}
              onChange={e => onDateTypeChange(e.target.value)}
            />
            {t('steps.when.form.day')}
            <span className="form-check-sign" />
          </Label>
        </div>
        <FormGroup className="d-inline-block ml-3 mr-3">
          <Input
            className={'when-select'}
            value={dayOfTheMonth}
            type="select"
            name="select"
            onChange={e => setDayOfTheMonth(Number(e.target.value))}
            disabled={dateType !== 'dayofthemonth'}>
            {Array.from(new Array(31), (x, i) => (
              <option value={i + 1} key={`dayofthemonth-option-${i}`}>
                {i + 1}
              </option>
            ))}
          </Input>
        </FormGroup>
        {t('steps.when.form.of_the_month')}
      </FormGroup>
      <h2>{t('steps.when.form.time_range')}</h2>
      <Row className="time-range-row">
        <Col md="4" className="pr-1">
          <div id="generalSettings-date">
            <div className="date-container">
              <div
                className={`date-svg-container ${
                  allDay ? 'date-disabled' : ''
                }`}>
                <img src={clockSvg} alt="clock-svg" />
              </div>
              <DatePicker
                className="form-control notranslate"
                selected={startTime}
                startDate={startTime}
                endDate={endTime}
                onChange={date => {
                  setStartTime(date)
                  setEndTime(date > endTime ? date : endTime)
                }}
                selectsStart
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                disabled={allDay}
                timeCaption={t('steps.when.form.from')}
                timeFormat="HH:mm "
                dateFormat="HH:mm "
                locale="es"
              />
            </div>
          </div>
        </Col>
        <Col md="1" className="pr-1 pl-1 pt-2 text-center">
          <span>{t('steps.when.form.to_time')}</span>
        </Col>
        <Col md="4" className="pl-1 pr-1 notranslate">
          <div id="generalSettings-date">
            <div className="date-container">
              <div
                className={`date-svg-container ${
                  allDay ? 'date-disabled' : ''
                }`}>
                <img src={clockSvg} alt="clock-svg" />
              </div>
              <DatePicker
                className="form-control"
                selected={endTime}
                startDate={startTime}
                endDate={endTime}
                minDate={startTime}
                filterTime={filterPassedTime}
                onChange={date => {
                  date = moment(date).isBefore(moment(startTime))
                    ? startTime
                    : date
                  setEndTime(date)
                }}
                selectsEnd
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                disabled={allDay}
                timeCaption={t('steps.when.form.to')}
                timeFormat="HH:mm "
                dateFormat="HH:mm "
                locale="es"
              />
            </div>
          </div>
        </Col>
        <Col md="6" className="pt-2 pl-3 pr-1 text-start">
          <FormGroup check>
            <Label id={'labelCheckbox'} check>
              <Input
                type="checkbox"
                checked={allDay}
                onChange={() => setAllDay(!allDay)}
              />
              {t('steps.when.form.all_day')}
              <span className="form-check-sign">
                <span className="check" />
              </span>
            </Label>
          </FormGroup>
        </Col>
      </Row>
      {errors.map((e, index) => (
        <p style={{ color: 'red', paddingTop: '5px' }} key={index}>
          {e}
        </p>
      ))}
    </form>
  )
}

export default WhenForm
