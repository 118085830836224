import React from 'react'
import './commonCheckbox.css'

const CommonCheckbox = ({ id, value, checked, handleChange }) => {
  return typeof checked === 'boolean' ? (
    <div className="checkbox-wrapper" onClick={e => e.stopPropagation()}>
      <label htmlFor={id}>
        <input type="checkbox" checked={checked} onChange={handleChange} id={id} />
        <span className="cbx">
          <svg width="12px" height="11px" viewBox="0 0 12 11">
            <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
          </svg>
        </span>
        <span>{value}</span>
      </label>
    </div>
  ) : (
    <div className={`checkbox-wrapper ${checked === 2 ? 'checboxWrapper2' : ''}`} onClick={e => e.stopPropagation()}>
      <label htmlFor={id}>
        <input type="checkbox" checked={checked} onChange={handleChange} id={id} />
        <span className="cbx">
          <svg width="12px" height="11px" viewBox="0 0 12 11">
            <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
          </svg>
        </span>
        <span>{value}</span>
      </label>
    </div>
  )
}

export default CommonCheckbox
