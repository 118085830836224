import React, { useContext, useEffect, useState } from 'react'
import { Card, Row, Col, Label, Input, FormGroup } from 'reactstrap'
import './GeneralSettingsForm.css'
import { useTranslation } from 'react-i18next'
import { CreatePromoContext } from '../../../../context/create-promo-context'
import moment from 'moment'
import { es } from 'date-fns/locale'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Select } from '../ConditionsForm/ConditionsForm'
import { getPriceLists } from 'api/ListAPI'
import calendarySvg from '../../../../assets/svg/calendary.svg'
import clockSvg from '../../../../assets/svg/clock.svg'
import { FastSaveButton } from 'components/PromoWizard/PromoWizard'
import DateHeader from 'components/DateHeader/DateHeader'
import ToggleWithTooltip from './Components/ToggleWithTooltip/ToggleWithTooltip'

registerLocale('es', es)

const GeneralSettingsForm = props => {
  const { t } = useTranslation('createPromo')
  let { form, saveForm, originalPromo, isValid } = useContext(CreatePromoContext)
  form = form['header']
  originalPromo = originalPromo?.['header']

  const [pricesLists, setPricesLists] = useState([])
  const [excludedLists, setExcludedLists] = useState([])
  const [excludedListsId, setExcludedListsID] = useState(form?.excluded_lists)
  const [combinableWithDiscounts, setCombinableWithDiscounts] = useState(form?.not_acumulable_manual_discount)

  const [startDate, setStartDate] = useState(form.date_time_from ? new Date(form.date_time_from) : null)
  const [startTime, setStartTime] = useState(form.date_time_from ? new Date(form.date_time_from) : null)
  const [endDate, setEndDate] = useState(form.date_time_to ? new Date(form.date_time_to) : null)
  const [endTime, setEndTime] = useState(form.date_time_to ? new Date(form.date_time_to) : null)

  useEffect(() => {
    if (excludedListsId && pricesLists.length) {
      const excluded = pricesLists.filter(list => excludedListsId.includes(parseInt(list.id)))
      setExcludedLists(excluded)
    }
  }, [excludedListsId, pricesLists])

  useEffect(() => {
    if (pricesLists.length === 0) {
      fetchPricesList()
    }
  }, [pricesLists])

  useEffect(() => {
    if (startDate && startTime) {
      const combinedStartDateTime = combineDateTime(startDate, startTime)
      onFormChange('date_time_from', moment(combinedStartDateTime).valueOf())
    }

    if (startDate && !startTime) {
      setStartTime(new Date(new Date().setHours(0, 0, 0, 0)))
      setEndTime(new Date(new Date().setHours(0, 0, 0, 0)))
    }
  }, [startDate, startTime])

  useEffect(() => {
    if (endDate && endTime) {
      const combinedEndDateTime = combineDateTime(endDate, endTime)
      onFormChange('date_time_to', moment(combinedEndDateTime).valueOf())
    }
  }, [endDate, endTime])

  const onFormChange = (input, value) => {
    saveForm('header', input, value)
  }

  const combineDateTime = (date, time) => {
    const combined = moment(date).hour(moment(time).hour()).minute(moment(time).minute()).second(0).millisecond(0)
    return combined.toDate()
  }

  const datesAreOnSameDay = (first, second) => {
    return first.year() === second.year() && first.month() === second.month() && first.date() === second.date()
  }

  const filterPassedTime = time => {
    if (form.date_time_from) {
      if (!form.date_time_to) {
        let selectedDate = new Date(time)
        return moment(form.date_time_from).valueOf() < selectedDate.getTime()
      }

      if (datesAreOnSameDay(moment(form.date_time_from), moment(form.date_time_to))) {
        let selectedDate = new Date(time)
        selectedDate.setDate(moment(form.date_time_to).date())
        selectedDate.setMonth(moment(form.date_time_to).month())
        selectedDate.setFullYear(moment(form.date_time_to).year())
        return moment(form.date_time_from).valueOf() < selectedDate.getTime()
      }
    }

    return true
  }

  const onDateToChange = time => {
    if (moment(form.date_time_from) > moment(time)) {
      time = form.date_time_from
    }
    onFormChange('date_time_to', moment(time).valueOf())
  }

  const createValuesArray = arr => {
    let values = []
    if (arr && arr.length) {
      arr.forEach(arr => values.push(parseInt(arr.value)))
    }
    return values
  }

  const handleListExclusion = arr => {
    !arr && setExcludedLists([])
    arr && arr.length && setExcludedLists(arr)
    let auxArray = createValuesArray(arr)
    setExcludedListsID(auxArray)
    onFormChange('excluded_lists', auxArray)
  }

  const fetchPricesList = () => {
    const promise = getPriceLists()
      .then(({ data }) => {
        setPricesLists(data)
      })
      .catch(err => {
        setPricesLists([{ label: 'No se pudo recuperar las listas', value: 'x' }])
      })
    return promise
  }

  useEffect(() => {
    if (endDate < startDate) {
      setEndDate(startDate)
    }

    if (endTime < startTime) {
      setEndTime(startTime)
    }
  }, [startDate, startTime])

  return (
    <>
      <FastSaveButton step={1} callback={props.handleFastSave} isValid={isValid} />
      <Card className="step-container">
        <h1>{t('steps.settings.title')}</h1>
        <Row id={'general-settings-row'}>
          <Col md="6" className="form-col">
            <FormGroup>
              <Label for="input-promo-name" id={'hasRequired'}>
                {t('steps.settings.form.name')}
              </Label>
              <Input
                type="text"
                id="input-promo-name"
                value={form.name}
                onChange={e => onFormChange('name', e.target.value)}
                placeholder={'Ingrese Nombre'}
                maxLength="60"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="input-promo-desc" id={'hasRequired'}>
                {t('steps.settings.form.description')}
              </Label>
              <Input
                type="textarea"
                id="input-promo-desc"
                placeholder={'Ingrese descripción'}
                value={form.description}
                onChange={e => onFormChange('description', e.target.value)}
                maxLength="1000"
                rows="100"
                required
              />
            </FormGroup>
            <h2 className="mt-5 mb-1">{t('steps.settings.form.max_amount_application')}</h2>
            <Row>
              <Col sm="12" md="10">
                <FormGroup inline>
                  <Label for="input-max-app-voucher">{t('steps.settings.form.by_voucher')}</Label>
                  <Input
                    type="number"
                    id="input-max-app-voucher"
                    min="0"
                    value={form.limit_per_voucher}
                    onChange={e => {
                      const value = e.target.value
                      if (value.length <= 10) {
                        onFormChange('limit_per_voucher', value)
                      }
                    }}
                    placeholder={t('steps.settings.form.no_limit')}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="4">
                <FormGroup inline hidden>
                  <Label for="input-max-app-general">{t('steps.settings.form.general')}</Label>
                  <Input
                    type="number"
                    id="input-max-app-general"
                    min="0"
                    value={form.limit}
                    onChange={e => onFormChange('limit', e.target.value)}
                    placeholder={t('steps.settings.form.no_limit')}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="4">
                <FormGroup inline hidden>
                  <Label for="input-max-app-client">{t('steps.settings.form.by_client')}</Label>
                  <Input
                    type="number"
                    id="input-max-app-client"
                    min="0"
                    value={form.limit_per_customer}
                    onChange={e => onFormChange('limit_per_customer', e.target.value)}
                    placeholder={t('steps.settings.form.no_limit')}
                  />
                </FormGroup>
              </Col>
              <FormGroup check className="mt-3 ml-3">
                <Label id="labelCheckbox" check>
                  <Input
                    type="checkbox"
                    id="promo-cumulative"
                    checked={form.not_acumulable}
                    onChange={() => onFormChange('not_acumulable', !form.not_acumulable)}
                  />{' '}
                  {t('steps.settings.form.not_acumulable')}
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
            </Row>
          </Col>
          <Col md="6" className="form-col">
            <h2 id={'hasRequired'}>{t('steps.settings.form.promo_validity')}</h2>
            <FormGroup check>
              <Label id="labelCheckbox" check>
                <Input
                  type="checkbox"
                  id="promo-always-current"
                  checked={form.always_current}
                  onChange={() => onFormChange('always_current', !form.always_current)}
                />{' '}
                {t('steps.settings.form.always_current')}
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="input-promo-start-date">{t('steps.settings.form.date_time_from')}</Label>
              <div className="notranslate">
                <div className="general-settings-datePicker" id="generalSettings-date">
                  <div className="date-container">
                    <div className={`date-svg-container ${form.always_current ? 'date-disabled' : ''}`}>
                      <img src={calendarySvg} alt="calendary-svg" />
                    </div>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      renderCustomHeader={props => <DateHeader {...props} />}
                      placeholderText="Desde"
                      startDate={moment(form.date_time_from).format('DD/MM/YY HH:mm')}
                      endDate={form.date_time_to}
                      minDate={new Date()}
                      selectsStart
                      onChange={date => setStartDate(date)}
                      timeFormat="HH:mm"
                      dateFormat="d/M/yyyy"
                      locale="es"
                      timeCaption={t('steps.settings.form.from')}
                      disabled={form.always_current}
                      onKeyDown={e => e.preventDefault()}
                      maxDate={new Date('2100-12-31')}
                      required
                    />
                  </div>
                  <div className="date-container">
                    <div className={`date-svg-container ${form.always_current ? 'date-disabled' : ''}`}>
                      <img src={clockSvg} alt="clock-svg" />
                    </div>
                    <DatePicker
                      className={`form-control ${!startTime ? 'form-control-date-unabled' : ''}`}
                      selected={startTime}
                      startDate={moment(form.date_time_from).format('HH:mm')}
                      endDate={form.date_time_to}
                      minDate={new Date()}
                      placeholderText="00:00"
                      selectsStart
                      showTimeSelect
                      showTimeSelectOnly
                      onChange={time => setStartTime(time)}
                      timeFormat="HH:mm "
                      dateFormat="HH:mm "
                      maxDate={new Date('2100-12-31')}
                      locale="es"
                      timeCaption={t('steps.settings.form.from')}
                      disabled={form.always_current}
                      required
                    />
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="input-promo-end-date">{t('steps.settings.form.date_time_to')}</Label>
              <div className="notranslate">
                <div className="general-settings-datePicker" id="generalSettings-date">
                  <div className="date-container">
                    <div className={`date-svg-container ${form.always_current ? 'date-disabled' : ''}`}>
                      <img src={calendarySvg} alt="calendary-svg" />
                    </div>
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      renderCustomHeader={props => <DateHeader {...props} />}
                      startDate={form.date_time_from}
                      placeholderText="Hasta"
                      endDate={form.date_time_to}
                      minDate={startDate || new Date()}
                      selectsEnd
                      filterTime={filterPassedTime}
                      onChange={date => setEndDate(date)}
                      timeFormat="HH:mm> "
                      dateFormat="d/M/yyyy"
                      locale="es"
                      timeCaption={t('steps.settings.form.to')}
                      disabled={form.always_current}
                      onKeyDown={e => e.preventDefault()}
                      required
                    />
                  </div>
                  <div className="date-container">
                    <div className={`date-svg-container ${form.always_current ? 'date-disabled' : ''}`}>
                      <img src={clockSvg} alt="clock-svg" />
                    </div>
                    <DatePicker
                      className={`form-control ${!endTime ? 'form-control-date-unabled' : ''}`}
                      selected={endTime}
                      startDate={form.date_time_from}
                      endDate={form.date_time_to}
                      minDate={startTime}
                      placeholderText="00:00"
                      showTimeSelect
                      showTimeSelectOnly
                      selectsEnd
                      filterTime={filterPassedTime}
                      onChange={time => setEndTime(time)}
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      locale="es"
                      timeCaption={t('steps.settings.form.to')}
                      disabled={form.always_current}
                      required
                    />
                  </div>
                </div>
              </div>
            </FormGroup>
            {pricesLists.length <= 0 ? (
              <p
                style={{
                  marginTop: '2rem',
                }}>
                Cargando listas...
              </p>
            ) : (
              <FormGroup className="excluded_list">
                <h2>{t('steps.settings.form.excluded_lists')}</h2>
                <label htmlFor="excluded_list_select">{t('steps.settings.form.excluded_lists_description')}</label>
                <Select
                  id={'excluded_list_select'}
                  className="form-control p-0"
                  classNamePrefix="react-select"
                  placeholder={`${t('steps.settings.form.select')}`}
                  loadingMessage={() => `${t('steps.settings.form.loading')}`}
                  noOptionsMessage={() => t('steps.settings.form.no_results')}
                  name="multipleSelect"
                  onChange={value => handleListExclusion(value)}
                  styles={{
                    container: baseStyles => ({
                      ...baseStyles,
                    }),
                    control: baseStyles => ({
                      ...baseStyles,
                      cursor: 'pointer',
                    }),
                    option: baseStyles => ({
                      ...baseStyles,
                      cursor: 'pointer',
                      fontWeight: 'bolder',
                    }),
                  }}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  optionCheck={true}
                  isMulti
                  options={pricesLists}
                  value={excludedLists}
                  labelList={'listas seleccionadas'}
                />
                {excludedLists?.length > 2 ? (
                  <button
                    id="btnSelectCardClear"
                    type="button"
                    onClick={() => handleListExclusion([])}
                    style={{
                      position: 'absolute',
                      top: '72%',
                      left: '51%',
                      width: '3ch',
                      height: '3ch',
                      opacity: '0',
                    }}
                  />
                ) : null}
                <ToggleWithTooltip
                  label={'No acumulable con descuentos'}
                  value={form?.not_acumulable_manual_discount}
                  onChange={() => onFormChange('not_acumulable_manual_discount', !form.not_acumulable_manual_discount)}>
                  <h3>No acumulable con descuentos</h3>
                  <p>Al activar esta opción, no se acumularán los descuentos manuales con las promociones de Ántion.</p>
                </ToggleWithTooltip>
              </FormGroup>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default GeneralSettingsForm
