import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup } from 'reactstrap'
import BaseSelect from 'react-select'

import CardAttributeMp from '../../ActionsForm/components/CardAttributeMp/CardAttributeMp'
import FixRequiredSelect from 'components/common/RequiredSelect'
import style from './paymentMethodSelector.module.css'
import useToast from 'components/Toast/hooks/useToast'
import Toast from 'components/Toast/Toast'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'
import deepEqual from '../Utilities'

export const Select = props => <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />

const PaymentMethodSelector = ({
  value,
  isOpen,
  setFees,
  feesValue,
  isLoading,
  setIsOpen,
  setPaymentMethod,
  creditCardValues,
  setCreditCardValues,
  paymentMethodOptions,
  availablePaymentMethods,
}) => {
  const { t } = useTranslation('createPromo')

  const [options, setOptions] = useState(paymentMethodOptions)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [confirmationCallback, setConfirmationCallback] = useState(null)
  const [creditCardValuesLocal, setCreditCardValuesLocal] = useState({})
  const [validateButton, setValidateButton] = useState(false)
  const { toast, showToast } = useToast()
  const scrollRef = useRef(null)

  const hasChanges = useMemo(() => {
    return !deepEqual(creditCardValues, creditCardValuesLocal)
  }, [creditCardValues, creditCardValuesLocal])

  const appendNewCreditCard = params => {
    if (!validateCreditCardAndToast()) return false

    const { value, label } = params

    setCreditCardValuesLocal(prevValues => {
      const updatedValues = { ...prevValues }

      const feeNumbers = feesValue.map(fee => fee.value)

      if (updatedValues[value]) {
        updatedValues[value].fees = [...new Set([...updatedValues[value].fees, ...feeNumbers])]
      } else {
        updatedValues[value] = {
          value,
          label,
          fees: feeNumbers,
        }
      }

      return updatedValues
    })
    setPaymentMethod('')
    setFees([])
    return true
  }

  const deletePaymentMethod = param => {
    const { value } = param

    setCreditCardValuesLocal(prevValues => {
      const updatedValues = { ...prevValues }

      if (updatedValues[value]) {
        delete updatedValues[value]
      }

      return updatedValues
    })
  }

  const openCreditCard = param => {
    if (!validateCreditCardAndToast()) {
      return
    } else {
      if (value) {
        appendNewCreditCard(value)
      }
    }

    const result = paymentMethodOptions.find(item => item.value === param.id[0].value)

    if (result) {
      deletePaymentMethod(result)
      setPaymentMethod(result)
      setFees(param.id[0].fees.map(v => ({ label: v, value: v })))
    }
  }

  const validateCreditCardAndToast = () => {
    const flag = validateCreditCard()

    if (!flag) {
      showToast({
        text: 'Complete la tarjeta o borre selección.',
        type: 'error',
      })
    }

    return flag
  }

  const validateCreditCard = () => {
    if (value) {
      if (feesValue?.length) {
        return true
      }
      return false
    }

    return true
  }

  const saveCreditCard = params => {
    if (value) {
      if (validateCreditCardAndToast()) {
        const { value, label } = params
        setCreditCardValuesLocal(prevValues => {
          const updatedValues = { ...prevValues }

          const feeNumbers = feesValue.map(fee => fee.value)

          if (updatedValues[value]) {
            updatedValues[value].fees = [...new Set([...updatedValues[value].fees, ...feeNumbers])]
          } else {
            updatedValues[value] = {
              value,
              label,
              fees: feeNumbers,
            }
          }
          setCreditCardValues(updatedValues)
          return updatedValues
        })
        setPaymentMethod('')
        setFees([])
        setIsOpen(false)
      }
    } else {
      setCreditCardValues(creditCardValuesLocal)
      setIsOpen(false)
    }
  }

  const setPrevValues = () => {
    setCreditCardValuesLocal(creditCardValues)
    setPaymentMethod('')
    setFees([])
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setCreditCardValuesLocal(creditCardValues)

    const handleWheel = event => {
      if (scrollRef.current && event.deltaY !== 0) {
        event.preventDefault()
        scrollRef.current.scrollLeft += event.deltaY
      }
    }

    const container = scrollRef.current
    if (container) {
      container.addEventListener('wheel', handleWheel)
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel)
      }
    }
  }, [])

  useEffect(() => {
    const selectedKeys = Object.keys(creditCardValuesLocal)
    const filteredOptions = paymentMethodOptions?.filter(option => !selectedKeys.includes(option.value))
    setOptions(filteredOptions)
  }, [paymentMethodOptions, creditCardValuesLocal])

  useEffect(() => {
    setValidateButton(!(Object.keys(creditCardValuesLocal).length !== 0 || (value && validateCreditCard())))
  }, [value, feesValue, creditCardValuesLocal])

  const saveValues = () => {
    saveCreditCard(value)
  }

  const cancelValues = () => {
    setPrevValues()
  }

  const handleCancel = () => {
    if (hasChanges) {
      setConfirmationCallback({
        handleConfirm: () => {
          setConfirmationOpen(false)
          saveValues()
        },
        handleCancel: () => {
          setConfirmationOpen(false)
          cancelValues()
        },
      })
      setConfirmationOpen(true)
    } else {
      cancelValues()
    }
  }

  return (
    <FormGroup>
      <PopUpAlert
        value={t('confirmPopUp.title')}
        extraText={t('confirmPopUp.description')}
        isOpen={confirmationOpen}
        setIsOpen={setConfirmationOpen}
        onConfirm={{
          value: t('confirmPopUp.confirm'),
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
        onCancel={{
          value: t('confirmPopUp.cancel'),
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
      />
      {toast && <Toast {...toast} portalRef={document.querySelector('body')} className={style.toast} />}
      <div className={`${style.container} ${style[isOpen]}`}>
        <div className={style.popUp}>
          <div className={style.header}>
            <button type="button" onClick={() => handleCancel()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <path
                  d="M-1.52588e-05 6L0.63057 6.7491L5.06554 12L6.7356 10.5018L3.89444 7.1415L13 7.1415V4.8585L3.89444 4.8585L6.7356 1.4982L5.06554 -7.62939e-06L0.63057 5.25091L-1.52588e-05 6Z"
                  fill="#971B00"
                />
              </svg>
            </button>
            <h2>Tarjetas</h2>
          </div>
          <div className={`${style.treeContent} ${style[String(Object.keys(creditCardValuesLocal) !== 0)]}`}>
            <div ref={scrollRef}>
              {Object.keys(creditCardValuesLocal).map((key, idx) => {
                const current = {
                  ...availablePaymentMethods.find(e => e.value === 'CREDIT_CARD'),
                  id: [creditCardValuesLocal[key]],
                }
                return (
                  <CardAttributeMp
                    element={current}
                    deletePaymentMethod={item => deletePaymentMethod(item)}
                    onClick={() => openCreditCard(current)}
                    key={'ct_' + idx}
                  />
                )
              })}
            </div>
          </div>
          <div className={style.body}>
            <div className={style.inputContainer}>
              <span>Tipo de tarjeta</span>
              <Select
                isSearchable={false}
                className="form-control p-0"
                classNamePrefix="react-select"
                placeholder={`Seleccione el tipo de tarjeta`}
                loadingMessage={() => `Cargando datos`}
                noOptionsMessage={() => 'No se encontraron opciones'}
                name="selectPaymentMethod"
                value={value}
                onChange={v => {
                  setPaymentMethod(v)
                  setFees([])
                }}
                options={options}
                labelList={'seleccionados'}
                hideSelectedOptions={false}
                isLoading={isLoading}
                styles={{
                  container: baseStyles => ({
                    ...baseStyles,
                  }),
                  control: baseStyles => ({
                    ...baseStyles,
                    cursor: 'pointer',
                  }),
                  option: baseStyles => ({
                    ...baseStyles,
                    cursor: 'pointer',
                    fontWeight: 'bolder',
                  }),
                }}
              />
            </div>
            <div className={style.inputContainer}>
              <span>Cuotas</span>
              <Select
                className="form-control p-0"
                classNamePrefix="react-select"
                placeholder={`Seleccione las cuotas`}
                isSearchable={false}
                allowSelectAll
                name="selectPaymentMethod"
                noOptionsMessage={() => 'No se encontraron opciones'}
                isMulti
                closeMenuOnSelect={false}
                value={feesValue}
                onChange={v => setFees(v)}
                optionCheck={true}
                labelList={'seleccionados'}
                hideSelectedOptions={false}
                options={Array.isArray(value?.fees) ? value.fees.map(v => ({ label: v, value: v })) : []}
                styles={{
                  container: baseStyles => ({
                    ...baseStyles,
                  }),
                  control: baseStyles => ({
                    ...baseStyles,
                    cursor: 'pointer',
                  }),
                  option: baseStyles => ({
                    ...baseStyles,
                    cursor: 'pointer',
                    fontWeight: 'bolder',
                  }),
                }}
              />
            </div>
            <div className={style.buttonsContainer}>
              <button
                type="button"
                className={style.delete}
                onClick={() => {
                  setPaymentMethod('')
                  setFees([])
                }}>
                Borrar Selección
              </button>
              <button
                type="button"
                className={style.append}
                onClick={() => appendNewCreditCard(value)}
                disabled={!value || !feesValue}>
                + Agregar otra tarjeta
              </button>
            </div>
          </div>
          <div className={style.footer}>
            <button color="primary" type="button" className="btn-style btn-style-red" onClick={() => handleCancel()}>
              Volver
            </button>
            <button
              color="primary"
              type="button"
              className="btn-style btn-style-orange"
              disabled={validateButton}
              onClick={() => saveValues()}>
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </FormGroup>
  )
}

export default PaymentMethodSelector
