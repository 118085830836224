import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import BaseSelect from 'react-select'
import { DefaultChild, OpenableChild } from './components'
import style from './commonList.module.css'
import FixRequiredSelect from 'components/common/RequiredSelect'
import Toast from 'components/Toast/Toast'
import useToast from 'components/Toast/hooks/useToast'

export const Select = props => <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options}/>


const CommonList = ({
  list,
  from,
  fees,
  isOpen,
  loading,
  setFees,
  childOpen,
  setChildOpen,
  handleMenuOpen,
  saveCreditCards,
  validCreditCard,
  handleMenuClose,
  getValuesLength,
  currentPaymentMethod,
  listPaymentMethodFiltrada,
  deleteCurrentPaymentMethod,
  optionsSelectedForCurrentPaymentMethod,
  setCurrentPaymentMethod,
  setOptionsSelectedForCurrentPaymentMethod,
}) => {
  const { toast, showToast } = useToast()
  const { t } = useTranslation('createPromo')

  useEffect(() => {
    setChildOpen(null)
  }, [isOpen, setChildOpen])


  function agregarCreditCard(){
    if(optionsSelectedForCurrentPaymentMethod !== undefined && (fees !== null && fees.length > 0)){
      saveCreditCards()
    }else if(optionsSelectedForCurrentPaymentMethod !== undefined || (fees !== null && fees.length > 0))
      showToast({
        text: t('steps.conditions.error.creditCard'),
        type: 'error',
      })
  }
  return (
    <ul className={style.container}>
        {toast && (
        <Toast
          {...toast}
          portalRef={document.querySelector('body')}
          className={style.toast}
        />
      )}
      {Array.isArray(list)
        ? list.map((item, index) =>
            from === 'PaymentMethod' ? (
              <OpenableChild
                item={item}
                onClick={() => validCreditCard(index,item)}
                isOpen={childOpen === index}
                onClickDelete={() => deleteCurrentPaymentMethod()}
                extraButton={() => agregarCreditCard()}
                selections={getValuesLength(item)}
                key={index}>
                <div className={style.inputContainer}>
                  <span>{childOpen === index ? currentPaymentMethod?.value==="INTERDEPOSIT"? "Entidades Bancarias": t(currentPaymentMethod.name) : ''}</span>
                  <Select
                    className="form-control p-0"
                    classNamePrefix="react-select"
                    id={`${item.value}=${index}`}
                    placeholder={`${t('steps.conditions.form.select')}`}
                    loadingMessage={() => `${t('steps.conditions.form.loading')}`}
                    noOptionsMessage={() => t('steps.conditions.form.no_results')}
                    name="selectPaymentMethod"
                    onMenuClose={() => {
                      handleMenuClose()
                    }}
                    onMenuOpen={() => {
                      handleMenuOpen()
                    }}
                    value={optionsSelectedForCurrentPaymentMethod || ''}
                    onChange={v => {
                      setOptionsSelectedForCurrentPaymentMethod(v);
                      setFees([])
                    }}
                    options={listPaymentMethodFiltrada}
                    isMulti={currentPaymentMethod?.value !== 'CREDIT_CARD'}
                    closeMenuOnSelect={currentPaymentMethod?.value === 'CREDIT_CARD'}
                    optionCheck={currentPaymentMethod?.value !== 'CREDIT_CARD'}
                    labelList={'seleccionados'}
                    hideSelectedOptions={false}
                    isLoading={loading}
                    styles={{
                      container: baseStyles => ({
                        ...baseStyles,
                      }),
                      control: baseStyles => ({
                        ...baseStyles,
                        cursor: 'pointer',
                      }),
                      option: baseStyles => ({
                        ...baseStyles,
                        cursor: 'pointer',
                        fontWeight: 'bolder',
                      }),
                    }}
                  />
                  {
                    currentPaymentMethod?.value !== 'CREDIT_CARD' && optionsSelectedForCurrentPaymentMethod?.length >2  ?
                  <button id='btnSelectCardClear' type='button' onClick={()=>setOptionsSelectedForCurrentPaymentMethod(null)} style={{
                    position: 'absolute',
                    top: '50%',
                    left: '26%',
                    width: '3ch',
                    height: '3ch',
                    opacity: '0'
                  }} />

                  : null
                }
                </div>
                {item.value === 'CREDIT_CARD' || item.value === 1 ? (
                  <div className={style.inputContainer}>
                    <span>
                      {t('steps.conditions.label_fees')}
                    </span>
                    <Select
                      className="form-control p-0"
                      classNamePrefix="react-select"
                      placeholder={`${t('steps.conditions.form.fees')}`}
                      isSearchable={false}
                      allowSelectAll
                      name="selectPaymentMethod"
                      noOptionsMessage={() => t('steps.conditions.form.no_results')}
                      isMulti
                      closeMenuOnSelect={false}
                      value={fees || []}
                      onChange={v => setFees(v)}
                      optionCheck={true}
                      labelList={'seleccionados'}
                      hideSelectedOptions={false}
                      options={
                        Array.isArray(optionsSelectedForCurrentPaymentMethod?.fees)
                          ? optionsSelectedForCurrentPaymentMethod.fees.map(v => ({ label: `${v}`, value: `${v}` }))
                          : []
                      }
                      styles={{
                        container: baseStyles => ({
                          ...baseStyles,
                        }),
                        control: baseStyles => ({
                          ...baseStyles,
                          cursor: 'pointer',
                        }),
                        option: baseStyles => ({
                          ...baseStyles,
                          cursor: 'pointer',
                          fontWeight: 'bolder',
                        }),
                      }}
                    />
                    {
                  fees?.length >2  ?
                  <button id='btnSelectCardClear' type='button' onClick={()=>setFees([])} style={{
                    position: 'absolute',
                    top: '50%',
                    left: '26%',
                    width: '3ch',
                    height: '3ch',
                    opacity: '0'
                  }} />

                  : null
                }
                  </div>
                ) : null}
              </OpenableChild>
            ) : (
              <DefaultChild label={t(item.name)} onClick={() => {}} key={index} />
            )
          )
        : 'No se encontró una lista'}
    </ul>
  )
}

export default CommonList
