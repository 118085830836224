// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".defaultChild_itemChild__30z1t {\n  width: 100%;\n  border-radius: 6px;\n  border: 1px solid var(--gris-clarito, #d2d2d2);\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  grid-gap: 0.69rem;\n  gap: 0.69rem;\n  padding-inline: 1.5rem;\n  cursor: pointer;\n}\n\n.defaultChild_itemChild__30z1t:nth-last-child(1) {\n  margin-bottom: 5vh;\n}\n\n.defaultChild_withoutBorders__dt1j0 {\n  border: 0;\n}\n\n.defaultChild_withoutBorders__dt1j0 > span {\n  padding-block: 0.5rem !important;\n}\n\n.defaultChild_itemChild__30z1t > span {\n  color: var(--gris-100, #68688c);\n  font: normal 600 16px/18px Inter;\n  padding-block: 1rem;\n}\n\n.defaultChild_itemChild__30z1t > img {\n  margin-left: auto;\n}\n\n.defaultChild_container__3Dt5a {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n  align-items: center;\n  justify-content: flex-start;\n}\n", ""]);
// Exports
exports.locals = {
	"itemChild": "defaultChild_itemChild__30z1t",
	"withoutBorders": "defaultChild_withoutBorders__dt1j0",
	"container": "defaultChild_container__3Dt5a"
};
module.exports = exports;
