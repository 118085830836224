import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import style from './popUpAlert.module.css'

const PopUpAlert = ({ value, extraText, isOpen, setIsOpen, onConfirm, onCancel }) => {
  const [animateOut, setAnimateOut] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(style.noScroll)
    } else if (animateOut) {
      const timer = setTimeout(() => {
        setAnimateOut(false)
        document.body.classList.remove(style.noScroll)
      }, 200)

      return () => clearTimeout(timer)
    }

    return () => {
      document.body.classList.remove(style.noScroll)
    }
  }, [isOpen, animateOut])

  const handleClose = action => {
    setAnimateOut(true)
    setTimeout(() => {
      setIsOpen(false)
      action()
    }, 500)
  }

  if (!isOpen && !animateOut) return null

  return ReactDOM.createPortal(
    <div className={`${style.container} ${animateOut ? style['fade-out'] : style['fade-in']}`}>
      <div className={`${style.popUp} ${animateOut ? style['fade-out'] : style['fade-in']}`}>
        <div className={style.content}>
          <span>{value}</span>
          {extraText ? <span>{extraText}</span> : null}
        </div>
        <div className={style.footer}>
          <button
            color="primary"
            className={style.delete}
            type="button"
            outline
            onClick={() => handleClose(onCancel.function)}>
            {onCancel.value}
          </button>
          <button
            color="primary"
            className={style.append}
            type="button"
            form="add-promo-form"
            onClick={() => handleClose(onConfirm.function)}>
            {onConfirm.value}
          </button>
        </div>
      </div>
    </div>,
    document.querySelector('body')
  )
}

export default PopUpAlert
