import React, { useEffect, useRef, useState } from 'react'
import '../CardAttribute/CardAttribute.css'
import { useTranslation } from 'react-i18next'

const CardAttributeMp = ({ element, deletePaymentMethod,onClick }) => {
  const { t } = useTranslation('createPromo')
  const [text, setText] = useState('')
  const liRef = useRef(null)
  const [isTextOverflowing, setIsTextOverflowing] = useState(false)

  useEffect(() => {
    if (element) {
      let textCard = recorrerId().join('')
      if (element.value == 'CREDIT_CARD') textCard = recorrerFees(textCard + ' | Cuotas:  ').join('')
      setText(textCard)
    }
  }, [element])

  useEffect(() => {
    const element = liRef.current
    if (element) {
      const isOverflowing = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
      setIsTextOverflowing(isOverflowing)
    }
  }, [text])

  function recorrerId() {
    let auxString = []
    if (element && element?.id.length > 0) {
      for (let i = 0; i < element.id.length; i++) {
        const id = element.id[i]
        if (i < element.id.length - 1) auxString.push(id.label + ', ')
        if (i === element.id.length - 1) auxString.push(id.label + ' ')
      }
    }
    return auxString
  }

  function recorrerFees(auxText) {
    let auxString = [auxText]
    if (element.value == 'CREDIT_CARD' && element?.id[0] && element?.id[0]?.fees) {
      element.id[0].fees.forEach((fee, i) => {
        if (i < element.id[0].fees.length - 1) auxString.push(fee + ', ')
        if (i === element.id[0].fees.length - 1) auxString.push(fee + ' ')
      })
    }
    return auxString
  }

  return (
    <ul className="CardAtribute-container" onClick={onClick}>
      {element && element.name && (
        <div className="CardAtribute-entity">
          <p className="CardAtribute-titule">{t(element.name)}{element.value === 'CREDIT_CARD'? ': ':'  | '}</p>
        </div>
      )}
      <div className="CardAtributeMp-list">
        <div className="CardAtributeMp-list-div">
          <li
            id="CardAtributeMp-list-div"
            ref={liRef}
            className={`CardAtributeMp-list-div ${isTextOverflowing ? 'text-overflow' : ''}`}>
            {text}{' '}
          </li>
        </div>
      </div>
      <div
        className="CardAtribute-list-element"
        onClick={e => {
          e.stopPropagation()
          deletePaymentMethod(element.value === 'CREDIT_CARD'? element?.id[0] : element.value)
        }}>
        x
      </div>
    </ul>
  )
}

export default CardAttributeMp
