export const numberFormatter = () => {
    const numberFormat = new Intl.NumberFormat('en-US', {
        prefix: "$",
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })

    const integer = new Intl.NumberFormat('en-US', {
        prefix: "$",
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })

    return {
        numberFormat,
        integer
    };
}

export default numberFormatter;