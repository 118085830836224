import React from 'react'

import style from './CardSelect.module.css'

const CardSelect = props => {
  const deleteId = () => {
    props.setActives(aux => aux.filter(element => element.value !== props.entity.value))
    props.setIdsActiveLocal(aux => aux.filter(element => element !== props.entity.value))
  }
  return (
    <div className={style.container}>
      <div>
        <p className={style.titulo}>{props.entity.lineBreak ? props.entity.value + ' - ' : ''} {props.entity.label}</p>
      </div>
      <div className={style.delete} onClick={() => deleteId()}>
        x
      </div>
    </div>
  )
}

export default CardSelect
