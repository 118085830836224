import Select from 'react-select'
import { getMonth, getYear } from 'date-fns'
import React from 'react'
import './dateHeader.css'

const range = (start, end, step) => {
  const array = []
  for (let i = start; i <= end; i += step) {
    array.push(i)
  }
  return array
}

const DateHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const years = range(2000, 2050, 1).map(year => ({
    value: year.toString(),
    label: year.toString(),
  }))

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]

  const handleYearChange = selectedOption => {
    changeYear(Number(selectedOption.value))
  }

  return (
    <div className={'dateHeader'}>
      <button className={'dateHeader-button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
          <path
            d="M0.697158 5L4.8583 0.717493L5.30321 1.17538L1.93252 4.65196L1.59508 5L1.93252 5.34804L5.30321 8.82462L4.8583 9.28251L0.697158 5Z"
            fill="#348888"
            stroke="#348888"
          />
        </svg>
      </button>

      <div className={'dateHeader-container'}>
        <p className={'dateHeader-month'}>{months[getMonth(date)]}</p>
        <Select
          className={'dateHeader-select'}
          isDate={true}
          title="year"
          label=""
          inputId="year-select"
          options={years.reverse()}
          onChange={handleYearChange}
          value={years.find(option => option.value === getYear(date).toString())}
        />
      </div>

      <button className={'dateHeader-button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled} type="button">
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
          <path
            d="M5.30284 5L1.1417 9.28251L0.69679 8.82462L4.06748 5.34804L4.40492 5L4.06748 4.65196L0.69679 1.17538L1.1417 0.717493L5.30284 5Z"
            fill="#348888"
            stroke="#348888"
          />
        </svg>
      </button>
    </div>
  )
}

export default DateHeader
