import Switch from 'react-switch'
import React from 'react'

import style from './toggleWithTooltip.module.css'
import infoSVG from '../../assets/info.svg'

const ToggleWithTooltip = ({ label, children, value, onChange, loading }) => {
  return (
    <div className={style.container}>
      <div className={style.content} onClick={onChange}>
        <span className={style.span}>{label}</span>
        <div className={style.tooltipContainer}>
          <img src={infoSVG} className={style.svg} />
          <div className={style.tooltip}>{children}</div>
        </div>
      </div>
      <Switch
        checked={value}
        onChange={onChange}
        onColor="#FDC792"
        onHandleColor="#FF7D00"
        disabled={loading}
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={13}
        width={30}
        className="react-switch toggle-pausar"
      />
    </div>
  )
}

export default ToggleWithTooltip
