import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { FormGroup } from 'reactstrap'

import deepEqual from 'components/PromoWizard/Steps/ConditionsForm/Utilities'
import { CardAttributeMp, CommonInput, CommonList } from '..'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'
import usePaymentMethod from './Hooks/usePaymentMethod'
import useToast from 'components/Toast/hooks/useToast'
import style from './paymentMethodWrapper.module.css'
import Toast from 'components/Toast/Toast'

const PaymentMethodWrapper = ({
  IS_ARG,
  FORM,
  handleMenuClose,
  handleMenuOpen,
  allPaymentMethodsSelected,
  setAllPaymentMethodsSelected,
  allCreditCard,
  setAllCreditCard,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [confirmationCallback, setConfirmationCallback] = useState(null)

  const scrollRef = useRef(null)
  const { toast, showToast } = useToast()

  const values = usePaymentMethod({
    FORM,
    IS_ARG,
    isOpen,
    allPaymentMethodsSelected,
    setAllPaymentMethodsSelected,
    allCreditCard,
    setAllCreditCard,
  })
  const { t } = useTranslation('createPromo')

  const {
    fees,
    objectsSize,
    inputLoading,
    setPrevValues,
    setSaveValues,
    openCreditCard,
    saveCreditCards,
    validCreditCard,
    deleteCreditCard,
    allCreditCardLocal,
    currentPaymentMethod,
    removePaymentMethods,
    availablePaymentMethods,
    deleteCurrentPaymentMethod,
    allPaymentMethodsSelectedLocal,
    optionsSelectedForCurrentPaymentMethod,
  } = values

  const hasChanges = useMemo(() => {
    const paymentMethodsChanged = !deepEqual(allPaymentMethodsSelected, allPaymentMethodsSelectedLocal)
    const creditCardChanged = !deepEqual(allCreditCard, allCreditCardLocal)

    if (currentPaymentMethod?.value === 'CREDIT_CARD') {
      if (optionsSelectedForCurrentPaymentMethod !== undefined && fees !== null && fees.length > 0) {
        return true
      }
    }

    return paymentMethodsChanged || creditCardChanged
  }, [
    fees,
    allCreditCard,
    allPaymentMethodsSelected,
    allPaymentMethodsSelectedLocal,
    allCreditCardLocal,
    currentPaymentMethod,
    optionsSelectedForCurrentPaymentMethod,
  ])

  const hasPaymentMethods = useMemo(() => {
    return Object.keys(allCreditCardLocal).length !== 0 || Object.keys(allPaymentMethodsSelectedLocal).length !== 0
  }, [allCreditCardLocal, allPaymentMethodsSelectedLocal])

  useEffect(() => {
    const handleWheel = event => {
      if (scrollRef.current && event.deltaY !== 0) {
        event.preventDefault()
        scrollRef.current.scrollLeft += event.deltaY
      }
    }

    const container = scrollRef.current
    if (container) {
      container.addEventListener('wheel', handleWheel)
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel)
      }
    }
  }, [])

  const saveValues = () => {
    if (currentPaymentMethod?.value === 'CREDIT_CARD') {
      if (optionsSelectedForCurrentPaymentMethod !== undefined && fees !== null && fees.length > 0) {
        saveCreditCards()
        setSaveValues()
        setIsOpen(!isOpen)
      } else if (optionsSelectedForCurrentPaymentMethod !== undefined || (fees !== null && fees.length > 0))
        showToast({
          text: t('steps.conditions.error.creditCard'),
          type: 'error',
        })
      else {
        setSaveValues()
        setIsOpen(!isOpen)
      }
    } else {
      setSaveValues()
      setIsOpen(!isOpen)
    }
  }

  const cancelValues = () => {
    setPrevValues()
    setIsOpen(!isOpen)
  }

  const handleCancel = () => {
    if (hasChanges) {
      setConfirmationCallback({
        handleConfirm: () => {
          setConfirmationOpen(false)
          saveValues()
        },
        handleCancel: () => {
          setConfirmationOpen(false)
          cancelValues()
        },
      })
      setConfirmationOpen(true)
    } else {
      setConfirmationOpen(false)
      cancelValues()
    }
  }

  return (
    <FormGroup>
      <PopUpAlert
        value={t('confirmPopUp.title')}
        extraText={t('confirmPopUp.description')}
        isOpen={confirmationOpen}
        setIsOpen={setConfirmationOpen}
        onConfirm={{
          value: t('confirmPopUp.confirm'),
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
        onCancel={{
          value: t('confirmPopUp.cancel'),
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
      />
      {toast && <Toast {...toast} portalRef={document.querySelector('body')} className={'RadioCheckToast'} />}
      <CommonInput
        label={'Medios de Pago'}
        labelPlural={'Medios de Pago seleccionados'}
        labelSingular={'Medio de Pago seleccionado'}
        onClick={() => setIsOpen(!isOpen)}
        onClickCross={removePaymentMethods}
        objectsSize={objectsSize}
        loading={inputLoading}
        placeholder={t('steps.actions.form.select_all_payment_method')}
      />
      <div className={`${style.container} ${style[isOpen]}`}>
        <div className={style.popUp}>
          <div className={style.header}>
            <button type="button" onClick={() => handleCancel()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <path
                  d="M-1.52588e-05 6L0.63057 6.7491L5.06554 12L6.7356 10.5018L3.89444 7.1415L13 7.1415V4.8585L3.89444 4.8585L6.7356 1.4982L5.06554 -7.62939e-06L0.63057 5.25091L-1.52588e-05 6Z"
                  fill="#971B00"
                />
              </svg>
            </button>
            <h2>Medio de pago</h2>
          </div>
          <div className={`${style.treeContent} ${style[String(hasPaymentMethods)]}`}>
            <div ref={scrollRef}>
              {Object.keys(allPaymentMethodsSelectedLocal).map((key, idx) => {
                const current = {
                  ...availablePaymentMethods.find(e => e.value === key),
                  id: allPaymentMethodsSelectedLocal[key],
                }

                if (!Array.isArray(current.id)) {
                  return null
                }

                const index = availablePaymentMethods.findIndex(item => item.value === current.value)

                return (
                  <CardAttributeMp
                    element={current}
                    onClick={() => validCreditCard(index, current)}
                    deletePaymentMethod={deleteCurrentPaymentMethod}
                    key={idx}
                  />
                )
              })}
              {Object.keys(allCreditCardLocal).map((key, idx) => {
                const current = {
                  ...availablePaymentMethods.find(e => e.value === 'CREDIT_CARD'),
                  id: [allCreditCardLocal[key]],
                }
                const index = availablePaymentMethods.findIndex(item => item.value === current.value)

                return (
                  <CardAttributeMp
                    element={current}
                    onClick={() => {
                      openCreditCard(current, index)
                    }}
                    deletePaymentMethod={deleteCreditCard}
                    key={'ct_' + idx}
                  />
                )
              })}
            </div>
          </div>
          <div className={style.body}>
            <CommonList
              list={availablePaymentMethods}
              from="PaymentMethod"
              handleMenuOpen={handleMenuOpen}
              handleMenuClose={handleMenuClose}
              isOpen={isOpen}
              {...values}
            />
          </div>
          <div className={style.footer}>
            <button color="primary" type="button" className="btn-style btn-style-red" onClick={() => handleCancel()}>
              Volver
            </button>
            <button color="primary" type="button" className="btn-style btn-style-orange" onClick={() => saveValues()}>
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </FormGroup>
  )
}

export default PaymentMethodWrapper
