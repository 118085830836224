import React, { useContext, useState } from 'react'
import StepWizard from 'react-step-wizard'
import GeneralSettingsForm from './Steps/GeneralSettingsForm/GeneralSettingsForm'
import StepLayout from './StepLayout'
import './css/PromoWizard.css'
import WhenForm from './Steps/WhenForm/WhenForm'
import WhereForm from './Steps/WhereForm/WhereForm'
import ConditionsForm from './Steps/ConditionsForm/ConditionsForm'
import { useTranslation } from 'react-i18next'
import {
  initialGeneralSettingsForm,
  initialWhenForm,
  initialActionForm,
} from './initialForm'
import { CreatePromoContext } from '../../context/create-promo-context'
import ActionsForm from './Steps/ActionsForm/ActionsForm'
import PromoSettingsContainer from './PromoSettingsContainer'
import {
  parseAction,
  parseActivation,
  parseOrigins,
  parseRule,
} from '../../utils/promoParser'
import saveIcon from "../../assets/svg/save.svg"

export const FastSaveButton = (props) => {
  const context = useContext(CreatePromoContext)

  return(
    <button className={`create-promo-fast-save ${props.step === 1 ? 'first-step' : ''}`} onClick={props.callback} disabled={!props.isValid} type='button'>
      <img src={saveIcon} alt='guardar-cambios' />
    </button>
  )
}

const PromoWizard = props => {
  const { t } = useTranslation('createPromo')
  const context = useContext(CreatePromoContext)
  const [submitting, setSubmitting] = useState(false)

  return (
    <StepWizard initialStep={1} isLazyMount>
      {/* https://www.npmjs.com/package/react-step-wizard */}
      <StepLayout saveForm={context.saveForm}
              >
        <GeneralSettingsForm
          initialForm={Object.assign({}, initialGeneralSettingsForm)}
        />
      </StepLayout>
      <StepLayout
        title={t('steps.when.title')}
        settingsKey="activations"
        addForm={WhenForm}
        initialAddForm={Object.assign({}, initialWhenForm)}>
        <PromoSettingsContainer
          columns={[
            t('steps.when.columns.day'),
            t('steps.when.columns.time_range'),
            t('steps.when.columns.actions'),
          ]}
          parser={parseActivation}
          settings={context.form.body.activations}
          allowCopy={true}
        />
      </StepLayout>
      <StepLayout
        title={t('steps.where.title')}
        settingsKey="origins"
        unique={true}
        initialAddForm={context.form.body.origins}
        addForm={WhereForm}>
        <PromoSettingsContainer
          columns={[
            t('steps.where.columns.added_settings_info'),
            t('steps.where.columns.actions'),
          ]}
          settings={
            context.form.body.origins.length ? [context.form.body.origins] : []
          }
          parser={parseOrigins}
          allowCopy={false}
        />
      </StepLayout>
      <StepLayout
        title={t('steps.conditions.title')}
        settingsKey="rules"
        addForm={ConditionsForm}>
        <PromoSettingsContainer
          columns={[
            t('steps.conditions.columns.added_settings_info'),
            t('steps.conditions.columns.actions'),
          ]}
          parser={parseRule}
          settings={context.form.body.rules}
          allowCopy={false}
        />
      </StepLayout>
      <StepLayout
        title={t('steps.actions.title')}
        settingsKey="actions"
        initialAddForm={Object.assign({}, initialActionForm)}
        submitting={submitting}
        setSubmitting={setSubmitting}
        addForm={ActionsForm}>
        <PromoSettingsContainer
          columns={[
            t('steps.actions.columns.added_settings_info'),
            t('steps.actions.columns.actions'),
          ]}
          settings={context.form.body.actions}
          parser={parseAction}
          allowCopy={true}
          submitting={submitting}
        />
      </StepLayout>
    </StepWizard>
  )
}

export default PromoWizard
