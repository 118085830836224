import React, { useContext, useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Button from '../common/Button/Button'
import './css/StepLayout.css'
import { CreatePromoContext } from '../../context/create-promo-context'
import { useTranslation } from 'react-i18next'
import { createPromo, updatePromo } from '../../api/PromotionsAPI'
import { Redirect } from 'react-router-dom'
import imgAdd from '../PromotionsTable/assets/Union.svg'
import { useHistory } from 'react-router-dom'

const StepLayout = props => {
  const { t } = useTranslation('createPromo')
  const { submitting, setSubmitting } = props
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const context = useContext(CreatePromoContext)
  const [draftAddForm, setDraftAddForm] = useState(props.initialAddForm)
  const [submitted, setSubmitted] = useState(false)
  const [editingIndex, setEditingIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(
    () => {
      if(context.currentStep !== props.currentStep){
        props.goToStep(context.currentStep)
      }else{
        context.setCurrentStep(props.currentStep)}
      },
    [context, props.currentStep]
  )
  useEffect(
    () =>
      document
        .getElementsByClassName('create-promo-container')[0]
        .scrollIntoView(),
    []
  )
  const isStepValid = !props.settingsKey
    ? true
    : context.form['body'][props.settingsKey].length > 0

  const onDraftFormChange = (name, value) => {
    let newForm = { ...draftAddForm }
    newForm[name] = value
    setDraftAddForm(newForm)
  }
  const handleSubmit = event => {
    if (context.menuIsOpen) {
      event.preventDefault()
    }
  }

  const editSetting = (e, setting, index) => {
    e.preventDefault()
    setDraftAddForm(setting)
    setEditingIndex(index)
    setIsEditing(Number.isFinite(index))
    setModalOpen(true)
  }

  const deleteSetting = (e, index) => {
    e.preventDefault()
    context.deleteSetting(props.settingsKey, index)
  }

  const onConfirm = () => {
      setSubmitting(true)
      const action =
        context.form.header.id && !context.copy
          ? updatePromo(context.form, context.originalPromo)
          : createPromo(context.form)
      action
        .then(res => {
          if(!context.originalPromo) {
            sessionStorage.setItem('promoAs', 'creation')
          }else {
            if(!context.copy && context.form.header.id){
              sessionStorage.setItem( 'promoAs', 'edit')
            }else {
              sessionStorage.setItem('promoAs', 'copy')
            }
          }
          setSubmitted(true)
        })
        .catch(err => console.log(err))
  }


  const onFastSave = async() => {
      const action =
      context.form.header.id && !context.copy
        ? updatePromo(context.form, context.originalPromo)
        : createPromo(context.form)
      try{
        await action
        if(!context.originalPromo) {
          sessionStorage.setItem('promoAs', 'creation')
        }else {
          if(!context.copy && context.form.header.id){
            sessionStorage.setItem( 'promoAs', 'edit')
          }else {
            sessionStorage.setItem('promoAs', 'copy')
          }
        }
      }catch{
        sessionStorage.setItem('promoAs', 'false')
      }finally{
        history.push("/")
      }
  }

  const onAdd = setting => {
    context.addSetting(setting, props.settingsKey, editingIndex)
    toggleModalOpen()
  }

  const onAddUniqueSetting = settings => {
    context.saveForm('body', props.settingsKey, settings)
    toggleModalOpen()
  }

  const resetDraftForm = () => setDraftAddForm(props.initialAddForm)
  const toggleModalOpen = () => {
    if (modalOpen) {
      setIsEditing(false)
    }
    setModalOpen(!modalOpen)
    // Cleanup form
    resetDraftForm()
  }
  const modal = props.addForm ? (
    <Modal isOpen={modalOpen} toggle={toggleModalOpen} backdrop="static">
      <ModalBody>
        {React.createElement(props.addForm, {
          form: draftAddForm,
          onFormChange: onDraftFormChange,
          addSetting: props.unique ? onAddUniqueSetting : onAdd,
          isEditing: isEditing,
          editingIndex: editingIndex,
        })}
      </ModalBody>
      <ModalFooter>
        <button
          color="primary"
          className="btn-style btn-style-red"
          outline
          onClick={toggleModalOpen}>
          {t('cancel')}
        </button>
        <button
          color="primary"
          className="btn-style btn-style-orange"
          type="submit"
          form="add-promo-form"
          onClick={handleSubmit}>
          {t('save')}
        </button>
      </ModalFooter>
    </Modal>
  ) : null

  if (submitted) {
    return <Redirect to="/" />
  }

  return (
    <div className="step-layout">
      {modal}
      {props.title && props.settingsKey && props.addForm && (
        <div className="actions-container">
          {/*<h1>{`${props.title} (${context.form.header.name})`}</h1>*/}
          <h1>{`${props.title}`}</h1>
          {!(
            props.unique && context.form.body[props.settingsKey].length !== 0
          ) && (
            <button
              color="primary"
              onClick={() => {
                setEditingIndex(null)
                toggleModalOpen()
              }}
              className="stepLayout-add-btn position-absolute mr-4"
              outline
              style={{ right: 0 }}>
              <img src={imgAdd} alt="add.svg" />
              <p>{t('add')}</p>
            </button>
          )}
        </div>
      )}

      <form
        id="promo-form"
        onSubmit={e => {
          e.preventDefault()
          context.setCurrentStep(context.currentStep+1)
        }}>
        {React.cloneElement(props.children, {
          editSetting: editSetting,
          deleteSetting: deleteSetting,
          editingIndex: editingIndex,
          handleFastSave: onFastSave
        })}
      </form>

      <div className="actions-container--footer">
        {props.currentStep > 1 && (
          <button
            color="primary"
            outline
            style={{ left: 0 }}
            className="btn-style btn-style-red"
            onClick={()=>{
              props.previousStep();
              context.setCurrentStep((step) => step - 1)
            }}>
            {t('back')}
          </button>
        )}
        {props.currentStep === props.totalSteps ? (
          <button
            color="primary"
            type="button"
            disabled={context.originalPromo && !context.copy ? !context.isValid : props.title === 'Acciones' ? !context.isValid : (submitting || !isStepValid) }
            onClick={onConfirm}
            className="btn-style btn-style-orange mr-4"
            style={{ right: 0 }}>
            {' '}
            {context.form.header.id && !context.copy
              ? t('save_changes')
              : t('confirm')}{' '}
          </button>
        ) : (
          <button
            color="primary"
            type="submit"
            form="promo-form"
            disabled={!isStepValid}
            className="btn-style btn-style-orange mr-4"
            style={{ right: 0 }}>
            {t('next')}
          </button>
        )}
      </div>
    </div>
  )
}

export default StepLayout
