import React, { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Switch from 'react-switch'
import NumberInput from 'components/common/NumberInput';

function DiscountSection({ t, actionType, maxDiscount, setMaxDiscount, loading }) {
    const [isDiscountLimite, setIsDiscountlimite] = useState(maxDiscount > 0)

    useEffect(() => {
        if (isDiscountLimite === false)
            setMaxDiscount(0)
    }, [isDiscountLimite, setMaxDiscount])

    return (
        actionType != null && (actionType?.value === "GENERAL_DISCOUNT" || actionType?.value === "DISCOUNT") ?
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <FormGroup id={'discountCap'} tag="fieldset">
                    <div className="d-inline-block">
                        <Label className="form-check-label" style={{color: "#68688C"}}>
                            {t('steps.actions.form.discount_cap_apply')}
                        </Label>
                    </div>
                    <div className="d-inline-block mr-2 ml-2" style={{ width: '9.3em' }}>
                        <NumberInput
                            className={`${!isDiscountLimite ? 'date-disabled' : ''}`}
                            type='number'
                            min={0.1}
                            onChange={setMaxDiscount}
                            value={maxDiscount}
                            increment={1}
                            precision={2}
                            disabled={!isDiscountLimite}
                            required
                        />
                    </div>
                </FormGroup>
                <Switch
                    checked={isDiscountLimite}
                    onChange={(e) => setIsDiscountlimite(e)}
                    onColor="#FDC792"
                    onHandleColor="#FF7D00"
                    disabled={loading}
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={13}
                    width={30}
                    className="react-switch toggle-pausar"
                />
            </div>
            : null
    );
}

export default DiscountSection;
