import React, { useEffect, useState, useContext, useMemo } from 'react'
import { FormGroup, Input, Label, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import BaseSelect from 'react-select'
import axios from 'axios'

import { CreatePromoContext } from 'context/create-promo-context'
import FixRequiredSelect from '../../../common/RequiredSelect'
import { productEntities } from '../ConditionsForm/variables'
import { validIdsURLElement } from 'utils/regularExpresion'
import { getEntities } from '../../../../api/EntitiesAPI'
import NumberInput from '../../../common/NumberInput'
import { getEntityNames } from 'api/EntitiesAPI'
import { actionTypes } from './variables'

import { AttributeWrapper, AttributeSelector, PaymentMethodWrapper } from './components'
import './ActionsForm.css'
import { v4 as randomUUID } from 'uuid'
import DiscountSection from './DiscountSection'
import CreditNoteWrapper from './components/CreditNoteWrapper/CreditNoteWrapper.jsx'

const Select = props => <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />

const ActionsForm = props => {
  const context = useContext(CreatePromoContext)
  const { t, i18n } = useTranslation('createPromo')
  const [actionType, setActionType] = useState(
    props.form.actionType ? props.form.actionType : actionTypes.find(actionType => actionType.value === props.form.type)
  )
  const [productOptions, setProductOptions] = useState(props.form.productOptions && [])
  const [product, setProduct] = useState(props.form.product || '')
  const [byPercentage, setByPercentage] = useState(props.form.percentage)
  const [discount, setDiscount] = useState(props.form.discount)
  const [byEntityIndex, setByEntityIndex] = useState(
    props.form.attributes &&
      props.form.attributes[0] &&
      props.form.attributes[0].entity &&
      props.form.type === 'PRODUCT_BONUS'
      ? productEntities.findIndex(prodEntity => prodEntity.value === props.form.attributes[0].entity)
      : ''
  )
  const [byEntity, setByEntity] = useState(Number.isFinite(byEntityIndex) ? productEntities[byEntityIndex] : null) //endpoint y name
  const [entityOptions, setEntityOptions] = useState(props.form.a && []) //Opciones disponibles
  const [entities, setEntities] = useState(props.form.entities ? [props.form.entities] : []) //Opciones selecionadas
  const [attributes, setAttributes] = useState(props.form.attributes ? props.form.attributes : []) //Atributos
  const [attributesPersist, setAttributesPersist] = useState(props.form.attributes ? props.form.attributes : []) //Son los que quedan al guardar
  const [entityAttributes, setEntityAttributes] = useState([])
  const [discountAllUnits, setDiscountAllUnits] = useState(
    props.form.lower_unit_price ? 2 : !props.form.quantity > 0 ? 1 : 0
  )
  const [discountUnits, setDiscountUnits] = useState(props.form.quantity ? props.form.quantity : 1)
  const [loading, setLoading] = useState(false)
  const [filterEntityBy, setFilterEntityBy] = useState('')
  const [filterProductBy, setFilterProductBy] = useState('')
  const [filteredEntityOptions, setFilteredEntityOptions] = useState([])
  const [filteredProductOptions, setFilteredProductOptions] = useState([])
  const [productPage, setProductPage] = useState(0)
  const [entityPage, setEntityPage] = useState(0)
  const [fetchedAllProductPages, setFetchedAllProductPages] = useState(false)
  const [fetchedAllEntityPages, setFetchedAllEntityPages] = useState(false)
  const [cancelToken, setCancelToken] = useState(null)
  const [filterCancelToken, setFilterCancelToken] = useState(null)
  const [openSelect, setOpenSelect] = useState(false)
  const [attributesValid, setAttributesValid] = useState('')
  const [entityValid, setEntityValid] = useState('')
  const [listaPrecioFijo, setListaPrecioFijo] = useState([])
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [actionTypeValue, setActionTypeValue] = useState(undefined)

  const IS_ARG = i18n && i18n.language && i18n.language.includes('es_arg')
  const [attributeSelectorOpen, setAttributeSelectorOpen] = useState(false)
  const [errors, setErrors] = useState([])
  //Cuando se Guardan
  // Opciones Seleccionadas en todos los metodos de pago (sin tarjeta de credito)
  const [allPaymentMethodsSelected, setAllPaymentMethodsSelected] = useState({})
  //Seleciones de tarjeta de credito
  const [allCreditCard, setAllCreditCard] = useState({})
  const [maxDiscount, setMaxDiscount] = useState(props.form.max_discount ? props.form.max_discount : 0.0)
  const [listarPrecioFijoAccionesFiltrados, setListarPrecioFijoAccionesFiltrados] = useState([])
  const [entityForCreditNote, setEntityForCreditNote] = useState(props.form.action_line_entity || [])
  const [creditNoteActive, setCreditNoteActive] = useState(props.form.generate_credit_note || false)
  const [creditNoteError, setCreditNoteError] = useState(undefined)
  const [creditNoteOpenSelect, setCreditNoteOpenSelect] = useState(false)

  useEffect(() => {
    setErrors([])
  }, [maxDiscount, actionType, discountAllUnits, discountUnits, entities, attributes, byPercentage, discount, product])

  useEffect(() => {
    let listaOptions = filterProductBy ? filteredProductOptions : productOptions
    if (!listaOptions) listaOptions = []
    else {
      if (actionType != null && actionType.value === 'SET_PRICE' && listaOptions != null && listaOptions.length > 0) {
        listaOptions = listaOptions
          .filter(option => !listaPrecioFijo.includes(option.value))
          .map(item => {
            return { label: item.label, value: item.value }
          })
      } else listaOptions = listaOptions.map(item => ({ label: item.label, value: item.value }))
    }
    setListarPrecioFijoAccionesFiltrados(listaOptions)
  }, [actionType, filterProductBy, filteredProductOptions, listaPrecioFijo, productOptions])

  useEffect(() => {
    setListaPrecioFijo(listarPrecioFijoAcciones())
  }, [])

  //limpiar selector de entity cuando abris popup
  useEffect(() => {
    if (attributeSelectorOpen) {
      setByEntityIndex('')
      setByEntity(null)
      setEntityOptions([])
      setEntities([])
    }
  }, [attributeSelectorOpen])

  function listarPrecioFijoAcciones() {
    let auxListaPrecioFijo = []
    if (context != null && context.form != null && context.form.body != null && context.form.body.actions) {
      let acciones = context.form.body.actions
      for (let i = 0; i < acciones.length; i++) {
        const accion = acciones[i]
        if (accion.type === 'SET_PRICE' && accion.attributes !== null && accion.attributes.length > 0) {
          if (accion.attributes[0]?.ids != null && accion.attributes[0]?.ids.length > 0) {
            auxListaPrecioFijo.push(accion.attributes[0].ids[0])
          }
        }
      }
    }
    return auxListaPrecioFijo
  }

  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((acc, key) => {
        acc[key] = obj[key]
        return acc
      }, {})
  }

  function isRepeatAction(actionNew) {
    let resp = false
    let auxAction = JSON.parse(JSON.stringify(actionNew))
    if (!auxAction.hasOwnProperty('quantity')) auxAction.quantity = 0
    if (!auxAction.hasOwnProperty('attributes')) auxAction.attributes = []

    if (context != null && context.form != null && context.form.body != null && context.form.body.actions) {
      let acciones = JSON.parse(JSON.stringify(context.form.body.actions))
      if (props?.isEditing) {
        if (props.form?.id) {
          acciones = acciones.filter(e => e.id !== props.form.id)
        } else {
          if (props.form?.auxId) acciones = acciones.filter(e => e.auxId !== props.form.auxId)
        }
      }

      if (auxAction.hasOwnProperty('id')) {
        delete auxAction.id
      }
      if (auxAction.hasOwnProperty('auxId')) {
        delete auxAction.auxId
      }
      if (auxAction.discount) auxAction.discount = auxAction.discount.toString()
      if (auxAction.quantity) auxAction.quantity = auxAction.quantity.toString()

      for (let i = 0; i < acciones.length; i++) {
        const accion = acciones[i]
        if (accion.hasOwnProperty('id')) {
          delete accion.id
        }
        if (accion.hasOwnProperty('auxId')) {
          delete accion.auxId
        }
        if (!accion.hasOwnProperty('quantity')) accion.quantity = 0
        if (!accion.hasOwnProperty('attributes')) accion.attributes = []
        if (accion.discount) accion.discount = accion.discount.toString()
        if (accion.quantity) accion.quantity = accion.quantity.toString()
        const sortedAccion = sortObject(accion)
        const sortedActionNew = sortObject(auxAction)
        if (JSON.stringify(sortedAccion) === JSON.stringify(sortedActionNew)) {
          resp = true
          break
        }
      }
    }
    return resp
  }

  useEffect(() => {
    let isValid = ''
    if (attributeHasId().length > 0) isValid = 'true'
    setAttributesValid(isValid)
  }, [attributes, setAttributesValid])

  useEffect(() => {
    let isValid = ''
    if (attributeHasId().length > 0) isValid = 'true'
    setEntityValid(isValid)
  }, [attributes, setEntityValid])

  useEffect(() => {
    if (!(props.form.type === 'SET_PRICE' && props.isEditing === false)) {
      if (props.form.attributes && props.form.attributes.length > 0) {
        props.form.attributes.forEach(attribute => {
          let entityType = productEntities.find(a => a.value === attribute.entity)
          if (entityType.value === 'PRODUCT_ID' && attribute.ids?.length) {
            getEntityNames(entityType.endpoint, attribute.ids).then(({ data }) => {
              setProduct({
                value: data[0].id,
                label: data[0].id + ' - ' + data[0].description,
              })
            })
          }
        })
      }
    }
    setErrors([])
  }, [entities])

  useEffect(() => {
    if (actionType && byEntity) {
      if (cancelToken) {
        cancelToken.cancel()
      }
      setFetchedAllEntityPages(false)
      setEntityOptions([])
      setEntityPage(0)
      setFilterEntityBy('')
    }
  }, [actionType, byEntity])

  useEffect(() => {
    let resp = []
    attributes.forEach(attribute => {
      let valor = productEntities.find(e => e.value === attribute.entity)
      if (valor !== undefined) resp.push(valor)
    })
    setEntityAttributes(resp)
  }, [productEntities, attributes])

  useEffect(() => {
    //Agrego a Attibutes un nuevo atributo de cero
    if (byEntity && entities !== null) {
      setAttributes(auxlist => {
        let nuevalista = [...auxlist]
        if (nuevalista.find(auxAtri => byEntity.value === auxAtri.entity) === undefined) {
          nuevalista.push({
            entity: byEntity.value,
            ids: [],
          })
        }
        return nuevalista
      })
    }
  }, [byEntity, entities])

  useEffect(() => {
    if (entities !== null && byEntity) {
      setAttributes(auxlist => {
        let nuevalista = auxlist.map(element => {
          if (element.entity !== byEntity.value) return element
          else {
            return {
              entity: byEntity.value,
              ids: entities.map(entity => entity.value),
            }
          }
        })
        return nuevalista
      })
    }
  }, [entities])

  useEffect(() => {
    if (filterEntityBy && byEntity) {
      fetchFilteredOptions(byEntity.endpoint, filterEntityBy, setFilteredEntityOptions)
    }
  }, [filterEntityBy])

  useEffect(() => {
    if (filterProductBy && (actionType.value === 'PRODUCT_BONUS' || actionType.value === 'SET_PRICE')) {
      fetchFilteredOptions('/product', filterProductBy, setFilteredProductOptions)
    }
  }, [filterProductBy])

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      let a = filterProductBy ? filteredProductOptions : productOptions
      if (a != null && a.length < 1) event.preventDefault()
      return
    }
  }

  const fetchFilteredOptions = (endpoint, keyword, setter) => {
    if (filterCancelToken) {
      filterCancelToken.cancel()
    }
    const source = axios.CancelToken.source()
    setFilterCancelToken(source)

    setLoading(true)
    getEntities(`${endpoint}?filter=${keyword}`, {
      cancelToken: source.token,
    })
      .then(({ data: { content } }) => {
        setLoading(false)
        const filteredOptions = content.map(({ id, description }) =>
          endpoint != '/product' ? { value: id, label: description } : { value: id, label: id + ' - ' + description }
        )
        setter(filteredOptions)
      })
      .catch(err => console.log(err))
  }

  const handleMenuOpen = event => {
    context.setMenuIsOpen(true)
    setMenuIsOpen(true)
  }

  const handleMenuClose = () => {
    context.setMenuIsOpen(false)
    setMenuIsOpen(false)
  }

  const fetchEntities = (page, endpoint, pageSetter, fetchedAllSetter, optionsSetter) => {
    const source = axios.CancelToken.source()
    setCancelToken(source)
    setLoading(true)

    getEntities(`${endpoint}?size=200&page=${page}`)
      .then(({ data }) => {
        setLoading(false)
        const newOptions = data.content.map(({ id, description }) =>
          endpoint != '/product' ? { value: id, label: description } : { value: id, label: id + ' - ' + description }
        )
        optionsSetter(prevEntityOptions => {
          return data.first ? newOptions : [...prevEntityOptions, ...newOptions]
        })

        if (!data.last) {
          pageSetter(page + 1)
        } else {
          fetchedAllSetter(true)
        }
        // if(props.form.entity && byEntity.value === props.form.entity){
        //   setEntities(props.form.ids ? entityOptions.filter(option => props.form.ids.includes(option.value)) :[])
        // }
      })
      .catch(err => console.log(err))
  }

  const creditNoteValid = useMemo(() => {
    setCreditNoteError(undefined)
    if (!creditNoteActive) {
      return true
    }

    if (entityForCreditNote?.length !== 0) {
      return true
    } else {
      return false
    }
  }, [creditNoteActive, entityForCreditNote])

  const onSubmit = e => {
    if (menuIsOpen) {
      e.preventDefault()
      return
    }
    e.preventDefault()

    if (!creditNoteValid) {
      return setCreditNoteError('El producto es obligatorio para la nota de crédito')
    }

    if (discount > 9999999999999.99) {
      setErrors([`${t('steps.actions.form.error_max_import')}`])
      return
    }

    if (maxDiscount > 9999999999999.99) {
      setErrors([`${t('steps.actions.form.error_max_import')}`])
      return
    }

    let action = {
      type: actionType.value,
    }

    const entity = byEntity
      ? byEntity.value
      : actionType.value === 'PRODUCT_BONUS' || actionType.value === 'SET_PRICE'
      ? 'PRODUCT_ID'
      : null

    let selectedElements = []
    let attributesAux = []
    switch (actionType.value) {
      case 'PRODUCT_BONUS':
      case 'SET_PRICE':
        selectedElements = product ? [product] : []
        attributesAux = [{ entity, ids: selectedElements.map(ent => ent.value) }]
        break
      case 'DISCOUNT':
        if (discountAllUnits === 2) {
          action.lower_unit_price = true
          action.quantity = 0
        } else {
          action.lower_unit_price = false
          action.quantity = discountAllUnits ? 0 : discountUnits
        }
        selectedElements = byEntity ? entities : []
        attributesAux = attributes ? attributeHasId() : []
        break
      default:
        break
    }
    let arrayInvalid = validIdsURLElement(selectedElements)
    if (arrayInvalid.length > 0) {
      setErrors([`${t('steps.actions.form.error_ids')} ${arrayInvalid.map(id => '"' + id.label + '"').join(', ')}`])
      return
    }
    if (props.form?.hasOwnProperty('id') && props.isEditing) {
      action['id'] = props.form.id
    }

    if (selectedElements.length) {
      action = { ...action, ids: selectedElements.map(ent => ent.value) }
    }
    if (attributesAux.length > 0) action = { ...action, attributes: attributesAux }
    if (actionType.value !== 'PRODUCT_BONUS') {
      action = {
        ...action,
        percentage: byPercentage,
        discount,
      }
    }
    if (entity) {
      action = { ...action, entity }
    }
    let payments = []
    let paymentsNoTar = Object.keys(allPaymentMethodsSelected).map((key, idx) => {
      const current = {
        type_entity_id: key,
        entity_id: allPaymentMethodsSelected[key].map(e => e.value),
      }
      return current
    })
    let paymentsTar = Object.keys(allCreditCard).map((key, idx) => {
      const current = {
        type_entity_id: 'CREDIT_CARD',
        entity_id: [allCreditCard[key].value],
        fees: allCreditCard[key].fees,
      }
      return current
    })
    payments = [...paymentsNoTar, ...paymentsTar]

    if (entityForCreditNote.length !== 0 && entityForCreditNote[0]?.value !== '') {
      if (actionType.value === 'DISCOUNT' || actionType.value === 'GENERAL_DISCOUNT') {
        action = {
          ...action,
          action_line_entity: [
            {
              entity_id: entityForCreditNote[0].value,
              entity_type_id: 'PRODUCT_ID',
            },
          ],
          generate_credit_note: creditNoteActive,
        }
      }
    }

    if (actionType.value !== 'PRODUCT_BONUS') action = { ...action, payments }

    if (action.hasOwnProperty('ids')) delete action.ids

    if (action.hasOwnProperty('entity')) delete action.entity

    if (actionType.value === 'DISCOUNT' || actionType.value === 'GENERAL_DISCOUNT')
      action = { ...action, max_discount: maxDiscount }

    if ((actionType.value === 'DISCOUNT' || actionType.value === 'SET_PRICE') && isRepeatAction(action))
      setErrors([t('steps.actions.form.error_repeated')])
    else {
      if (props?.isEditing === false) action = { ...action, auxId: randomUUID() }
      else {
        if (props.form?.auxId) action = { ...action, auxId: props.form?.auxId }
      }
      props.addSetting(action)
    }
  }

  const onEntitySearch = (keyword, action, filterSetter) => {
    if (action === 'input-change') {
      filterSetter(keyword)
    }
  }

  const onEntitySelectClose = () => {
    setLoading(false)
    if (cancelToken) {
      cancelToken.cancel()
    }
  }

  const fetchNextPage = (fetchedAllPages, fetchedAllSetter, pageSetter, filterBy, page, endpoint, optionsSetter) => {
    if (!fetchedAllPages && !loading && !filterBy) {
      fetchEntities(page, endpoint, pageSetter, fetchedAllSetter, optionsSetter)
    }
  }

  const onEntitySelectOpen = (
    fetchedAllPages,
    fetchedAllSetter,
    pageSetter,
    filterBy,
    page,
    endpoint,
    optionsSetter
  ) => {
    fetchNextPage(fetchedAllPages, fetchedAllSetter, pageSetter, filterBy, page, endpoint, optionsSetter)
  }

  const onEntityScrollBottom = (
    fetchedAllPages,
    fetchedAllSetter,
    pageSetter,
    filterBy,
    page,
    endpoint,
    optionsSetter
  ) => {
    fetchNextPage(fetchedAllPages, fetchedAllSetter, pageSetter, filterBy, page, endpoint, optionsSetter)
  }

  const deleteAtribute = () => {
    setAttributesPersist([])
    setAttributes([])
  }

  const attributeHasId = () => {
    return attributes.filter(element => element.ids.length > 0)
  }

  useEffect(() => {
    if (actionType) {
      let result = actionTypes.findIndex(item => item.value === actionType.value)
      setActionTypeValue({ label: actionTypes[result]?.name, value: result })
    }
  }, [actionType])

  return (
    <form onSubmit={onSubmit} className="actions-form" id="add-promo-form">
      <h1>{t('steps.actions.title')}</h1>
      <FormGroup>
        <Label>Acción</Label>
        <Select
          className="form-control p-0"
          classNamePrefix="react-select"
          value={actionTypeValue}
          isSearchable={false}
          placeholder={t('steps.actions.form.select')}
          required
          onChange={e => {
            setProduct('')
            setEntities([])
            setByEntity(null)
            setDiscount(0)
            setAttributes([])
            setByPercentage(false)
            setActionType(actionTypes[e.value])

            if (e.value === '1')
              //if Descuento
              setByEntityIndex('')
          }}
          options={actionTypes.map((t, index) => {
            return { label: t.name, value: index }
          })}
          styles={{
            container: baseStyles => ({
              ...baseStyles,
            }),
            control: baseStyles => ({
              ...baseStyles,
              cursor: 'pointer',
            }),
            option: baseStyles => ({
              ...baseStyles,
              cursor: 'pointer',
              fontWeight: 'bolder',
            }),
          }}
        />
      </FormGroup>
      {actionType ? (
        actionType.value === 'PRODUCT_BONUS' || actionType.value === 'SET_PRICE' ? (
          <>
            <Label>Artículo</Label>
            <Select
              className="form-control p-0"
              classNamePrefix="react-select"
              placeholder={`${t('steps.conditions.form.select')}`}
              noOptionsMessage={() => t('steps.actions.form.no_results')}
              value={product || ''}
              onChange={v => setProduct(v)}
              required
              onKeyDown={handleKeyDown}
              isLoading={loading}
              onMenuClose={() => {
                handleMenuClose()
                onEntitySelectClose()
                setFilterProductBy('')
              }}
              onMenuOpen={() => {
                handleMenuOpen()
                onEntitySelectOpen(
                  fetchedAllProductPages,
                  setFetchedAllProductPages,
                  setProductPage,
                  filterProductBy,
                  productPage,
                  '/product',
                  setProductOptions
                )
              }}
              onMenuScrollToBottom={() =>
                onEntityScrollBottom(
                  fetchedAllProductPages,
                  setFetchedAllProductPages,
                  setProductPage,
                  filterProductBy,
                  productPage,
                  '/product',
                  setProductOptions
                )
              }
              onInputChange={(keyword, { action }) => onEntitySearch(keyword, action, setFilterProductBy)}
              options={listarPrecioFijoAccionesFiltrados}
              styles={{
                container: baseStyles => ({
                  ...baseStyles,
                }),
                control: baseStyles => ({
                  ...baseStyles,
                  cursor: 'pointer',
                }),
                option: baseStyles => ({
                  ...baseStyles,
                  cursor: 'pointer',
                  fontWeight: 'bolder',
                }),
              }}
            />
            {actionType.value === 'SET_PRICE' && (
              <FormGroup>
                <label for={'final-input-price'}>Precio Final</label>
                <NumberInput
                  min={0.1}
                  max={byPercentage ? 99.99 : null}
                  increment={1}
                  precision={2}
                  onChange={setDiscount}
                  required
                  type="number"
                  value={discount}
                  id={'final-input-price'}
                  placeholder="Precio final"
                />
              </FormGroup>
            )}
          </>
        ) : (
          <>
            <Row className="mt-2 mb-0 pl-3 flex-wrap justify-content-start">
              <div className="form-check-radio mr-4">
                <Label className="form-check-label" id={'when-radio-label'}>
                  <Input
                    type="radio"
                    name="by_percentage"
                    onChange={() => {
                      setByPercentage(false)
                      setDiscount(0)
                    }}
                    checked={!byPercentage}
                  />
                  {t('steps.actions.form.by_value')}
                  <span className="form-check-sign" />
                </Label>
              </div>
              <div className="form-check-radio">
                <Label className="form-check-label" id={'when-radio-label'}>
                  <Input
                    type="radio"
                    name="by_percentage"
                    onChange={() => {
                      setByPercentage(true)
                      setDiscount(0)
                    }}
                    checked={byPercentage}
                  />
                  {t('steps.actions.form.by_percentage')}
                  <span className="form-check-sign" />
                </Label>
              </div>
            </Row>
            <FormGroup>
              <NumberInput
                min={0.1}
                max={byPercentage ? 99.99 : null}
                increment={1}
                precision={2}
                value={discount}
                onChange={setDiscount}
                required
                type="number"
              />
            </FormGroup>

            {actionType.value === 'DISCOUNT' ? (
              <>
                <PaymentMethodWrapper
                  allPaymentMethodsSelected={allPaymentMethodsSelected}
                  setAllPaymentMethodsSelected={setAllPaymentMethodsSelected}
                  allCreditCard={allCreditCard}
                  setAllCreditCard={setAllCreditCard}
                  IS_ARG={IS_ARG}
                  FORM={props.form}
                  handleMenuClose={handleMenuClose}
                  handleMenuOpen={handleMenuOpen}
                />
                <AttributeWrapper
                  productEntities={productEntities}
                  setByEntity={setByEntity}
                  setByEntityIndex={setByEntityIndex}
                  setLoading={setLoading}
                  setEntities={setEntities}
                  attributes={attributes}
                  setOpenSelect={setOpenSelect}
                  isLoading={loading}
                  attributeSelectorOpen={attributeSelectorOpen}
                  setAttributeSelectorOpen={setAttributeSelectorOpen}
                  attributeHasId={attributeHasId}
                  deleteAtribute={deleteAtribute}
                  setAttribute={setAttributes}
                  setAttributesPersist={setAttributesPersist}
                  attributesPersist={attributesPersist}
                  entityValid={entityValid}
                />
              </>
            ) : null}
          </>
        )
      ) : null}
      {actionType != null && actionType?.value === 'DISCOUNT' ? (
        <>
          <AttributeSelector
            key={byEntity ? byEntity.value + openSelect : 'primero'}
            entityOptions={entityOptions}
            loading={loading}
            setLoading={setLoading}
            entities={entities}
            setEntities={setEntities}
            openSelect={openSelect}
            setOpenSelect={setOpenSelect}
            onEntitySelectOpen={onEntitySelectOpen}
            fetchedAllEntityPages={fetchedAllEntityPages}
            setFetchedAllEntityPages={setFetchedAllEntityPages}
            setEntityPage={setEntityPage}
            filterEntityBy={filterEntityBy}
            entityPage={entityPage}
            byEntity={byEntity}
            setEntityOptions={setEntityOptions}
            setFilterEntityBy={setFilterEntityBy}
            filteredEntityOptions={filteredEntityOptions}
            AttributeCurrent={attributes.find(element => byEntity != null && element.entity === byEntity.value)}
            setAttributeSelectorOpen={setAttributeSelectorOpen}
            attributeSelectorOpen={attributeSelectorOpen}
          />
          <div className="d-flex">
            <FormGroup tag="fieldset" className="mr-auto" id={'actionFormInputsDiscount'} style={{ color: '#68688C' }}>
              <h2 className="aplidiscount_application">Aplicación de descuento</h2>
              <div className="form-check-radio mb-2">
                <Label id={'when-radio-label'} className="form-check-label" style={{ color: '#68688C' }}>
                  <Input
                    type="radio"
                    name="discount-radios"
                    id="discount-radios-1"
                    value="apply_all"
                    checked={discountAllUnits === 1}
                    onChange={e => setDiscountAllUnits(1)}
                  />
                  {t('steps.actions.form.apply_to_all_units')}
                  <span className="form-check-sign" />
                </Label>
              </div>
              <div className="form-check-radio d-inline-block">
                <Label id={'when-radio-label'} className="form-check-label" style={{ color: '#68688C' }}>
                  <Input
                    type="radio"
                    name="discount-radios"
                    id="discount-radios-2"
                    value="apply_to"
                    checked={discountAllUnits === 0}
                    onChange={e => setDiscountAllUnits(0)}
                  />
                  {t('steps.actions.form.apply_to')}
                  <span className="form-check-sign" />
                </Label>
              </div>
              <div
                className={`d-inline-block mr-2 ml-2 ${discountAllUnits ? 'date-disabled' : ''}`}
                style={{ width: '6.3em', color: '#68688C' }}>
                <Input
                  type="number"
                  min={1}
                  className={`${discountAllUnits ? 'date-disabled' : ''}`}
                  onChange={e => setDiscountUnits(e.target.value)}
                  value={discountUnits}
                  disabled={discountAllUnits}
                  required
                />
              </div>
              {t('steps.actions.form.units')}
              <div className="form-check-radio mt-2">
                <Label id={'when-radio-label'} className="form-check-label" style={{ color: '#68688C' }}>
                  <Input
                    type="radio"
                    name="discount-radios"
                    id="discount-radios-1"
                    value="apply_all"
                    checked={discountAllUnits === 2}
                    onChange={() => setDiscountAllUnits(2)}
                  />
                  Aplica a las unidades de menor precio
                  <span className="form-check-sign" />
                </Label>
              </div>
            </FormGroup>
          </div>
        </>
      ) : null}
      {actionType != null && (actionType?.value === 'GENERAL_DISCOUNT' || actionType?.value === 'SET_PRICE') && (
        <>
          {actionType?.value === 'SET_PRICE' && <p className="set_priece_text">Válido únicamente con:</p>}
          <PaymentMethodWrapper
            allPaymentMethodsSelected={allPaymentMethodsSelected}
            setAllPaymentMethodsSelected={setAllPaymentMethodsSelected}
            allCreditCard={allCreditCard}
            setAllCreditCard={setAllCreditCard}
            IS_ARG={IS_ARG}
            FORM={props.form}
            handleMenuClose={handleMenuClose}
            handleMenuOpen={handleMenuOpen}
          />
        </>
      )}
      <DiscountSection
        t={t}
        actionType={actionType}
        maxDiscount={maxDiscount}
        setMaxDiscount={setMaxDiscount}
        loading={loading}
      />
      {actionType !== null && actionType?.value === 'DISCOUNT' || actionType?.value === 'GENERAL_DISCOUNT' ? (
        <CreditNoteWrapper
          form={props.form}
          isActive={creditNoteActive}
          setIsActive={setCreditNoteActive}
          entityOptions={entityOptions}
          loading={loading}
          selectedEntity={entityForCreditNote}
          setSelectedEntity={setEntityForCreditNote}
          setLoading={setLoading}
          entities={entities}
          setEntities={setEntities}
          openSelect={creditNoteOpenSelect}
          setOpenSelect={setCreditNoteOpenSelect}
          onEntitySelectOpen={onEntitySelectOpen}
          fetchedAllEntityPages={fetchedAllEntityPages}
          setFetchedAllEntityPages={setFetchedAllEntityPages}
          setEntityPage={setEntityPage}
          filterEntityBy={filterEntityBy}
          entityPage={entityPage}
          byEntity={byEntity}
          setEntityOptions={setEntityOptions}
          setFilterEntityBy={setFilterEntityBy}
          filteredEntityOptions={filteredEntityOptions}
          setAttributeSelectorOpen={setAttributeSelectorOpen}
          attributeSelectorOpen={attributeSelectorOpen}
          setByEntity={setByEntity}
          error={creditNoteError}
        />
      ) : null}
      {errors.map((e, index) => (
        <p style={{ color: 'red', padding: '5px 0 0 0' }} key={index}>
          {e}
        </p>
      ))}
    </form>
  )
}

export default ActionsForm
