import React from 'react'
import {Input} from "reactstrap";

const CLICK_THRESHOLD = 10 // ms

class NumberInput extends React.Component {

  constructor(props) {

    super(props)
    
    this.state = {
      lastKeyPressed: null,
      lastClickTimestamp: Date.now(),
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleChange(event) {

    //^\d(\.\d) * $
    const value = event.target.value

    // if (value === '' && this.state.lastKeyPressed !== 'Backspace') {
    //   return
    // }

    if (Date.now() - this.state.lastClickTimestamp < CLICK_THRESHOLD) {
      this.handleArrowButtonClick(+value)
    } else {
      this.props.onChange(this.round(value))
    }
  }

  handleKeyDown(event) {
    this.setState({ lastKeyPressed: event.key })

    if (Number.isInteger(parseFloat(event.key)) || event.key === "." 
    || event.key === "Backspace" 
    || event.key === "Tab" 
    || event.key === "Enter"
    || event.key === "ArrowLeft"
    || event.key === "ArrowRight") {
      return
    } else {
      event.preventDefault()
    }

    /*
    if (!event.key.includes('Arrow')) {
      return
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()
      this.increment()
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      this.decrement()
    }*/
  }

  handleMouseDown() {
    this.setState({ lastClickTimestamp: Date.now() })
  }

  handleArrowButtonClick(value) {
    value > this.props.value ? this.increment() : this.decrement()
  }

  handleBlur(event) {   
    if (parseFloat(event.target.min) && (parseFloat(event.target.min) > parseFloat(event.target.value)) && parseFloat(event.target.value) > 0){
      this.props.onChange(this.round(event.target.min))
    }

    if (parseFloat(event.target.max) && (parseFloat(event.target.max) < parseFloat(event.target.value)) && parseFloat(event.target.value) > 100) {
      this.props.onChange(this.round(event.target.max))
    }
  }

  handleSubmit(value) {
    console.log('handleSubmit');
  }

  increment() {
    const value = this.props.value === '' ? 0 : this.props.value
    if (!this.props.max ||
      (parseFloat(value) + (parseFloat(this.props.increment))).toFixed(this.props.precision) <= this.props.max){
        this.props.onChange(this.round(parseFloat(value) + (parseFloat(this.props.increment))))
    }
  }

  decrement() {
    const value = this.props.value === '' ? 0 : this.props.value
    if (!this.props.min ||
      (parseFloat(value) - (parseFloat(this.props.increment)))
        .toFixed(this.props.precision) >= this.props.min){
        this.props.onChange(this.round((parseFloat(value) - (parseFloat(this.props.increment))).toFixed(this.props.precision)))
    }
  }

  round(value) {
    const decimal = value.toString().split('.')[1]

    value = value === "" ? value : value

    if (!Number.isInteger(value) && decimal?.length > this.props.precision) {
      value = value.slice(0, -1)
    }

    return value
  }

  render() {
    return (
      <Input
        type={this.props.type ? this.props.type : "text"}
        step={this.props.step || "any"}
        min={this.props.min}
        max={this.props.max}
        disabled={this.props.disabled}
        required={this.props.required}
        value={this.props.placeholder? !isNaN(this.props.value)&&this.props.value!=0 ? this.props.value : this.props.placeholder?this.props.placeholder:"":!this.props.value.isNaN ? this.props.value : ""}
        onMouseDown={ this.handleMouseDown }
        onBlur={ this.handleBlur }
        onKeyDown={ this.handleKeyDown }
        placeholder={this.props.placeholder?this.props.placeholder:""}
        onChange={ this.handleChange }
        className={ this.props.className}
        onSubmit={ this.handleSubmit }
      />
    )
  }
}

export default NumberInput