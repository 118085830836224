import React from 'react'

import { DefaultChild, OpenableChild } from 'components/PromoWizard/Steps/ActionsForm/components/CommonList/components'
import useSelectLocation from '../../Hooks/useSelectLocation'
import style from './whereList.module.css'
import { WhereTree } from '..'

const WhereList = props => {
  const { filteredLocations, tree, origins, setOrigins,raiz,filterKeyword } = props
  const { list, open, goBack, goStep, handleChange, handleClick, setIsOpen, validateTree, setOriginTree } =
    useSelectLocation(props)

  const onChange = item => {
    const clonedObject = JSON.parse(JSON.stringify(origins))
    const response = handleChange(item, clonedObject)
    setOriginTree(raiz[0], response)
    const uniqueOrigins = Array.from(new Set(response))
    setOrigins(uniqueOrigins)
  }

  return (
    <div className={style.container}>
      <WhereTree tree={tree} goBack={goBack} goStep={goStep} />
      {filteredLocations.length && list?.length ? (
        <div className={style.listContainer}>
          <ul className={style.ul}>
            {tree.length === 0 && filteredLocations[0].value === 'EVERYWHERE' ? (
              <DefaultChild
                label={filteredLocations[0].label}
                withArrow={false}
                id={filteredLocations[0].value}
                checked={validateTree(filteredLocations[0])}
                handleChange={() => onChange(filteredLocations[0])}
                withCheckbox
                withoutBorders
              />
            ) : null}
            {list.map((item, idx) => {
              if (!item.children) {
                return (
                  <DefaultChild
                    label={item.label}
                    withArrow={false}
                    onClick={() => handleClick(item)}
                    id={`${item.value}-${idx}`}
                    checked={validateTree(item)}
                    handleChange={() => onChange(item)}
                    key={idx}
                    withCheckbox
                  />
                )
              }

              if (item.children[0].children) {
                return (
                  <DefaultChild
                    label={item.label}
                    onClick={() => handleClick(item)}
                    id={`${item.value}-${idx}`}
                    checked={validateTree(item)}
                    handleChange={() => onChange(item)}
                    key={idx}
                    withCheckbox
                  />
                )
              }

              return (
                <OpenableChild
                  item={item}
                  checked={validateTree(item)}
                  handleChange={() => onChange(item)}
                  onClick={() => setIsOpen(open === idx ? null : idx)}
                  isOpen={idx === open}
                  key={idx}
                  withCheckbox={true}>
                  {item.children.map((child, childIdx) => (
                    <DefaultChild
                      label={child.label}
                      withArrow={child.children ? true : false}
                      id={`${child.value}-${childIdx}`}
                      key={childIdx}
                      checked={validateTree(child)}
                      handleChange={() => onChange(child)}
                      withCheckbox
                      withoutBorders
                    />
                  ))}
                </OpenableChild>
              )
            })}
          </ul>
        </div>
      ) : (
        <div className="mt-4" style={{ fontSize: '1.2em' }}>
          No se encontraron resultados
        </div>
      )}
    </div>
  )
}

export default WhereList
