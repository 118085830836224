export const initialActionForm = {
  actionType: "",
  productOptions: [],
  product: "",
  percentage: false,
  discount: 0,
  byEntity: null,
  attributes:[],
  entityOptions: [],
  entities: [],
  maxDiscount: 0,
  lower_unit_price:false
}

export const initialWhenForm = {
  date_setting: {
    day_month: 0,
    days: [1,2,3,4,5,6,7],
    specific_day: 0
  },
  time_range: {
    all_day: true,
    time_from: null,
    time_to: null
  }
}

export const initialWhereForm = []

export const initialGeneralSettingsForm = {
  name: '',
  paused: false,
  description: '',
  limit: '',
  limit_per_customer: '',
  limit_per_voucher: '',
  not_acumulable: false,
  always_current: false,
  excluded_lists: [],
  date_time_from: 0,
  date_time_to: 0,
  priority: 0,
  not_acumulable_manual_discount: false
}

const initialForm = {
  header:{
    ...initialGeneralSettingsForm,
  },
  body:{
    activations: [],
    rules: [],
    actions: [],
    origins: []
  }
}
export default initialForm