// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".whereList_container__2To-K {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.whereList_listContainer__14aTX {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.whereList_ul__3_O7m {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 105%;\n  max-height: 100%;\n  list-style: none;\n  padding: 0;\n  padding-right: 5%;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "whereList_container__2To-K",
	"listContainer": "whereList_listContainer__14aTX",
	"ul": "whereList_ul__3_O7m"
};
module.exports = exports;
