// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".whereTree_container__2wrCL {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n\n.whereTree_container__2wrCL > svg {\n  cursor: pointer;\n}\n\n.whereTree_containerSpans__15eVI {\n  display: flex;\n  grid-gap: 0.2rem;\n  gap: 0.2rem;\n}\n\n.whereTree_span__2wEiQ {\n  cursor: pointer;\n  color: var(--Narnanja-Principal, #ff7d00);\n  font: normal 500 14px/18px Inter;\n  letter-spacing: 0.28px;\n  text-transform: capitalize;\n  max-width: 15ch;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  -webkit-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "whereTree_container__2wrCL",
	"containerSpans": "whereTree_containerSpans__15eVI",
	"span": "whereTree_span__2wEiQ"
};
module.exports = exports;
