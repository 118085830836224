import React from 'react'
import { Redirect } from 'react-router-dom'
import {useCookies} from "react-cookie";

const ProtectedRoute = (props) => {
  const [cookies, ] = useCookies(['auth_token'])
  const Component = props.component;

  const isAuthenticated = () => {
    // TODO: validate that auth token too
    if (cookies.auth_token){
      return true
    }
  };

  return isAuthenticated() ? (
    <Component />
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );

}

export default ProtectedRoute;