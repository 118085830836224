import React from 'react'

import style from './whereTree.module.css'

const WhereTree = ({ tree, goBack, goStep }) => {
  return tree.length !== 0 ? (
    <div className={style.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        onClick={() => goBack()}>
        <path
          d="M5.22297 8.47161L4.80169 8.89289L0.708794 4.8L4.80169 0.707107L5.22297 1.12839L1.91175 4.44683L1.55935 4.8L1.91175 5.15317L5.22297 8.47161Z"
          fill="#68688C"
          stroke="#FF7D00"
        />
      </svg>
      <div className={style.containerSpans}>
        {tree.map((item, idx) => (
          <span className={style.span} key={`${idx}-${item.value}`} onClick={() => goStep(item)}>
            {idx !== 0 ? '/' : ''} {item.label.toLocaleLowerCase()}
          </span>
        ))}
      </div>
    </div>
  ) : null
}

export default WhereTree
