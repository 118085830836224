// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dashboard_dashboard-header__HVDsc {\n  justify-content: space-between;\n  margin-bottom: 1.5em;\n}\n\n.Dashboard_dashboard-header-h1__2S0pI {\n  font-weight: 600;\n  margin-bottom: auto;\n  margin-top: auto;\n  font-family: Inter;\n  font-size: 20px;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.Dashboard_btn__3v4Mu {\n  position: relative;\n  background: var(--primary-color);\n  border-radius: 40px;\n  -webkit-border-radius: 40px;\n  -moz-border-radius: 40px;\n  -ms-border-radius: 40px;\n  -o-border-radius: 40px;\n  padding: 12px, 20px, 12px, 20px;\n  height: 41px;\n  width: 199px;\n  border: none;\n  color: #fff;\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 17px;\n  letter-spacing: 0em;\n  text-align: center;\n  text-transform: uppercase;\n  height: 51px;\n  right: 1.8rem;\n}\n\n.Dashboard_btn__3v4Mu:focus-visible {\n  outline: none;\n}\n\n.Dashboard_Dashboard-content__15HQG {\n  width: 100%;\n  margin: 93px 5% 10px 5%;\n  max-width: 90%;\n}\n", ""]);
// Exports
exports.locals = {
	"dashboard-header": "Dashboard_dashboard-header__HVDsc",
	"dashboard-header-h1": "Dashboard_dashboard-header-h1__2S0pI",
	"btn": "Dashboard_btn__3v4Mu",
	"Dashboard-content": "Dashboard_Dashboard-content__15HQG"
};
module.exports = exports;
