// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".commonInput_container__1bjzV {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 0.44rem;\n  gap: 0.44rem;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n.commonInput_container__1bjzV > span {\n  color: #68688c;\n  font: normal 500 14px/18px Inter;\n}\n\n.commonInput_input__KXmOB {\n  padding-inline: 16px;\n  cursor: pointer;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-radius: 6px;\n  border: 1px solid #d2d2d2;\n  background: #fff;\n  font: normal 500 16px / normal Inter;\n}\n\n.commonInput_input__KXmOB > div {\n  color: var(--Gris-50, #a1a9c7);\n}\n\n.commonInput_tag__n05lm {\n  color: var(--Gris-100, #68688c) !important;\n  padding: 4px 10px;\n  border-radius: 30px;\n  font: normal 500 14px/18px Inter;\n  border: 1px solid var(--Gris-20, #ccd1e4);\n  background: var(--Turquesa-fondo, #e4fdfa);\n  display: flex;\n  grid-gap: 0.2rem;\n  gap: 0.2rem;\n}\n\n.commonInput_tag__n05lm > button {\n  background: 0;\n  outline: 0;\n  border: 0;\n  display: grid;\n  place-items: center;\n  position: relative;\n  bottom: 1px;\n  cursor: pointer;\n  transition: ease 0.2s;\n}\n\n.commonInput_tag__n05lm > button:hover {\n  color: #ef4040;\n}\n\n.commonInput_input__KXmOB > span {\n  padding-block: 13px;\n  color: var(--Narnanja-Principal, #ff7d00);\n}\n@keyframes commonInput_rotate-forever__25aGA {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.commonInput_loadingContainer__3_47r {\n  animation-duration: 0.75s;\n  animation-iteration-count: infinite;\n  animation-name: commonInput_rotate-forever__25aGA;\n  animation-timing-function: linear;\n  height: 25px;\n  width: 25px;\n  border: 3px solid var(--Gris-50, #a1a9c7);\n  border-right-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "commonInput_container__1bjzV",
	"input": "commonInput_input__KXmOB",
	"tag": "commonInput_tag__n05lm",
	"loadingContainer": "commonInput_loadingContainer__3_47r",
	"rotate-forever": "commonInput_rotate-forever__25aGA"
};
module.exports = exports;
