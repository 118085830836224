import React from 'react';

export const CreatePromoContext = React.createContext({
  form: {},
  saveForm: () => {},
  addSetting: () => {},
  deleteSetting: () => {},
  copy: false,
  currentStep: 1,
  setCurrentStep: () => {},
  menuIsOpen:false,
  setMenuIsOpen:()=>{}
})
