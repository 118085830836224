/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "index.css"

import App from "layouts/Admin.js";
import './i18n';
import {Spinner} from "reactstrap";
import {CookiesProvider, withCookies} from "react-cookie";
const AppWithCookies = withCookies(App)

const hist = createBrowserHistory();

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <CookiesProvider>
      <Router history={hist}>
        <Switch>
          <Route path="/" render={(props) => <AppWithCookies {...props} />} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </CookiesProvider>
  </Suspense>,
  document.getElementById("root")
);
