import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import React, { useState } from 'react'

// reactstrap components
import { Label, Input, FormGroup } from 'reactstrap'

import { forgottenPassword } from '../../api/AuthAPI'
import style from './forgottenPassword.module.css'
import antion from './assets/antion.svg'
import emailSvg from './assets/email.svg'

const ForgottenPassword = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [retrieve, setRetrieve] = useState(false)
  const { t } = useTranslation('forgottenPassword')
  const [submitting, setSubmitting] = useState(false)
  const [cookies, _setCookies] = useCookies(['auth_token', 'connect.sid'])

  const asLogued = () => cookies.auth_token

  const onSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    forgottenPassword(email)
      .then(({ data }) => {
        console.log('Recuperè contraseña', { data })
        setSubmitting(false)
        setRetrieve(true)
      })
      .catch(error => {
        setSubmitting(false)
        setError(true)
      })
  }

  return asLogued() ? (
    <Redirect to="/" />
  ) : (
    <div className={style.page}>
      <form onSubmit={onSubmit} className={style.form}>
        <div className={style.header}>
          <div className={style.imgContainer}>
            <img src={antion} alt="antion - ico" />
          </div>
        </div>
        {retrieve ? (
          <div className={`${style.body} ${style.retrieve}`}>
            <h3 className={style.bodyTitle}>{t('message_title')}</h3>
            <div className={style.sentEmailContainer}>
              <img src={emailSvg} alt="email - svg" />
              <p>
                {t('message.one')} <b>{email}</b> {t('message.two')}
              </p>
            </div>
            <button
              color="primary"
              className="btn-style btn-style-orange"
              type="submit"
              form="add-promo-form"
              onClick={onSubmit}>
              {t('retrieve')}
            </button>
          </div>
        ) : (
          <div className={style.body}>
            <h3 className={style.bodyTitle}>{t('title')}</h3>
            
            <FormGroup>
              <Label for={'forgotten-password-email'}>
                {t('email.label')}{' '}
              </Label>
              <Input
                className={error ? style.error : ''}
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={t('email.placeholder')}
                id={'forgotten-password-email'}
                required
                type="text"
              />
              {error && <p style={{ color: 'red' }}>{t('not_found_email')}</p>}
            </FormGroup>
            <button
              color="primary"
              className="btn-style btn-style-orange mr-4"
              type="submit"
              form="add-promo-form"
              disabled={submitting}
              onClick={onSubmit}>
              {t('title')}
            </button>
          </div>
        )}
        <div className={style.footer}>
          <a className={style.login} href="/login">
            {t(retrieve ? 'back_to_top' : 'login')}{' '}
          </a>
        </div>
      </form>
    </div>
  )
}

export default ForgottenPassword
