// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CreditNoteWrapper_container__1Nr39 {\n  display: flex;\n  flex-direction: column;\n  margin: 16px 0;\n}\n\n.CreditNoteWrapper_container__1Nr39.CreditNoteWrapper_active__Y7Q6I > div > .CreditNoteWrapper_text__2O-Zl {\n  color: var(--primary-color);\n}\n\n.CreditNoteWrapper_label__2PJHZ {\n  font-size: 14px;\n  margin-bottom: 8px;\n  font: normal 500 12px/117% Inter;\n  color: #68688c;\n}\n\n.CreditNoteWrapper_selectBox__2RW9o {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px;\n  background: #f7f7ff;\n  border: 1px solid #d2d2d2;\n  border-radius: 6px;\n  cursor: pointer;\n  transition: background 0.3s ease;\n}\n\n.CreditNoteWrapper_container__1Nr39.CreditNoteWrapper_active__Y7Q6I > .CreditNoteWrapper_selectBox__2RW9o {\n  background: #ffffff;\n}\n\n.CreditNoteWrapper_selectBox__2RW9o:hover {\n  border-color: #ccc;\n}\n\n.CreditNoteWrapper_text__2O-Zl {\n  width: 80%;\n  height: 100%;\n  text-align: start;\n  font-size: 16px;\n  color: #a6a5b9;\n  transition: color ease 0.3s;\n}\n\n.CreditNoteWrapper_container__1Nr39.CreditNoteWrapper_active__Y7Q6I .CreditNoteWrapper_text__2O-Zl.CreditNoteWrapper_complete__2UH50 {\n  color: #303048 !important;\n}\n\n.CreditNoteWrapper_err__1VyAY {\n  padding-top: 0.39rem;\n  font: normal 500 12px/14px Inter;\n  color: var(--danger);\n}\n", ""]);
// Exports
exports.locals = {
	"container": "CreditNoteWrapper_container__1Nr39",
	"active": "CreditNoteWrapper_active__Y7Q6I",
	"text": "CreditNoteWrapper_text__2O-Zl",
	"label": "CreditNoteWrapper_label__2PJHZ",
	"selectBox": "CreditNoteWrapper_selectBox__2RW9o",
	"complete": "CreditNoteWrapper_complete__2UH50",
	"err": "CreditNoteWrapper_err__1VyAY"
};
module.exports = exports;
