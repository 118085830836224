import React from 'react'

import style from './loading.module.css'

const Loading = ({ scroll }) => {
  return (
    <div className={style.loadingContainer}>
      <div className={style.loading}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>
        Cargando...
      </span>
    </div>
  )
}

export default Loading
