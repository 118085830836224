import API from "./API";

export const getPromos = () => {
  return API.get('/promotion')
}

export const createPromo = (promo) => {

  promo.body.activations.forEach(element => {
  if (element.id) {
      delete element.id
    }
  });
  if (promo.header.id) {
    delete promo.header.id
  }
  return API.post('/promotion', promo)
}

const updateDeleted = (currentPromo, originalPromo, field) => {
  originalPromo.body[field.name].forEach(e => {
    if (e.hasOwnProperty('id') && !currentPromo.body[field.name].find(c => c.id === e.id)) {
      return API.delete(`${field.endpoint}/${e.id}`)
    }
  })
}

export const updatePromo = (currentPromo, originalPromo) => {
  let promises = []
  promises.push(API.put(`/promotion`, currentPromo.header).then(res => console.log(res)).catch(err => console.log(err)))
  promises.push(API.post(`/origin/${originalPromo.header.id}?origins=${currentPromo.body.origins.join(',')}`).catch(err => console.log(err)))


  const fields = [
    { name: 'activations', endpoint: '/activation' },
    { name: 'actions', endpoint: '/action' },
    { name: 'rules', endpoint: '/rule' }
  ]

  // Iterate over all settings and update/create them accordingly
  fields.forEach(f => {
    const delPromise = updateDeleted(currentPromo, originalPromo, f)
    if (delPromise) { promises.push(delPromise) }

    currentPromo.body[f.name].forEach(e => {
      const promise = e.hasOwnProperty('id') ? API.put(f.endpoint, e) : API.post(`${f.endpoint}/${currentPromo.header.id}`, e)
      promises.push(promise)
    })
  })


  return Promise.all(promises).then(res => console.log(res))
}

export const getPromo = (id) => {
  return API.get(`/promotion/${id}`)
}

export const pausePromo = (id) => {
  return API.post(`/promotion/${id}/pause`)
}
export const resumePromo = (id) => {
  return API.post(`/promotion/${id}/resume`)
}

export const deletePromo = (id) => {
  return API.delete(`/promotion/${id}`)
}

export const updatePriority = (id, priority) => {
  return API.post(`/promotion/${id}/priority/${priority}`)
}

