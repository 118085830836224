import i18n from "../../../../i18n";


export const productEntities = [
  {
    name: 'createPromo:steps.conditions.form.code',
    endpoint: '/product',
    value: 'PRODUCT_ID'
  },
  {
    name: 'createPromo:steps.conditions.form.category',
    endpoint: '/product-category',
    value: 'PRODUCT_CATEGORY'
  },
  {
    name: 'createPromo:steps.conditions.form.family',
    endpoint: '/product-family',
    value: 'FAMILY'
  },
  {
    name: 'createPromo:steps.conditions.form.group',
    endpoint: '/product-group',
    value: 'GROUP'
  },
  {
    name: 'createPromo:steps.conditions.form.brand',
    endpoint: '/product-brand',
    value: 'BRAND'
  },
  {
    name: 'createPromo:steps.conditions.form.equivalence',
    endpoint: '/product-equivalence',
    value: 'EQUIVALENCY'
  },
  {
    name: 'createPromo:steps.conditions.form.supplier',
    endpoint: '/product-supplier',
    value: 'PROVIDER'
  },
  {
    name: 'createPromo:steps.conditions.form.maker',
    endpoint: '/product-maker',
    value: 'MANUFACTURE'
  },
  {
    name: 'createPromo:steps.conditions.form.type',
    endpoint: '/product-type',
    value: 'PRODUCT_TYPE'
  },
  {
    name: 'createPromo:steps.conditions.form.size',
    endpoint: '/product-size',
    value: 'SIZE'
  },
  {
    name: 'createPromo:steps.conditions.form.color',
    endpoint: '/product-color',
    value: 'COLOUR'
  },
]
export const customerEntities = [
  {
    name: 'createPromo:steps.conditions.form.code',
    endpoint: '/customer',
    value: 'CUSTOMER_ID'
  },
  {
    name: 'createPromo:steps.conditions.form.type',
    endpoint: '/customer-type',
    value: 'CUSTOMER_TYPE'
  },
  {
    name: 'createPromo:steps.conditions.form.department',
    endpoint: '/customer-department',
    value: 'DEPARTMENT'
  },
  {
    name: 'createPromo:steps.conditions.form.city',
    endpoint: '/customer-city',
    value: 'CITY'
  },
  {
    name: 'createPromo:steps.conditions.form.state',
    endpoint: '/customer-state',
    value: 'STATE'
  },
  {
    name: 'createPromo:steps.conditions.form.region',
    endpoint: '/customer-area',
    value: 'REGION'
  },
  {
    name: 'createPromo:steps.conditions.form.category_vat',
    endpoint: '/customer-category-vat',
    value: 'VAT_CATEGORY'
  },
  {
    name: 'createPromo:steps.conditions.form.category',
    endpoint: '/customer-category',
    value: 'CUSTOMER_CATEGORY'
  },
  {
    name: 'createPromo:steps.conditions.form.ramification',
    endpoint: '/customer-ramification',
    value: 'BUSINESS_TYPE'
  },
]
export const voucherEntities = [
  {
    name: 'createPromo:steps.conditions.form.payment_method',
    value: 'PAYMENT_METHODS'
  },
  {
    name: 'createPromo:steps.conditions.form.voucher_total',
    value: 'AMOUNT'
  },
]
export const paymentMethodTypes = [
  {
    name: 'createPromo:steps.conditions.form.cash',
    endpoint: '/currency',
    value: 'CASH'
  },
  {
    name: 'createPromo:steps.conditions.form.credit_card',
    endpoint: '/credit-card',
    value: 'CREDIT_CARD'
  },
  {
    name: 'createPromo:steps.conditions.form.interdeposit',
    endpoint: '/bank',
    value: 'INTERDEPOSIT'
  }
]
export const paymentMethodTypesArgExclusive = [
  {
    name: 'createPromo:steps.conditions.form.cash',
    endpoint: '/currency',
    value: 'CASH'
  },
  {
    name: 'createPromo:steps.conditions.form.credit_card',
    endpoint: '/credit-card',
    value: 'CREDIT_CARD'
  },
  {
    name: 'createPromo:steps.conditions.form.interdeposit',
    endpoint: '/bank',
    value: 'INTERDEPOSIT'
  },
  {
    name: 'createPromo:steps.conditions.form.mercado_pago',
    endpoint: '/mercado-pago',
    value: 'PAGO_QR'
  }
]
export const ruleTypes = [
  {name: i18n.t('createPromo:steps.conditions.form.customer'), entities: customerEntities},
  {name: i18n.t('createPromo:steps.conditions.form.product'), entities: productEntities},
  {name: i18n.t('createPromo:steps.conditions.form.voucher'), entities: voucherEntities}
]
export const calculateByTypes = {
  quantity: i18n.t('createPromo:steps.conditions.form.quantity'),
  price: i18n.t('createPromo:steps.conditions.form.price')
}

export const calculationTypes = {
  quantity: [
    {name: i18n.t('createPromo:steps.conditions.form.equal'), value: 'EQUAL_QUANTITY'},
    {name: i18n.t('createPromo:steps.conditions.form.greater_equal_than'), value: 'GREATER_EQUAL_QUANTITY'},
  ],
  price: [
    {name: i18n.t('createPromo:steps.conditions.form.equal'), value: 'EQUAL_AMOUNT'},
    {name: i18n.t('createPromo:steps.conditions.form.greater_equal_than'), value: 'GREATER_EQUAL_AMOUNT'},
    {name: i18n.t('createPromo:steps.conditions.form.in_range'), value: 'RANGE_AMOUNT'},
  ]
}