import React, { useEffect, useState } from 'react'
import { FormGroup, Input, Spinner } from 'reactstrap'
import './WhereForm.css'
import { useTranslation } from 'react-i18next'
import { getEntities } from '../../../../api/EntitiesAPI'
import deepRenameKeys from 'deep-rename-keys'
import { WhereList } from './Components'

const WhereForm = props => {
  const { t } = useTranslation('createPromo')
  const [origins, setOrigins] = useState(props.form || [])
  const [viewSearch, setViewSearch] = useState(true)
  const [locations, setLocations] = useState(null)
  const [filteredLocations, setFilteredLocations] = useState(null)
  const [filterKeyword, setFilterKeyword] = useState('')
  const [errors, setErrors] = useState([])
  const [tree, setTree] = useState([])
  const [raiz, setRaiz] = useState({})

  useEffect(() => {
    const modals = document.querySelectorAll('.modal-dialog')

    modals.forEach(modal => {
      if (modal.querySelector('.where-form')) {
        modal.classList.add('has-where-form')
      }
    })
  }, [])

  useEffect(() => {
    setErrors([])
  }, [origins])

  useEffect(() => {
    setTree([])
  }, [filterKeyword])
  

  useEffect(() => {
    getEntities('/origin')
      .then(({ data }) => {
        const locations = deepRenameKeys(data, key => {
          switch (key) {
            case 'id':
              return 'value'
            case 'description':
              return 'label'
            case 'childs':
              return 'children'
            default:
              return key
          }
        })
        if (origins.length) {
          const parentLocations = origins.map(o => searchTree(locations, o, matchId))
          let allLocations = []
          parentLocations.forEach(o => getAllChildren(o, allLocations))
          setOrigins(allLocations)
        }
        setLocations([locations])
        setRaiz([locations])
        setFilteredLocations([locations])
      })
      .catch(err => {
        console.error(err)
        setErrors(err)
      })
  }, [])

  const matchTitle = (element, title) => {
    return element.label.toLowerCase().includes(title.toLowerCase())
  }

  const matchId = (element, id) => {
    return element.value === id
  }

  const getAllChildren = (parent, allChildren) => {
    allChildren.push(parent.value)
    if (parent.hasOwnProperty('children')) {
      parent.children.forEach(c => getAllChildren(c, allChildren))
    }
  }

  const searchTree = (element, matchingParam, comparison, results = null) => {
    if (comparison(element, matchingParam)) {
      if (results === null) {
        return element
      }
      results.push(element)
    } else if (element.children != null) {
      let i
      let result = null
      for (i = 0; result == null && i < element.children.length; i++) {
        result = searchTree(element.children[i], matchingParam, comparison, results)
      }
      return result
    }
    return null
  }

  const dfs = (node, values, parents) => {
    if (values.includes(node.value)) {
      parents.push(node.value)
    } else if (node.hasOwnProperty('children')) {
      node.children.forEach(c => dfs(c, values, parents))
    }
  }

  const filterLocations = keyword => {
    let results = []
    searchTree(locations[0], keyword, matchTitle, results)
    setFilteredLocations(results)
  }

  const onSubmit = e => {
    e.preventDefault()
    if (origins?.length > 0) {
      let parents = []
      dfs(locations[0], origins, parents)
      props.addSetting(parents)
    } else {
      setErrors([t('steps.where.form.error_no_selection')])
      return
    }
  }

/*   useEffect(() => {
    if (tree.length !== 0) {
      setViewSearch(false)
    } else {
      setViewSearch(true)
    }
  }, [tree]) */

  return (
    <form onSubmit={onSubmit} className="where-form" id="add-promo-form">
      <div className="container-head-where">
        <h1>{t('steps.where.title')}</h1>
{/*         {!viewSearch ? (
          <button type="button" onClick={() => setViewSearch(!viewSearch)}>
            <span />
          </button>
        ) : null} */}
      </div>
      {filteredLocations ? (
        <FormGroup id={'fieldsetWhere'} tag="fieldset">
          {viewSearch ? (
            <FormGroup className="where-form-search">
              <Input
                type="text"
                id="input-search"
                value={filterKeyword}
                onChange={e => {
                  setFilterKeyword(e.target.value)
                  filterLocations(e.target.value)
                }}
                placeholder={t('steps.where.form.search')}
              />
            </FormGroup>
          ) : null}
          <WhereList
            filterKeyword={filterKeyword}
            filteredLocations={filteredLocations}
            tree={tree}
            setTree={setTree}
            origins={origins}
            setOrigins={setOrigins}
            matchId={matchId}
            searchTree={searchTree}
            dfs={dfs}
            raiz={raiz}
          />
        </FormGroup>
      ) : (
        <div className="mt-5 mb-5 text-center">
          <Spinner />
        </div>
      )}
      {errors.map((e, index) => (
        <p style={{ color: 'red' }} key={index}>
          {e}
        </p>
      ))}
    </form>
  )
}

export default WhereForm
