import { components } from 'react-select'
import React from 'react'
import './option.css'

const Option = ({ checkbox = true, ...props }) => {
  return (
    <div className={'checkbox-wrapper3'}>
      <components.Option {...props}>
        <label htmlFor={props.data.value}>
          <input type="checkbox" checked={props.isSelected} id={props.data.value} onChange={()=>{}}/>
          <span className="cbx">
            <svg width="12px" height="11px" viewBox="0 0 12 11">
              <polyline points="1 6.29411765 4.5 10 11 1"></polyline>
            </svg>
          </span>
          <span>{props.data.label}</span>
        </label>
      </components.Option>
    </div>
  )
}

export default Option
