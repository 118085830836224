import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getEntities, getEntityNames } from '../../../../../../api/EntitiesAPI'
import { productEntities } from '../../../ConditionsForm/variables'
import './CardAttribute.css'

export const CardAttribute = ({
  element,
  entity,
  deleteAtribute,
  setByEntity,
  setByEntityIndex,
  setLoading,
  setEntities,
  attributes,
  setOpenSelect,
}) => {
  const { t } = useTranslation('createPromo')
  const [idsName, setIdsName] = useState([])
  const [text, setText] = useState('')
  const liRef = useRef(null)
  const [isTextOverflowing, setIsTextOverflowing] = useState(false)

  useEffect(() => {
    if (entity && entity.endpoint) {
      getEntityNames(entity.endpoint, element.ids).then(({ data }) => {
        setIdsName(data)
      })
    }
  }, [element.ids, entity])

  useEffect(() => {
    if (idsName.length > 0) {
      setText(recorrerId())
    }
  }, [idsName])

  useEffect(() => {
    const element = liRef.current
    if (element) {
      const isOverflowing = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
      setIsTextOverflowing(isOverflowing)
    }
  }, [text])

  function recorrerId() {
    let auxString = []
    if (idsName && idsName.length > 0) {
      for (let i = 0; i < idsName.length; i++) {
        let auxValue =
          entity?.endpoint != '/product' ? idsName[i].description : idsName[i].id + ' - ' + idsName[i].description
        if (i < element.ids.length - 1) auxString.push(auxValue + ', ')
        if (i === element.ids.length - 1) auxString.push(auxValue + ' ')
      }
    }
    return auxString
  }

  return (
    <ul
      onClick={() => {
        setByEntity(entity)
        setByEntityIndex(productEntities.findIndex(prodEntity => prodEntity.value === entity.value))
        let atributeActual = attributes.find(element => element.entity === entity.value)
        if (atributeActual !== undefined) {
          setLoading(true)
          getEntities(`${entity.endpoint}/ids?ids=${atributeActual.ids.join(',')}`)
            .then(({ data }) => {
              setLoading(false)
              setEntities(
                data.map(({ id, description }) =>
                  entity.endpoint != '/product'
                    ? { value: id, label: description }
                    : { value: id, label: id + ' - ' + description }
                )
              )
              setOpenSelect(true)
            })
            .catch(err => console.log(err))
        }
      }}
      className="CardAtribute-container">
      {entity && entity.name && (
        <div className="CardAtribute-entity">
          <p className="CardAtribute-titule">{t(entity.name) + ': '}</p>
        </div>
      )}
      <div className="CardAtributeMp-list">
        {isTextOverflowing && <span className={'tooltiptext-CardAtributeMP'}>{text}</span>}
        <div className="CardAtributeMp-list-div">
          <li
            id="CardAtributeMp-list-div"
            ref={liRef}
            className={`CardAtributeMp-list-div ${isTextOverflowing ? 'text-overflow' : ''}`}>
            {text}{' '}
          </li>
        </div>
      </div>
      <div
        className="CardAtribute-list-element"
        onClick={e => {
          e.stopPropagation()
          deleteAtribute(element.entity)
        }}>
        x
      </div>
    </ul>
  )
}
export default CardAttribute
