import Dashboard from "views/Dashboard/Dashboard.js";
import CreatePromo from "./views/CreatePromo/CreatePromo";
import Login from "./views/Login/Login";
import Signin from "./views/Signin/Signin";
import ForgottenPassword from "./views/ForgottenPassword/ForgottenPassword";
import ResetPassword from "./views/ResetPassword/ResetPassword";

const routes = [
  {
    path: "/crear-promocion",
    name: "Crear promocion",
    icon: "nc-icon nc-caps-small",
    component: CreatePromo,
    isProtected: true
  },
  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-caps-small",
    component: Login,
    isProtected: false
  },
  {
    path: "/signin",
    name: "Signin",
    icon: "nc-icon nc-caps-small",
    component: Signin,
    isProtected: false
  },
  {
    path: "/recuperar",
    name: "Recuperar contraseña",
    icon: "nc-icon nc-caps-small",
    component: ForgottenPassword,
    isProtected: false
  },
  {
    path: "/resetear",
    name: "Resetear contraseña",
    icon: "nc-icon nc-caps-small",
    component: ResetPassword,
    isProtected: false
  },
  {
    path: "/",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    isProtected: true
  },
];
export default routes;
