// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CardSelect_container__3L64n {\n  display: flex;\n  align-items: center;\n  grid-gap: 5px;\n  gap: 5px;\n  padding: 4px 14px 4px 10px;\n  flex-shrink: 0;\n  border-radius: 30px;\n  border: 1px solid var(--Gris-20, #ccd1e4);\n  background: var(--Turquesa-fondo, #e4fdfa);\n}\n\n.CardSelect_titulo__1-J9k {\n  color: var(--Gris-100, #68688c);\n  font: normal 500 14px/18px Inter;\n  text-transform: capitalize;\n  letter-spacing: 0.28px;\n}\n\n.CardSelect_delete__2h_xB {\n  cursor: pointer;\n  background: var(--Turquesa-fondo, #e4fdfa);\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n  -ms-border-radius: 50%;\n  -o-border-radius: 50%;\n  font-size: 13px;\n  width: 16px;\n  height: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #707070;\n}\n\n.CardSelect_RadioCheckAttributeContainer__1TCCp {\n  margin: 0;\n  padding: 0;\n  color: #2f2e3c;\n  font-weight: 600;\n  font-size: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "CardSelect_container__3L64n",
	"titulo": "CardSelect_titulo__1-J9k",
	"delete": "CardSelect_delete__2h_xB",
	"RadioCheckAttributeContainer": "CardSelect_RadioCheckAttributeContainer__1TCCp"
};
module.exports = exports;
