import i18n from '../../../../i18n'

export const actionTypes = [
  {
    name: i18n.t('createPromo:steps.actions.form.product_bonus'),
    value: 'PRODUCT_BONUS',
  },
  {
    name: i18n.t('createPromo:steps.actions.form.discount'),
    value: 'DISCOUNT',
  },
  {
    name: i18n.t('createPromo:steps.actions.form.general_discount'),
    value: 'GENERAL_DISCOUNT',
  },
  {
    name: i18n.t('createPromo:steps.actions.form.set_price'),
    value: 'SET_PRICE',
  },
]

export const paymentMethodTypesArgExclusive = [
  {
    name: 'createPromo:steps.conditions.form.cash',
    endpoint: '/currency',
    value: 'CASH',
  },
  {
    name: 'createPromo:steps.conditions.form.credit_card',
    endpoint: '/credit-card',
    value: 'CREDIT_CARD',
  },
  {
    name: 'createPromo:steps.conditions.form.interdeposit',
    endpoint: '/bank',
    value: 'INTERDEPOSIT',
  },
  {
    name: 'createPromo:steps.conditions.form.mercado_pago',
    endpoint: '/mercado-pago',
    value: 'PAGO_QR',
  },
]

export const paymentMethodTypes = [
  {
    name: 'createPromo:steps.conditions.form.cash',
    endpoint: '/currency',
    value: 'CASH',
  },
  {
    name: 'createPromo:steps.conditions.form.credit_card',
    endpoint: '/credit-card',
    value: 'CREDIT_CARD',
  },
  {
    name: 'createPromo:steps.conditions.form.interdeposit',
    endpoint: '/bank',
    value: 'INTERDEPOSIT',
  },
]
