/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from '../components/ProtectedRoute'
import routes from "routes.js";
import MainNavbar from "../components/Navbars/MainNavbar";
import API from "../api/API";

var ps;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    API.interceptors.response.use(
      res => {
        return res
      },
      err => {
        console.log(err)
        console.log(err.message)
        console.log(err.hasOwnProperty('message'))
        // if (!err.response || err.response.status >= 500 || err.response.status === 403) {
        if (err.message === 'Network Error' || ( (err.response.status === 403 || err.response.status === 401 ) && this.props.location.pathname !== '/signin' && this.props.location.pathname !== '/resetear' && this.props.location.pathname !== '/recuperar' )){
          console.log('Redireccion login');
          this.props.cookies.remove('auth_token')
          this.props.history.push('/login')
        }
      }
    );

  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  render() {
    return (
      <div className="wrapper">
        <div className="main-panel" ref={this.mainPanel}>
        {['/crear-promocion', '/','/dashboard'].includes(this.props.location.pathname) && (
          <MainNavbar authToken={this.props.cookies.get('auth_token')} />
        )}
          <Switch>
            {routes.map((prop, key) => {
              const route = prop.isProtected ? ProtectedRoute : Route
              return (
                React.createElement( route , {
                  path: prop.path,
                  component: prop.component,
                  key: key
                })
              );
            })}
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
