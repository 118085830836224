import React, { useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import styles from './renderdaylist.module.css'

const RenderDayList = ({ daysList, setDays, days, dateType, isNew }) => {
  const setDaysValue = day => {
    const dayIndex = days.indexOf(day)
    let newDays = [...days]
    dayIndex !== -1 ? newDays.splice(dayIndex, 1) : newDays.push(day)
    setDays(newDays)
  }

  return !isNew ? (
    daysList.map((item, index) => (
      <FormGroup
        check
        inline
        key={`days-${index}`}
        disabled={dateType !== 'days'}>
        <Label check>
          <Input
            type="checkbox"
            disabled={dateType !== 'days'}
            checked={days.includes(index + 1)}
            onChange={() => setDaysValue(index + 1)}
          />{' '}
          {item}
          <span className="form-check-sign">
            <span className="check" />
          </span>
        </Label>
      </FormGroup>
    ))
  ) : (
    <div className={styles.container}>
      {daysList.map((item, index) => (
        <button
          type={'button'}
          key={`days-${index}`}
          disabled={dateType !== 'days'}
          onClick={() => setDaysValue(index + 1)}
          className={styles[String(days.includes(index + 1))]}>
          <span>{item}</span>
        </button>
      ))}
    </div>
  )
}

export default RenderDayList
