import { useState, useCallback, useEffect } from 'react';

const useToast = () => {
  const [toast, setToast] = useState(null);

  const showToast = useCallback(async (data) => {
    setToast(data);
    const timer = setTimeout(() => setToast(null), 3500);
    return () => clearTimeout(timer);
  }, []);

  const hideToast = useCallback(() => {
    setToast(null);
  }, []);

  const handleClick = (e) => {
    const { target } = e;
    if (target instanceof Element && target.nodeName === 'BUTTON') {
      return;
    } else {
      hideToast();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [hideToast]);

  return {
    toast,
    showToast,
    hideToast,
  };
};

export default useToast;
