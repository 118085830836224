import API from "./API";

export const login = (username, password) => {
  return API.post('/login', {username, password})
}

export const logout = () => {
  return API.post('/logout')
}

export const signin = (code, id_token, session_state, state) => {
  return API.post('/signin', { code, id_token, session_state, state })
}

export const forgottenPassword = (email) => {
  return API.post('/forgot-password', { email })
}

export const resetPassword = (email, password, token) => {
  return API.post('/reset-password', { email, password, token })
}

export const me = () => {
  return API.get('/me', {  })
}

export const validate = () => {
  return API.get('/validate', {})
}

export const existToken = (token) => {
  return API.post('/exist-token', { token})
}
