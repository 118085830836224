/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react'

// reactstrap components
import { Button, Card, CardHeader, CardBody, CardFooter, Form, Input } from 'reactstrap'

import './Login.css'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { login } from '../../api/AuthAPI'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { MicrosoftLoginButton } from 'react-social-login-buttons'
import { getCountry } from 'i18n'
import imgTextAction from './../../assets/textAntion.png'
import imgEye from './../../assets/eye.svg'
import imgEyeDisable from './../../assets/eye-disable.svg'
const Login = () => {
  // Si aparece o no el recuerdame
  // -----------------------------
  const isViewCheck = false

  const { t } = useTranslation('login')
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [cookies, setCookies] = useCookies(['auth_token', 'connect.sid'])
  const [rememberMe, setRememberMe] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmit = e => {
    e.preventDefault()
    setSubmitting(true)
    login(username, password)
      .then(({ data }) => {
        setSubmitting(false)
        setCookies('auth_token', data.auth_token, {
          path: '/',
        })
        getCountry()
        history.push({ pathname: '/' })
      })
      .catch(error => {
        setSubmitting(false)
        setError(true)
      })
  }

  const isAuthenticated = () => {
    return cookies.auth_token
  }

  const onLoginAzure = e => {
    window.location.href = `${process.env.REACT_APP_API_URL}/login-azure`
  }

  return (
    <>
      {isAuthenticated() ? (
        <Redirect to="/" />
      ) : true ? (
        <div className="login-page">
          <Card className="card-login">
            <div style={{ margin: '80px auto' }}></div>
            <Form onSubmit={onSubmit} action="" className="form" method="">
              <CardHeader>
                <h3 className="header text-center">{t('title')}</h3>
              </CardHeader>
              <CardBody>
                <div className="error">{error && <p>{t('error')}</p>}</div>
                <label className="label-input label-input2" htmlFor="username">
                  {t('username')}
                </label>
                <Input
                  value={username} // Utiliza value en lugar de defaultValue
                  onChange={e => setUsername(e.target.value)}
                  placeholder={t('placeholder.username')}
                  required
                  type="text"
                />
                <label className="label-input" htmlFor="password">
                  {t('password')}
                </label>
                <div className='relative'>
                  <Input
                    value={password} // Utiliza value en lugar de defaultValue
                    onChange={e => setPassword(e.target.value)}
                    placeholder={t('placeholder.password')}
                    type={isPasswordVisible ? 'text' : 'password'}
                    autoComplete="off"
                    required
                    autoCapitalize="none"
                    autoCorrect="off"
                    aria-describedby=""
                    maxLength="20"
                    className="login-password"
                  />
                  <img src={ isPasswordVisible? imgEyeDisable:imgEye} 
                    onClick={handleTogglePasswordVisibility} 
                    className='login-eyes' alt='eyes.png'/>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isViewCheck ? 'space-between' : 'flex-end',
                    padding: '16px 0 20px 0',
                  }}>
                  {isViewCheck ? (
                    <div
                      style={{
                        display: 'flex',
                        gap: '7px',
                        color: '#68688C',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '18px',
                        letterSpacing: '0.28px',
                        alignItems: 'center',
                      }}>
                      <Input type="checkbox" checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />
                      <label style={{ marginLeft: '5px' /* TODO cambiar i18n*/ }}>Recuérdame</label>
                    </div>
                  ) : null}
                  <a className="recuperar" style={{ textAlign: 'center' }} href="/recuperar">
                    {t('retrieve_password')}
                  </a>
                </div>
              </CardBody>
              <CardFooter>
                <Button
                  block
                  className="btn-style btn-style-orange mr-4"
                  color="primary"
                  disabled={submitting}
                  type="submit">
                  {t('login')}
                </Button>
              </CardFooter>
            </Form>
            <div style={{ padding: '0px 30px', width: '100%' }}>
              <MicrosoftLoginButton
                className="microsoft"
                preventActiveStyles="true"
                iconColor="#00c9dd"
                style={{
                  backgroundColor: '#FFF',
                  fontWeight: 'bold',
                  fontSize: '0.8em',
                  width: '100%',
                  color: '#68688C',
                  borderRadius: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '40px',
                  border: '1px solid #FF7D00',
                  boxShadow: 'none',
                  padding: '0 30px',
                }}
                onClick={onLoginAzure}>
                <span style={{ width: '14px' }}>{t('login_azure')}</span>
              </MicrosoftLoginButton>
            </div>
          </Card>
        </div>
      ) : null}

      <div className="animated-image">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="56" viewBox="0 0 40 56" fill="none">
          <path
            d="M34.6626 12.4065C32.8086 6.26603 29.384 2.06462 29.2392 1.88891L27.6842 0L20.2148 11.1201L23.2366 13.1376L27.8605 6.25347C28.9087 7.9196 30.2811 10.4612 31.1907 13.5016C32.5033 17.8756 32.475 21.5176 31.1907 25.3048L34.5084 27.386C36.1703 22.7579 36.2207 17.5806 34.6595 12.4034L34.6626 12.4065Z"
            fill="url(#paint0_linear_2933_21338)"
          />
          <path
            d="M37.9 33.4362C36.1165 30.4432 33.5311 28.3515 30.6281 27.1425L26.7788 23.7144C24.7194 21.5046 22.1628 19.6461 19.186 18.1884C13.3318 15.3229 7.81451 14.9095 7.58355 14.894L5.09433 14.7168L8.09037 27.6522L11.7087 26.8659L9.85464 18.8566C11.8242 19.2451 14.6727 19.9942 17.5661 21.4176C19.8083 22.521 21.749 23.8543 23.34 25.3834L23.9431 25.9273C18.8845 26.0858 13.8964 28.5815 11.0062 33.4331L0.0260793 51.8572C-0.429422 52.6218 5.22906 51.0616 5.50813 51.7889C5.89948 52.8114 1.21295 56.0002 2.49284 56.0002H24.4499C36.402 56.0002 43.8729 43.4626 37.8968 33.4331L37.9 33.4362ZM24.8477 51.9256H8.84099C7.90432 51.9256 11.3238 49.5977 11.0383 48.8549C10.8362 48.3266 6.70783 49.4641 7.04144 48.9046L15.0448 35.4719C19.4009 28.1588 30.2945 28.1588 34.6506 35.4719C39.0067 42.785 33.5599 51.9225 24.8477 51.9225V51.9256Z"
            fill="url(#paint1_linear_2933_21338)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2933_21338"
              x1="42.5272"
              y1="35.1274"
              x2="8.66586"
              y2="20.5988"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.04" stopColor="#971B00" />
              <stop offset="0.473972" stopColor="#F24405" />
              <stop offset="1" stopColor="#FA7F08" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2933_21338"
              x1="57.2884"
              y1="67.67"
              x2="-9.81896"
              y2="18.6285"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.04" stopColor="#971B00" />
              <stop offset="0.473972" stopColor="#F24405" />
              <stop offset="1" stopColor="#FA7F08" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="animated-image2">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="56" viewBox="0 0 40 56" fill="none">
          <path
            d="M34.6626 12.4065C32.8086 6.26603 29.384 2.06462 29.2392 1.88891L27.6842 0L20.2148 11.1201L23.2366 13.1376L27.8605 6.25347C28.9087 7.9196 30.2811 10.4612 31.1907 13.5016C32.5033 17.8756 32.475 21.5176 31.1907 25.3048L34.5084 27.386C36.1703 22.7579 36.2207 17.5806 34.6595 12.4034L34.6626 12.4065Z"
            fill="url(#paint0_linear_2933_21338)"
          />
          <path
            d="M37.9 33.4362C36.1165 30.4432 33.5311 28.3515 30.6281 27.1425L26.7788 23.7144C24.7194 21.5046 22.1628 19.6461 19.186 18.1884C13.3318 15.3229 7.81451 14.9095 7.58355 14.894L5.09433 14.7168L8.09037 27.6522L11.7087 26.8659L9.85464 18.8566C11.8242 19.2451 14.6727 19.9942 17.5661 21.4176C19.8083 22.521 21.749 23.8543 23.34 25.3834L23.9431 25.9273C18.8845 26.0858 13.8964 28.5815 11.0062 33.4331L0.0260793 51.8572C-0.429422 52.6218 5.22906 51.0616 5.50813 51.7889C5.89948 52.8114 1.21295 56.0002 2.49284 56.0002H24.4499C36.402 56.0002 43.8729 43.4626 37.8968 33.4331L37.9 33.4362ZM24.8477 51.9256H8.84099C7.90432 51.9256 11.3238 49.5977 11.0383 48.8549C10.8362 48.3266 6.70783 49.4641 7.04144 48.9046L15.0448 35.4719C19.4009 28.1588 30.2945 28.1588 34.6506 35.4719C39.0067 42.785 33.5599 51.9225 24.8477 51.9225V51.9256Z"
            fill="url(#paint1_linear_2933_21338)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2933_21338"
              x1="42.5272"
              y1="35.1274"
              x2="8.66586"
              y2="20.5988"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.04" stopColor="#971B00" />
              <stop offset="0.473972" stopColor="#F24405" />
              <stop offset="1" stopColor="#FA7F08" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2933_21338"
              x1="57.2884"
              y1="67.67"
              x2="-9.81896"
              y2="18.6285"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.04" stopColor="#971B00" />
              <stop offset="0.473972" stopColor="#F24405" />
              <stop offset="1" stopColor="#FA7F08" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="textAntion">
        <img src={imgTextAction} alt="antion.png" />
      </div>
    </>
  )
}

export default Login
