import { useTranslation } from 'react-i18next'
import { FormGroup } from 'reactstrap'
import React, { useMemo, useState, useRef } from 'react'

import { getEntities } from '../../../../../../api/EntitiesAPI'
import style from './attributeWrapper.module.css'
import imgLupa from '../../assets/shape.svg'
import { CommonInput } from '..'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) return false
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false
  }
  return true
}

const AttributeWrapper = ({
  productEntities,
  setByEntity,
  setByEntityIndex,
  setEntities,
  setLoading,
  setOpenSelect,
  attributeSelectorOpen,
  setAttributeSelectorOpen,
  attributeHasId,
  deleteAtribute,
  setAttribute,
  setAttributesPersist,
  attributes,
  attributesPersist,
  entityValid,
}) => {
  const { t } = useTranslation('createPromo')
  const [search, setSearch] = useState('')
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
  const [confirmationCallback, setConfirmationCallback] = useState(null)

  const hasChanges = useMemo(() => {
    const filteredAttributes = attributes.filter(attr => attr.ids && attr.ids.length > 0)
    return !deepEqual(attributesPersist, filteredAttributes)
  }, [attributesPersist, attributes])

  const handleInputChange = event => {
    setSearch(event.target.value)
  }

  const filteredEntities = useMemo(() => {
    return search === ''
      ? productEntities
      : productEntities.filter(entity => t(entity.name).toLowerCase().includes(search.toLowerCase()))
  }, [search, productEntities, t])

  const handleClick = async (entity, index) => {
    setByEntity(entity)
    setByEntityIndex(index)
    setLoading(true)

    await getEntities(`${entity.endpoint}/ids?ids=['']`)
      .then(({ data }) => {
        setLoading(false)
        setOpenSelect(true)
        setEntities(aux => {
          if (data) {
            data.map(({ id, description }) => ({
              value: id,
              label: description,
            }))
            return data
          } else return aux
        })
      })
      .catch(err => console.log(err))
  }

  const handleCross = param => {
    const updatedAttributes = attributes.filter(attribute => attribute.entity !== param.entity)
    setAttribute(updatedAttributes)
  }

  const handleSaveAttributes = () => {
    setAttributeSelectorOpen(false)
    setShowConfirmationPopup(false)
  }

  const handleCancelAttributes = () => {
    setAttribute(attributesPersist)
    setAttributeSelectorOpen(false)
    setShowConfirmationPopup(false)
  }

  const handleCancelAttributesWrapper = () => {
    if (hasChanges) {
      setConfirmationCallback({
        handleConfirm: handleSaveAttributes,
        handleCancel: handleCancelAttributes,
      })
      setShowConfirmationPopup(true)
    } else {
      handleCancelAttributes()
    }
  }

  return (
    <FormGroup>
      <CommonInput
        label={'Atributos'}
        labelPlural={'Atributos seleccionados'}
        labelSingular={'Atributo seleccionado'}
        onClick={() => {
          setAttributesPersist(attributes)
          setAttributeSelectorOpen(!attributeSelectorOpen)
        }}
        onClickCross={deleteAtribute}
        entityValid={entityValid}
        objectsSize={attributes.filter(element => element.ids.length > 0).length}
        requiredValid={true}
      />
      <div className={`${style.container} ${style[String(attributeSelectorOpen)]}`}>
        <div className={`${style.popUpContainer} ${style[String(attributeSelectorOpen)]}`}>
          <div className={style.header}>
            <button type="button" onClick={handleCancelAttributesWrapper}>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <path
                  d="M-1.52588e-05 6L0.63057 6.7491L5.06554 12L6.7356 10.5018L3.89444 7.1415L13 7.1415V4.8585L3.89444 4.8585L6.7356 1.4982L5.06554 -7.62939e-06L0.63057 5.25091L-1.52588e-05 6Z"
                  fill="#971B00"
                />
              </svg>
            </button>
            <h2>Atributos</h2>
          </div>
          <div className={style.body}>
            <div className="SelectAttribute-filter">
              <img src={imgLupa} alt="lupa.jpg" />
              <input
                type="text"
                placeholder="Buscar"
                value={search}
                onChange={handleInputChange}
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
              />
            </div>
            <div className={style.containerBody}>
              <ul>
                {filteredEntities?.length > 0 &&
                  filteredEntities.map((entity, index) => {
                    const value = attributeHasId()?.find(attribute => attribute.entity === entity.value)
                    return (
                      <li value={index} key={entity.name} onClick={() => handleClick(entity, index)}>
                        <span>{t(entity.name)}</span>
                        <div className={style.valuesContainer}>
                          {value ? (
                            <div
                              className={style.valueCounter}
                              onClick={e => {
                                e.stopPropagation()
                                handleCross(value)
                              }}>
                              <span>{value.ids.length}</span>
                              <svg
                                width="9"
                                height="8"
                                viewBox="0 0 9 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  opacity="0.6"
                                  d="M8.18166 1.45412L6.90887 0.181326L4.36328 2.72691L1.8177 0.181326L0.544906 1.45412L3.09049 3.9997L0.544905 6.54529L1.8177 7.81808L4.36328 5.27249L6.90887 7.81808L8.18166 6.54529L5.63607 3.9997L8.18166 1.45412Z"
                                  fill="#68688C"
                                />
                              </svg>
                            </div>
                          ) : null}
                          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                            <path
                              d="M0.706721 1.52839L1.128 1.10711L5.22089 5.2L1.128 9.29289L0.706721 8.87161L4.01794 5.55317L4.37034 5.2L4.01794 4.84683L0.706721 1.52839Z"
                              fill="#68688C"
                              stroke="#68688C"
                            />
                          </svg>
                        </div>
                      </li>
                    )
                  })}
                {filteredEntities?.length === 0 && <h2>No se encontraron atributos para ese filtro</h2>}
              </ul>
            </div>
          </div>
          <div className={style.footer}>
            <button
              color="primary"
              type="button"
              className="btn-style btn-style-red"
              onClick={handleCancelAttributesWrapper}>
              Volver
            </button>
            <button
              color="primary"
              type="button"
              className="btn-style btn-style-orange"
              onClick={() => {
                setAttributesPersist(attributes)
                setAttributeSelectorOpen(false)
              }}>
              Aplicar
            </button>
          </div>
        </div>
      </div>
      <PopUpAlert
        value={t('confirmPopUp.title')}
        extraText={t('confirmPopUp.description')}
        isOpen={showConfirmationPopup}
        setIsOpen={setShowConfirmationPopup}
        onConfirm={{
          value: t('confirmPopUp.confirm'),
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
        onCancel={{
          value: t('confirmPopUp.cancel'),
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
      />
    </FormGroup>
  )
}

export default AttributeWrapper
