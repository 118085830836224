import React, { useEffect, useMemo, useState } from 'react'
import { Spinner, Table } from 'reactstrap'
import './css/PromotionsTable.css'
import PromotionRow from './PromotionRow'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { useTranslation } from 'react-i18next'
import useToast from 'components/Toast/hooks/useToast'
import Toast from 'components/Toast/Toast'
import emptySvg from './assets/withoutPromos.svg'
import emptySearch from './assets/emptySearch.svg'

const SortableTable = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>
})

const SortableItem = SortableElement(({ value, style, searchQuery, statusFilter }) => {
  return <PromotionRow {...value} searchQuery={searchQuery} statusFilter={statusFilter} className={style} />
})

const PromotionsTable = props => {
  const { t } = useTranslation('dashboard')
  const { loading, promos, sortable } = props
  const [locked, setLocked] = useState(false)
  const { toast, showToast } = useToast()

  const defaultColumns = [
    t('columns.id'),
    t('columns.name'),
    t('columns.status'),
    t('columns.toggle_status'),
    t('columns.actions'),
    '',
  ]

  // Table visual bug fix: https://github.com/clauderic/react-sortable-hoc/issues/361
  const onSortStart = ({ node, helper }) => {
    node.childNodes.forEach((td, index) => {
      helper.childNodes[index].style.width = `${td.offsetWidth}px`
    })
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setLocked(true)
      props
        .onPromoSwap(oldIndex, newIndex)
        .then(() => setLocked(false))
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    const promoAs = sessionStorage.getItem('promoAs')
    if (promoAs) {
      if (promoAs !== 'false') {
        showToast({
          text: t(`succes_${promoAs}`),
          type: 'success',
        })
      } else {
        showToast({
          text: t('error_edit'),
          type: 'error',
        })
      }
      sessionStorage.removeItem('promoAs')
    }
  }, [])

  const emptyState = useMemo(() => {
    const { statusFilter, searchQuery } = props

    if (statusFilter.length !== 0 || searchQuery.length !== 0) {
      return { svg: emptySearch, text: 'No se encontraron resultados de búsqueda.', search: true }
    }

    return { svg: emptySvg, text: 'Aún no has creado promociones.' }
  }, [props.searchQuery, props.statusFilter])

  const table =
    promos.length > 0 ? (
      <>
        {toast && <Toast {...toast} />}
        <Table responsive="md" className="promos-table">
          <thead>
            <tr>
              {defaultColumns.map((item, i) => (
                <th className={'colum' + i} key={item}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <SortableTable
            onSortEnd={onSortEnd}
            onSortStart={onSortStart}
            helperClass="dragging"
            lockToContainerEdges
            useDragHandle
            lockAxis="y">
            {promos.map((promo, index) => {
              return (
                <SortableItem
                  key={`item-${index}`}
                  index={index}
                  style={`promotion-row ${locked ? 'disabled' : ''}`}
                  value={{
                    ...promo,
                    index,
                    sortable: sortable,
                    onTogglePause: props.onTogglePause,
                    onDeletePromo: props.onDeletePromo,
                  }}
                  searchQuery={props.searchQuery}
                  statusFilter={props.statusFilter}
                />
              )
            })}
          </SortableTable>
        </Table>
      </>
    ) : (
      // If there's no promos, could be loading or filtered.
      <div className="mt-5 mb-5 ml-auto mr-auto no-results">
        {loading ? (
          <Spinner color="primary" size="lg" />
        ) : (
          <>
            <div className={'empty-dashboard-container'}>
              <div className="empty-dashboard-image-container">
                <img src={emptyState.svg} alt="empty-svg" className={'empty-dashboard-image'} draggable={false} />
              </div>
              <span className={!emptyState.search ? 'empty-dashboard-container-search-span' : ''}>
                {emptyState.text}
              </span>
            </div>
          </>
        )}
      </div>
    )

  return <div className="promotions-table">{table}</div>
}

export default PromotionsTable
