import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getEntities } from "../src/api/EntitiesAPI";
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

//EN
import en_dashboard from './translations/en/dashboard.json'
import en_common from './translations/en/common.json'

//ES_ES
import es_es_dashboard from './translations/es-es/dashboard.json'
import es_es_common from './translations/es-es/common.json'
import es_es_createPromo from './translations/es-es/createPromo.json'
import es_es_login from './translations/es-es/login.json'
import es_es_signin from './translations/es-es/signin.json'
import es_es_forgottenPassword from './translations/es-es/forgottenPassword.json'
import es_es_resetPassword from './translations/es-es/resetPassword.json'

//ES_PE
import es_pe_common from './translations/es-pe/common.json'
import es_pe_createPromo from './translations/es-pe/createPromo.json'
import es_pe_login from './translations/es-pe/login.json'
import es_pe_signin from './translations/es-pe/signin.json'
import es_pe_forgottenPassword from './translations/es-pe/forgottenPassword.json'
import es_pe_resetPassword from './translations/es-pe/resetPassword.json'
import es_pe_dashboard from './translations/es-pe/dashboard.json'

//ES_ARG
import es_arg_common from './translations/es-arg/common.json'
import es_arg_createPromo from './translations/es-arg/createPromo.json'
import es_arg_login from './translations/es-arg/login.json'
import es_arg_signin from './translations/es-arg/signin.json'
import es_arg_forgottenPassword from './translations/es-arg/forgottenPassword.json'
import es_arg_resetPassword from './translations/es-arg/resetPassword.json'
import es_arg_dashboard from './translations/es-arg/dashboard.json'


export const getCountry = () => {
  getEntities('customer-country')
    .then((data) => {
      switch (data.data.country) {
        case "ES": i18n.changeLanguage("es_es"); break;
        case "AR": i18n.changeLanguage("es_arg"); break;
        case "PE": i18n.changeLanguage("es_pe"); break;
      }
    }).catch(err => {
      console.log(err);
    });
}

const resources = {
  en: {
    dashboard: en_dashboard,
    common: en_common,
    createPromo: {},
    login: es_es_login,
    signin: es_es_signin,
    forgottenPassword: es_es_forgottenPassword,
    resetPassword: es_es_resetPassword
  },
  es_es: {
    dashboard: es_es_dashboard,
    common: es_es_common,
    createPromo: es_es_createPromo,
    login: es_es_login,
    signin: es_es_signin,
    forgottenPassword: es_es_forgottenPassword,
    resetPassword: es_es_resetPassword
  },
  es_pe: {
    dashboard: es_pe_dashboard,
    common: es_pe_common,
    createPromo: es_pe_createPromo,
    login: es_pe_login,
    signin: es_pe_signin,
    forgottenPassword: es_pe_forgottenPassword,
    resetPassword: es_pe_resetPassword
  },
  es_arg: {
    dashboard: es_arg_dashboard,
    common: es_arg_common,
    createPromo: es_arg_createPromo,
    login: es_arg_login,
    signin: es_arg_signin,
    forgottenPassword: es_arg_forgottenPassword,
    resetPassword: es_arg_resetPassword
  }
}

i18n

 //.use(Backend)
  // // detect user language
  // // learn more: https://github.com/i18next/i18next-browser-languageDetector
 //.use(LanguageDetector)
  // // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: ["es_es", "es_arg", "es_pe"],
    resources,
    lng: getCountry(),
    fallbackNS: 'dashboard',
    fallbackLng: "es_pe", //es_pe, es_arg, es_es
    debug: true,
    // keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }

  });


export default i18n;