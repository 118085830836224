// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".renderdaylist_container__qGL2q {\n  left: -1.5rem;\n  position: relative;\n  padding: 1rem 0rem;\n  display: flex;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.renderdaylist_container__qGL2q > button {\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: transparent;\n  color: var(--gray);\n  background: #e4fdfa;\n  transition: ease-out 0.2s;\n}\n\n.renderdaylist_container__qGL2q > button:disabled {\n  background: #f7f7ff;\n  color: #ccd1e4;\n}\n\n.renderdaylist_container__qGL2q > button:disabled.renderdaylist_true__IKEtF {\n  opacity: 50%;\n}\n\n.renderdaylist_container__qGL2q > button.renderdaylist_true__IKEtF {\n  color: var(--white);\n  background: var(--primary-color);\n}\n\n.renderdaylist_container__qGL2q > button > span {\n  font-family: Inter;\n  font-weight: 500;\n  text-transform: uppercase;\n  font-size: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "renderdaylist_container__qGL2q",
	"true": "renderdaylist_true__IKEtF"
};
module.exports = exports;
