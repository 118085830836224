import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Card, Spinner, Table } from 'reactstrap'
import '../../components/PromotionsTable/css/PromotionsTable.css'
import './css/PromoSettingsContainer.css'
import { useTranslation } from 'react-i18next'
import imgCopy from '../PromotionsTable/assets/faCopy.svg'
import CardSettingItem from './CardSettingItem'

import imgDelet from '../PromotionsTable/assets/icono-borrar.svg'
import imgEdit from '../PromotionsTable/assets/icono-modificar.svg'
import emptyWhere from './assets/withoutWhere.svg'
import emptyConditions from './assets/withoutConditions.svg'
import emptyActions from './assets/withoutActions.svg'
import emptyWhen from './assets/whitoutWhen.svg'

import { FastSaveButton } from './PromoWizard'
import { CreatePromoContext } from 'context/create-promo-context'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'
import Toast from 'components/Toast/Toast'
import useToast from 'components/Toast/hooks/useToast'

const PromoSettingsContainer = props => {
  const { t } = useTranslation('createPromo')
  const { submitting } = props
  const [parsedSettings, setParsedSettings] = useState([])
  const [deletedIndex, setDeletedIndex] = useState(null)
  const maxLengthString = 80
  const { isValid, originalPromo, currentStep } = useContext(CreatePromoContext)
  const [isOpen, setIsOpen] = useState(false)
  const [confirmationCallback, setConfirmationCallback] = useState({ onConfirm: () => {}, onCancel: () => {} })
  const { toast, showToast } = useToast()

  const updateParsedSetting = (setting, index) => {
    let updatedParsedSettings = [...parsedSettings]
    updatedParsedSettings[index] = setting
    setParsedSettings(updatedParsedSettings)
  }

  useEffect(() => {
    if (props.settings) {
      // Checks if length is the same, that means a setting was edited.
      // Else, checks every 'new' setting.
      if (Number.isFinite(deletedIndex) && props.settings.length < parsedSettings.length) {
        const newParsedSettings = [...parsedSettings]
        newParsedSettings.splice(deletedIndex, 1)
        setParsedSettings(newParsedSettings)
        setDeletedIndex(null)
      } else if (
        props.settings.length &&
        props.settings.length === parsedSettings?.length &&
        Number.isFinite(props.editingIndex)
      ) {
        updateParsedSetting({}, props.editingIndex)
        props
          .parser(props.settings[props.editingIndex])
          .then(data => {
            updateParsedSetting(data, props.editingIndex)
          })
          .catch(err => console.log(err))
      } else if (props.settings.length > parsedSettings.length) {
        const newParsedSettings = props.settings.map((setting, i) => {
          if (i > parsedSettings.length - 1) {
            return props.parser(setting)
          }
          return parsedSettings[i]
        })
        Promise.all(newParsedSettings)
          .then(data => {
            setParsedSettings([...data])
          })
          .catch(err => console.log(err))
      }
    }
  }, [props.settings])

  const onDeleteSetting = (e, i) => {
    e.preventDefault()
    setIsOpen(true)
    const handleConfirm = () => {
      setDeletedIndex(i)
      props.deleteSetting(e, i)
      setIsOpen(false)
      showToast({
        text:
          currentStep === 4
            ? 'Se eliminó la condición con éxito'
            : currentStep === 5
            ? 'Se eliminó la acción con éxito'
            : 'Se eliminó la configuración con éxito',
        type: 'success',
      })
    }
    const handleCancel = () => {
      setIsOpen(false)
    }
    setConfirmationCallback({ handleConfirm, handleCancel })
  }

  const emptySetting = useMemo(() => {
    if(currentStep === 2) {
      return {text: 'Aún no has definido períodos de tiempo para esta promoción.',  svg: emptyWhen}
    }
    
    if(currentStep === 3) {
      return {text: 'Aún no has definido ubicaciones para esta promoción.', svg: emptyWhere}
    }

    if(currentStep === 4) {
      return {text: 'Aún no has definido condiciones para esta promoción.',svg: emptyConditions}
    }

    return {text: 'Aún no has definido acciones para esta promoción.', svg: emptyActions}
  }, [currentStep])

  return (
    <>
      <PopUpAlert
        value={
          currentStep === 4
            ? t('confirm_delete_setting.condition')
            : currentStep === 5
            ? t('confirm_delete_setting.action')
            : t('confirm_delete_setting.default')
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={{
          value: 'Eliminar',
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
        onCancel={{
          value: 'Cancelar',
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
      />
      {toast && <Toast {...toast} portalRef={document.querySelector('body')} />}
      <FastSaveButton step={2} callback={props.handleFastSave} isValid={isValid}/>
      <Card className="promo-settings-container promotions-table">
        {props.settings && props.settings.length > 0 ? (
          <div className="promos-table-scroll">
            <Table responsive="md">
              <thead>
                <tr>
                </tr>
                <tr>
                  {props.columns.map(item => (
                    <th key={item}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {parsedSettings.length ? (
                  parsedSettings.map((parsedSetting, i) => {
                    return (
                      <tr key={`tr-${parsedSetting.id ? parsedSetting.id : i}`} className="promotion-row settings-row">
                        {Object.keys(parsedSetting)?.length ? (
                          Object.values(parsedSetting).map((k, idx) => {
                            return (
                              <CardSettingItem
                                key={`td-${i}-${idx}`}
                                k={k}
                                maxLengthString={maxLengthString}
                                i={i}
                                idx={idx}
                              />
                            )
                          })
                        ) : (
                          <td>
                            <Spinner />
                          </td>
                        )}
                        <td className="actions">
                          <div className="flex-aling">
                            <div
                              className={`action ${submitting ? 'disabled' : ''}`}
                              onClick={e => props.editSetting(e, props.settings[i], i)}>
                              {' '}
                              <img src={imgEdit} alt="edit.svg" />
                            </div>
                            <div
                              className={`action ${submitting ? 'disabled' : ''}`}
                              onClick={e => onDeleteSetting(e, i)}>
                              <img src={imgDelet} alt="delet.svg" />
                            </div>
                            {props.allowCopy && (
                              <a
                                className={`action ${submitting ? 'disabled' : ''}`}
                                onClick={e => props.editSetting(e, props.settings[i])}
                                href="/">
                                <img src={imgCopy} alt="copy.svg" />
                              </a>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <div className="text-center mt-5 mb-5">
                    <Spinner />
                  </div>
                )}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="promo-settings-empty">
            <div className='promo-settings-empty-image-container'>
              <img src={emptySetting.svg} alt='empty-svg' draggable={false} className='promo-settings-empty-image' />
            </div>
            <p>{emptySetting.text}</p>
          </div>
        )}
      </Card>
    </>
  )
}

export default PromoSettingsContainer
