import React from 'react';
import { Badge } from 'reactstrap'
import './StatusBadge.css'
import {useTranslation} from "react-i18next";

const StatusBadge = (props) => {
  const {t} = useTranslation('common')
  const statusSwitch = (status) => ({
    'active': {color: 'success', text: t('status.active')},
    'paused': {color: 'warning', text: t('status.paused')},
    'expired': {color: 'danger', text: t('status.expired')},
  })[status]

  return (
    <Badge color={statusSwitch(props.status).color} pill={props.pill} className={`status-badge ${props.outline && 'outline'}`}>
      { statusSwitch(props.status).text }
    </Badge>
  );
};

export default StatusBadge;