import React, { useMemo, useState, useEffect } from 'react'
import Switch from 'react-switch'

import AttributeSelector from '../AttributeSelector/AttributeSelector'
import styles from './CreditNoteWrapper.module.css'
import { getEntityNames } from 'api/EntitiesAPI'

const CreditNoteWrapper = ({
  form,
  isActive,
  setIsActive,
  error,
  selectedEntity,
  setSelectedEntity,
  loading,
  entityOptions,
  setLoading,
  openSelect,
  setOpenSelect,
  onEntitySelectOpen,
  fetchedAllEntityPages,
  setFetchedAllEntityPages,
  setEntityPage,
  filterEntityBy,
  entityPage,
  setByEntity,
  setEntityOptions,
  setFilterEntityBy,
  filteredEntityOptions,
  setAttributeSelectorOpen,
  attributeSelectorOpen,
  byEntity,
}) => {
  const [entityLabel, setEntityLabel] = useState('+ Selecciona producto')
  const [currentChanges, setCurrentChanges] = useState({
    name: 'createPromo:steps.conditions.form.code',
    endpoint: '/product',
    value: 'PRODUCT_ID',
    ids: (Array.isArray(form.action_line_entity) && form.action_line_entity) || [],
  })

  const cleanedEntities = useMemo(() => {
    return (
      entityOptions?.map(entity => ({
        ...entity,
        label: entity.label.replace(`${entity.value} - `, ''),
      })) || []
    )
  }, [entityOptions])

  const cleanedFilteredEntities = useMemo(() => {
    return (
      filteredEntityOptions?.map(entity => ({
        ...entity,
        label: entity.label.replace(`${entity.value} - `, ''),
      })) || []
    )
  }, [filteredEntityOptions])

  useEffect(() => {
    if (Array.isArray(selectedEntity) && selectedEntity !== 0) {
      const selected = selectedEntity[0]

      if (selected?.value) {
        return setEntityLabel(`${selected.value} - ${selected.label.replace(`${selected.value} - `, '')}`)
      }

      if (selected?.entity_id) {
        if (byEntity?.value !== 'PRODUCT_ID' || !Array.isArray(cleanedEntities)) {
          setLoading(true)
          getEntityNames('/product', [selected.entity_id])
            .then(({ data }) => {
              setSelectedEntity([{ value: data[0]?.id, label: data[0]?.description }])
              setEntityLabel(`${data[0]?.id} - ${data[0]?.description}`)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
        } else {
          const result = cleanedEntities?.find(item => item.value === selected.entity_id)
          if (result) {
            setSelectedEntity([{ value: result.value, label: result.label }])
            return setEntityLabel(`${result.value} - ${result.label}`)
          }
        }
      }
    }

    return setEntityLabel('+ Selecciona producto')
  }, [selectedEntity])

  useEffect(() => {
    if (!selectedEntity.length) {
      setCurrentChanges(prev => ({ ...prev, ids: selectedEntity }))
      return
    }

    const { entity_id, value } = selectedEntity[0]

    setCurrentChanges(prev => ({
      ...prev,
      ids: [entity_id || value],
    }))
  }, [selectedEntity])

  return (
    <>
      <div className={`${styles.container} ${isActive ? styles.active : ''}`}>
        <label htmlFor="select-credit-note" className={styles.label}>
          Realiza Nota de Crédito por los descuentos
        </label>
        <div className={styles.selectBox}>
          <span
            className={`${styles.text} ${selectedEntity.length !== 0 ? styles.complete : ''}`}
            onClick={() => {
              if (isActive) {
                setOpenSelect(prev => {
                  if (!prev) {
                    setFilterEntityBy('')
                  }

                  return !prev
                })

                if (byEntity?.value !== 'PRODUCT_ID') {
                  setByEntity({
                    name: 'createPromo:steps.conditions.form.code',
                    endpoint: '/product',
                    value: 'PRODUCT_ID',
                  })
                }
              }
            }}>
            {loading ? 'Cargando...' : entityLabel}
          </span>

          <Switch
            checked={isActive}
            onChange={e => {
              setIsActive(e)
              if (!e) {
                setCurrentChanges(prev => {
                  return { ...prev, ids: [] }
                })
                return setSelectedEntity([])
              }
            }}
            onColor="#FDC792"
            onHandleColor="#FF7D00"
            disabled={loading}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={13}
            width={30}
            id={'select-credit-note'}
            className="react-switch toggle-pausar"
          />
        </div>
        {error && <span className={styles.err}>{error}</span>}
      </div>
      <AttributeSelector
        entityOptions={cleanedEntities}
        loading={loading}
        setLoading={setLoading}
        entities={selectedEntity}
        setEntities={setSelectedEntity}
        openSelect={openSelect}
        setOpenSelect={setOpenSelect}
        localChanges
        onEntitySelectOpen={onEntitySelectOpen}
        fetchedAllEntityPages={fetchedAllEntityPages}
        setFetchedAllEntityPages={setFetchedAllEntityPages}
        setEntityPage={setEntityPage}
        filterEntityBy={filterEntityBy}
        entityPage={entityPage}
        byEntity={{
          name: 'createPromo:steps.conditions.form.code',
          endpoint: '/product',
          value: 'PRODUCT_ID',
        }}
        AttributeCurrent={currentChanges}
        setEntityOptions={setEntityOptions}
        setFilterEntityBy={setFilterEntityBy}
        filteredEntityOptions={cleanedFilteredEntities}
        setAttributeSelectorOpen={setAttributeSelectorOpen}
        attributeSelectorOpen={attributeSelectorOpen}
        withCheck={false}
        setEntityLabel={setEntityLabel}
      />
    </>
  )
}

export default CreditNoteWrapper
