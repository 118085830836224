import { useTranslation } from 'react-i18next'
import React from 'react'

import style from './openableChild.module.css'
import arrowSvg from '../../assets/arrow.svg'
import { CommonCheckbox } from '..'

const OpenableChild = ({
  item,
  isOpen,
  checked,
  onClick,
  children,
  selections,
  extraButton,
  handleChange,
  onClickDelete,
  withCheckbox = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`${style.container} ${withCheckbox ? style.withCheckbox : ''} ${style[isOpen]}`}>
      <div className={style.item} onClick={onClick}>
        {withCheckbox ? (
          <CommonCheckbox
            value={item.label.toLocaleLowerCase()}
            checked={checked}
            handleChange={handleChange}
            id={item.value}
          />
        ) : (
          <span className={style.span}>{t(item.name)}</span>
        )}
        <div className={style.divItem}>
          {selections?.length ? <span>{selections.length}</span> : null}
          <img src={arrowSvg} className={`${style.svg} ${style[isOpen]}`} />
        </div>
      </div>
      <div className={style.content}>
        {children}
        <div className={style.buttonsContainer}>
          {onClickDelete ? (
            <button type="button" className={style.delete} onClick={onClickDelete}>
              Borrar Selección
            </button>
          ) : null}
          {item.value === 'CREDIT_CARD' ? (
            <button type="button" className={style.append} onClick={extraButton}>
              + Agregar otra tarjeta
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default OpenableChild
