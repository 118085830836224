/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect} from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
} from "reactstrap";

import './Signin.css'
import { Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {signin} from "../../api/AuthAPI";
import {useCookies} from "react-cookie";
import { useHistory } from 'react-router-dom';
import * as QueryString from "query-string"

const Signin = (props) => {
  const { t } = useTranslation('signin')
  const history = useHistory()
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [cookies, setCookies] = useCookies(['auth_token', 'connect.sid'])

  useEffect(() => {
    if (hasParams()) {
      let params = QueryString.parse(props.location.hash);
      setSubmitting(true)
      signin(params.code, params.id_token, params.session_state, params.state).then(({ data }) => {
        setSubmitting(false)
        setCookies("auth_token", data.auth_token, {
          path: "/"
        });

        history.push({ pathname: '/' })
      }).catch((error) => {
        setSubmitting(false)
        setError(true)
      });
    } else {
      history.push({ pathname: '/login' })
    }
  }, []);

  const isAuthenticated = () => {
    return cookies.auth_token
  }

  const hasParams = () => {
    if (Object.keys(QueryString.parse(props.location.hash)).length === 0){
      return false;
    }

    return true;
  }
  return (
    (isAuthenticated() || !hasParams()) ?
      <Redirect to='/'/>
    :
    <div className="signin-page">
      <Form action="" className="form" method="">
          <Card className="card-signin">
          <CardHeader>
            <CardHeader>
                {hasParams() && !error && <h6 className="header text-center">{t('title')}</h6>}
            </CardHeader>
          </CardHeader>
          <CardBody>
            <br />
            { error &&
            <div className='pl-2 pr-2'>
                <p style={{ color: "red", textAlign: "center" }}>{t('error')}</p>
            </div>
            }
          </CardBody>
          <CardFooter>
              {error && <a className='login' style={{ textAlign: 'center', display: 'block' }} href='/login'>{t('login')}</a>}
          </CardFooter>
        </Card>
      </Form>
    </div>
  )
}

export default Signin;
