import axios from "axios";
import {API_URL} from "./config";

const API = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  headers: {
    'Content-Type' : 'application/json',
  },
  withCredentials: true
})

export default API
