import React from 'react'
import style from './Toast.module.css'
import checkIcon from './assets/check.svg'
import errorIcon from './assets/error.svg'
import confirmIcon from './assets/confirm.svg'
import { createPortal } from 'react-dom'

const icon = type => {
  if (type === 'success') return checkIcon
  if (type === 'error') return errorIcon
  if (type === 'test') return confirmIcon
}

const Toast = ({ type = 'test', text = '', styles = {}, portalRef = null, iconStyles = {}, color, className }) => {
  const toast = (
    <div
      className={[style['container'], style[`${type}`], , className || ''].join(' ')}
      style={{ ...styles, background: color }}>
      <div className={style['icon']}>
        <img draggable={false} src={icon(type)} alt={`${type}-icon`} style={iconStyles} />
      </div>
      <span className={style['text']}>{text}</span>
    </div>
  )

  if (portalRef) {
    return createPortal(toast, portalRef)
  } else {
    return toast
  }
}

export default Toast
