/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react'
// reactstrap components
import { Row } from 'reactstrap'

import style from './Dashboard.module.css'
import PromotionsTable from '../../components/PromotionsTable/PromotionsTable'
import ActionBar from '../../components/ActionBar/ActionBar'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { deletePromo, getPromos, pausePromo, resumePromo, updatePriority } from '../../api/PromotionsAPI'
import useToast from 'components/Toast/hooks/useToast'
import Toast from 'components/Toast/Toast'
import moment from 'moment'
import PopUpAlert from 'components/PopUpAlert/PopUpAlert'

const Dashboard = props => {
  const { t } = useTranslation('dashboard')
  const [promos, setPromos] = useState([])
  const [filteredPromos, setFilteredPromos] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [statusFilter, setStatusFilter] = useState([])
  const { toast, showToast } = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const [confirmationCallback, setConfirmationCallback] = useState({ onConfirm: () => {}, onCancel: () => {} })

  const onSearchChange = query => {
    setSearchQuery(query)
  }

  const onFilterChange = value => {
    const filterIndex = statusFilter.indexOf(value)
    let filters = [...statusFilter]
    filterIndex !== -1 ? filters.splice(filterIndex, 1) : filters.push(value)
    setStatusFilter(filters)
  }

  const onTogglePause = id => {
    const i = promos.findIndex(p => p.id === id)
    const promise = promos[i].paused ? resumePromo(id) : pausePromo(id)
    promise
      .then(res => {
        let newPromos = [...promos]
        newPromos[i] = { ...promos[i], paused: !promos[i].paused }
        setPromos(newPromos)
      })
      .catch(err => console.log(err))
    return promise
  }

  const onDeletePromo = id => {
    setIsOpen(true)
    return new Promise((resolve, reject) => {
      const handleConfirm = () => {
        let promise = deletePromo(id)
        promise
          .then(res => {
            showToast({
              text: 'Se eliminó la promoción con éxito',
              type: 'success',
            })
            const newPromos = promos.filter(p => p.id !== id)
            setPromos(newPromos)
            resolve(res)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      }

      const handleCancel = () => {
        setIsOpen(false)
        resolve()
      }

      setConfirmationCallback({ handleConfirm, handleCancel })
    })
  }

  const onPromoSwap = (oldIndex, newIndex) => {
    const promo = promos[oldIndex]
    const newPriority = promos[newIndex].priority

    return new Promise((resolve, reject) => {
      const promise = updatePriority(promo.id, newPriority)
      promise
        .then(res => {
          fetchPromos()
            .then(res => resolve())
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
    })
  }

  const fetchPromos = () => {
    setLoading(true)
    const promise = getPromos()
      .then(({ data }) => {
        setLoading(false)
        // Sort array of promos by Priority field
        const sortedPromos = data.sort((a, b) => a.priority - b.priority)
        setPromos(sortedPromos)
        setFilteredPromos(sortedPromos)
      })
      .catch(err => console.log(err))
    return promise
  }

  useEffect(() => {
    fetchPromos()
  }, [setLoading, setPromos])

  useEffect(() => {
    const filterPromos = filters => {
      const filteredPromos = promos.filter(p => {
        // Filter fetched promos by search query and status.
        // '!filters.status.length' prevents filtering when no checkboxes are selected
        const status =
          moment().isAfter(moment(p.date_time_to)) && !p.always_current ? 'expired' : p.paused ? 'paused' : 'active'
        return (
          [p.code.toString(), p.name.toLowerCase()].some(e => e.includes(filters.searchQuery.toLowerCase())) &&
          (!filters.status.length || filters.status.includes(status))
        )
      })
      setFilteredPromos(filteredPromos)
    }

    if (promos) {
      filterPromos({ searchQuery: searchQuery, status: statusFilter })
    }
  }, [promos, searchQuery, statusFilter])

  const getPromosValues = param => {
    if (param === 'active') {
      return promos.filter(p => (!moment().isAfter(moment(p.date_time_to)) || p.always_current) && !p.paused)
    }

    if (param === 'paused') {
      return promos.filter(p => (!moment().isAfter(moment(p.date_time_to)) || p.always_current) && p.paused)
    }

    return promos.filter(p => moment().isAfter(moment(p.date_time_to)) && !p.always_current)
  }

  useEffect(() => {
    if (!loading) {
      const item = JSON.parse(sessionStorage.getItem('promoSettings'))
      if (item) {
        showToast(item)
        sessionStorage.removeItem('promoSettings')
      }
    }
  }, [loading])

  return (
    <>
      <PopUpAlert
        value="¿Deseas eliminar esta promoción?"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={{
          value: 'Eliminar',
          function: confirmationCallback ? confirmationCallback.handleConfirm : () => {},
        }}
        onCancel={{
          value: 'Cancelar',
          function: confirmationCallback ? confirmationCallback.handleCancel : () => {},
        }}
      />
      {toast && <Toast {...toast} />}
      <div className={style['Dashboard-content']}>
        <Row className={`${style['dashboard-header']} pl-4 pr-4`}>
          <h1 className={style['dashboard-header-h1']}>{t('header.title')}</h1>
          <Link to="/crear-promocion">
            <button className={style.btn}>{t('header.cta')}</button>
          </Link>
        </Row>
        <Row className="pl-4 pr-4">
          <ActionBar
            onSearchChange={onSearchChange}
            onFilterChange={onFilterChange}
            searchQuery={searchQuery}
            getPromosValues={getPromosValues}
          />
        </Row>
        <Row className="pl-4 pr-4">
          <PromotionsTable
            promos={filteredPromos}
            onTogglePause={onTogglePause}
            onDeletePromo={onDeletePromo}
            onPromoSwap={onPromoSwap}
            loading={loading}
            sortable={!(searchQuery.length || statusFilter.length)}
            searchQuery={searchQuery}
            statusFilter={statusFilter}
          />
        </Row>
      </div>
    </>
  )
}

export default Dashboard
