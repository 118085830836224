import React from 'react'

import style from './commonInput.module.css'

const CommonInput = props => {
  const { label,labelPlural,labelSingular, onClick, onClickCross, objectsSize, placeholder, loading,entityValid ,requiredValid=false} = props

  return (
    <div className={style.container} onClick={onClick}>
      <span>
        {label}
      </span>
      <div className={style.input}>
      {requiredValid&&<input type="text" name="ActionsForm-entity-falso" id="ActionsForm-entity-falso" className="ActionsForm-entity-falso" required value={entityValid}></input>}
        {loading ? (
          <div className={style.loadingContainer}></div>
        ) : (
          <div>
            {objectsSize > 0 ? (
              <div className={style.tag}>
                <div className={style.tagContent} onClick={onClick}>
                  {objectsSize} {objectsSize > 1 ? (labelPlural? labelPlural : label + 's seleccionados') : labelSingular?labelSingular: label + ` seleccionado`}
                </div>
                <button
                  type="button"
                  onClick={e => {
                    e.stopPropagation()
                    onClickCross()
                  }}>
                  x
                </button>
              </div>
            ) : (
              placeholder || 'Sin seleccionar'
            )}
          </div>
        )}
        <span>+ {label}</span>
      </div>
    </div>
  )
}

export default CommonInput
